@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

#mui_measurement_span {
    display: none;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-wrap: normal;
}

:root {
    --main-bgcolor: #052963;
    /* --main-bgcolor: #32213A; */
    --secondary-bgcolor: orange;
}

body,
a {
    font-family: "Oswald", monospace !important;
}

li,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    letter-spacing: 0.9px;

}

a:hover {
    text-decoration: none;
}

.main_nav {
    background-color: var(--main-bgcolor);
}

/* ==============Login Page/Registration page================================ */
a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}


.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}

.form-control:focus {
    border-color: #bbb;
    box-shadow: none;
    outline: 0;
}

.form-control.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
}

.form-control.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
}

a {

    text-decoration: none;
}

input,
button,
a {
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
    outline: none;
}

input[type="text"],
input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea.form-control {
    resize: vertical;
}

.btn {
    padding: 0.375rem 0.575rem;
}

.navbar-nav>li {
    float: left;
}

.form-group {
    margin-bottom: 1.25rem;
}

.form-check-input:focus {
    border: none;
}

.form-check-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.input-group .form-control {
    height: 40px;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
}

.font-weight-600 {
    font-weight: 600;
}

label {
    margin-bottom: 0.5rem;
}

.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
}

.avatar>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar-title {
    width: 100%;
    height: 100%;
    background-color: #2962ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
}

.avatar-online:before {
    background-color: #7bb13c;
}

.avatar-offline:before {
    background-color: #e84646;
}

.avatar-away:before {
    background-color: #ffbc34;
}

.avatar .border {
    border-width: 3px !important;
}

.avatar .rounded {
    border-radius: 6px !important;
}

.avatar .avatar-title {
    font-size: 18px;
}

.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}

.avatar-xs .border {
    border-width: 2px !important;
}

.avatar-xs .rounded {
    border-radius: 4px !important;
}

.avatar-xs .avatar-title {
    font-size: 12px;
}

.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
    border-width: 1px;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}

.avatar-sm .border {
    border-width: 3px !important;
}

.avatar-sm .rounded {
    border-radius: 4px !important;
}

.avatar-sm .avatar-title {
    font-size: 15px;
}

.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
    border-width: 2px;
}

.avatar-lg {
    width: 3.75rem;
    height: 3.75rem;
}

.avatar-lg .border {
    border-width: 3px !important;
}

.avatar-lg .rounded {
    border-radius: 8px !important;
}

.avatar-lg .avatar-title {
    font-size: 24px;
}

.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
    border-width: 3px;
}

.avatar-xl {
    width: 5rem;
    height: 5rem;
}

.avatar-xl .border {
    border-width: 4px !important;
}

.avatar-xl .rounded {
    border-radius: 8px !important;
}

.avatar-xl .avatar-title {
    font-size: 28px;
}

.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
    border-width: 4px;
}

.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
}

.avatar-xxl .border {
    border-width: 6px !important;
}

.avatar-xxl .rounded {
    border-radius: 8px !important;
}

.avatar-xxl .avatar-title {
    font-size: 30px;
}

.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
    border-width: 4px;
}

.avatar-group {
    display: inline-flex;
}

.avatar-group .avatar+.avatar {
    margin-left: -0.75rem;
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -0.40625rem;
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -0.625rem;
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
    z-index: 1;
}

.card-header-tabs {
    border-bottom: 0;
}

.nav-tabs>li>a {
    margin-right: 0;
    color: #888;
    border-radius: 0;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    border-color: transparent;
    color: #333;
}

.nav-tabs.nav-tabs-solid>li>a {
    color: #333;
}

.nav-tabs.nav-tabs-solid>.active>a,
.nav-tabs.nav-tabs-solid>.active>a:hover,
.nav-tabs.nav-tabs-solid>.active>a:focus {
    background-color: var(--main-bgcolor);
    border-color: var(--main-bgcolor);
    color: #fff;
}

.tab-content {
    padding-top: 20px;
}

.nav-tabs .nav-link {
    border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
    color: #333;
}

.nav-tabs.nav-justified>li>a {
    border-radius: 0;
    margin-bottom: 0;
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
    border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
    border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
    color: #333;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
    background-color: var(--main-bgcolor);
    border-color: var(--main-bgcolor);
    color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
    border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
    border-radius: 50px;
}

.nav-tabs-justified>li>a {
    border-radius: 0;
    margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
    border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
    border-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
}

.nav-tabs.nav-justified.nav-tabs-top>li>a,
.nav-tabs.nav-justified.nav-tabs-top>li>a:hover,
.nav-tabs.nav-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0;
}

.nav-tabs.nav-tabs-top>li {
    margin-bottom: 0;
}

.nav-tabs.nav-tabs-top>li>a,
.nav-tabs.nav-tabs-top>li>a:hover,
.nav-tabs.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0;
}

.nav-tabs.nav-tabs-top>li.open>a,
.nav-tabs.nav-tabs-top>li>a:hover,
.nav-tabs.nav-tabs-top>li>a:focus {
    border-top-color: #ddd;
}

.nav-tabs.nav-tabs-top>li+li>a {
    margin-left: 1px;
}

.nav-tabs.nav-tabs-top>li>a.active,
.nav-tabs.nav-tabs-top>li>a.active:hover,
.nav-tabs.nav-tabs-top>li>a.active:focus {
    border-top-color: var(--main-bgcolor);
}

.nav-tabs.nav-tabs-bottom>li {
    margin-bottom: -1px;
}

.nav-tabs.nav-tabs-bottom>li>a.active,
.nav-tabs.nav-tabs-bottom>li>a.active:hover,
.nav-tabs.nav-tabs-bottom>li>a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: var(--main-bgcolor);
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
}

.nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
}

.nav-tabs.nav-tabs-solid>li {
    margin-bottom: 0;
}

.nav-tabs.nav-tabs-solid>li>a {
    border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a:hover,
.nav-tabs.nav-tabs-solid>li>a:focus {
    background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-solid>.open:not(.active)>a {
    background-color: #f5f5f5;
    border-color: transparent;
}

.nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
}

.nav-tabs-justified.nav-tabs-top>li>a,
.nav-tabs-justified.nav-tabs-top>li>a:hover,
.nav-tabs-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0;
}

.progress-xs {
    height: 4px;
}

.progress-sm {
    height: 15px;
}

.progress.progress-sm {
    height: 6px;
}

.progress.progress-md {
    height: 8px;
}

.progress.progress-lg {
    height: 18px;
}

.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}

.row.row-sm>div {
    padding-left: 3px;
    padding-right: 3px;
}

.avatar {
    position: relative;
    display: inline-block;
}

.avatar>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar-title {
    width: 100%;
    height: 100%;
    background-color: var(--main-bgcolor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
}

.avatar-online:before {
    background-color: #27c24c;
}

.avatar-offline:before {
    background-color: #f05050;
}

.avatar-away:before {
    background-color: #ffbc34;
}

.avatar .border {
    border-width: 3px !important;
}

.avatar .rounded {
    border-radius: 6px !important;
}

.avatar .avatar-title {
    font-size: 18px;
}

.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
}

.avatar-xs .border {
    border-width: 2px !important;
}

.avatar-xs .rounded {
    border-radius: 4px !important;
}

.avatar-xs .avatar-title {
    font-size: 12px;
}

.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
    border-width: 1px;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
}

.avatar-sm .border {
    border-width: 3px !important;
}

.avatar-sm .rounded {
    border-radius: 4px !important;
}

.avatar-sm .avatar-title {
    font-size: 15px;
}

.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
    border-width: 2px;
}

.header {
    background: #3b3b3b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    height: 60px;
    transition: all 0.45s ease 0s;
}

.header .header-left {
    float: left;
    height: 60px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 280px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: none;
}

.header .header-left .logo {
    display: inline-block;
    line-height: 60px;
}

.header .header-left .logo img {
    max-height: 24px;
    width: auto;
}

.header-left .logo.logo-small {
    display: none;
}

.header .dropdown-menu>li>a {
    position: relative;
}

.header .dropdown-toggle:after {
    display: none;
}

.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: "";
    height: 8px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 8px;
    vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
    padding: 12px 0;
}

.user-menu.nav>li>a {
    color: #333;
    font-size: 14px;
    padding: 0 15px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
    background-color: transparent;
}

.user-menu.nav>li>a:hover i,
.user-menu.nav>li>a:focus i {
    color: #333;
}

.user-img {
    display: inline-block;
    margin-right: 3px;
    position: relative;
}

.user-img img {
    width: 40px;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
}

.user-menu.nav>li>a.mobile_btn {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0;
}

.user-menu .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}

.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}

.user-menu .dropdown-menu .dropdown-item:hover {
    color: var(--main-bgcolor);
}

.header .dropdown-menu>li>a:focus,
.header .dropdown-menu>li>a:hover {
    background-color: var(--main-bgcolor);
    color: #fff;
}

.header .dropdown-menu>li>a:focus i,
.header .dropdown-menu>li>a:hover i {
    color: #fff;
}

.header .dropdown-menu>li>a {
    padding: 10px 18px;
}

.header .dropdown-menu>li>a i {
    color: var(--main-bgcolor);
    margin-right: 10px;
    text-align: center;
    width: 18px;
}

.header .user-menu .dropdown-menu>li>a i {
    color: var(--main-bgcolor);
    font-size: 16px;
    margin-right: 10px;
    min-width: 18px;
    text-align: center;
}

.header .user-menu .dropdown-menu>li>a:focus i,
.header .user-menu .dropdown-menu>li>a:hover i {
    color: #fff;
}

.mobile_btn {
    display: none;
    float: left;
}

.slide-nav .sidebar {
    margin-left: 0;
}

.user-header {
    background-color: #f9f9f9;
    display: flex;
    padding: 10px 15px;
}

.user-header .user-text {
    margin-left: 10px;
}

.user-header .user-text h6 {
    margin-bottom: 2px;
}

.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px;
}

.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.sidebar-overlay.opened {
    display: block;
}

html.menu-opened {
    overflow: hidden;
}

html.menu-opened body {
    overflow: hidden;
}

.sidebar {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 280px;
    z-index: 1001;
}

.sidebar.opened {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar-inner {
    height: 100%;
    min-height: 100%;
    transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
    padding: 15px;
}

.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.sidebar-menu li a {
    color: #333;
    display: block;
    font-size: 16px;
    height: auto;
    padding: 0 20px;
}

.sidebar-menu li a:hover {
    color: var(--main-bgcolor);
}

.sidebar-menu>ul>li>a:hover {
    background-color: #e8e8e8;
    color: #000;
}

.sidebar-menu>ul>li.active>a:hover {
    background-color: var(--main-bgcolor);
    color: #fff;
}

.sidebar-menu li.active a {
    background-color: var(--main-bgcolor);
    color: #fff;
}

.menu-title {
    color: #9e9e9e;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}

.menu-title>i {
    float: right;
    line-height: 40px;
}

.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}

.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
    color: var(--main-bgcolor);
    text-decoration: underline;
}

.mobile_btn {
    display: none;
    float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
    transition: all 0.2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
    margin-left: auto;
}

.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}

.sidebar-menu ul ul {
    display: none;
}

.sidebar-menu ul ul ul a {
    padding-left: 65px;
}

.sidebar-menu ul ul ul ul a {
    padding-left: 85px;
}

.sidebar-menu>ul>li {
    margin-bottom: 5px;
    position: relative;
}

.sidebar-menu>ul>li:last-child {
    margin-bottom: 0;
}

.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "font awesome 5 free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 11px;
}

.sidebar-menu .menu-arrow:before {
    content: "\f054";
}

.sidebar-menu li a.subdrop .menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar-menu ul ul a .menu-arrow {
    top: 10px;
}

.sidebar-menu>ul>li>a {
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li a i {
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    vertical-align: middle;
    width: 20px;
    transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
    font-size: 16px !important;
    margin-right: 0;
    text-align: right;
    width: auto;
}

.sidebar-menu li a>.badge {
    color: #fff;
}

.main-wrapper {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}

.page-wrapper {

    position: relative;
    transition: all 0.4s ease;
}

.page-wrapper>.content {
    padding: 1.875rem 1.875rem 0;
}

.page-header {
    margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}

.page-header .breadcrumb a {
    color: #333;
}

.page-title {
    color: #333;
    margin-bottom: 5px;
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}

.checktoggle:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
    background-color: #55ce63;
}

.check:checked+.checktoggle:after {
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0;
}

.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}

.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}

.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0;
}

.custom-modal .modal-body {
    padding: 30px;
}

.login-wrapper {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.loginbox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(55, 73, 72, 0.02);
    max-width: 500px;
    padding: 40px;
    width: 100%;
}

.account-logo img {
    max-height: 80px;
    width: auto;
}

.forgotpass a {
    color: #a0a0a0;
}

.forgotpass a:hover {
    color: #333;
    text-decoration: underline;
}

.dont-have {
    color: #a0a0a0;
    margin-top: 1.875rem;
}

.dont-have a {
    color: #333;
}

.dont-have a:hover {
    text-decoration: underline;
}

.social-login {
    text-align: center;
}

.social-login>span {
    color: #a0a0a0;
    margin-right: 8px;
}

.social-login>a {
    background-color: #ccc;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    margin-right: 6px;
    text-align: center;
    width: 32px;
}

.social-login>a:last-child {
    margin-right: 0;
}

.social-login>a.facebook {
    background-color: #4b75bd;
}

.social-login>a.google {
    background-color: #fe5240;
}

.login-or {
    color: #a0a0a0;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.or-line {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}

.span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 42px;
}

.notifications {
    padding: 0;
}

.notifications .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #bdbdbd;
}

.notifications .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
    border-bottom: none;
}

.notifications .media a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px;
}

.notifications .media a:hover {
    background-color: #fafafa;
}

.notifications .media>.avatar {
    margin-right: 10px;
}

.notifications .media-list .media-left {
    padding-right: 8px;
}

.topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.topnav-dropdown-footer {
    border-top: 1px solid #eee;
}

.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333;
}

.user-menu.nav>li>a .badge {
    background-color: #f43f3f;
    display: block;
    font-size: 10px;
    font-weight: 700;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 7px;
    top: 7px;
    border: 2px solid #fff;
    line-height: unset;
    padding: 0;
}

.user-menu.nav>li>a>i {
    font-size: 1.5rem;
}

.noti-details {
    color: #989c9e;
    margin-bottom: 0;
}

.noti-title {
    color: #333;
}

.notifications .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative;
}

.notification-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifications ul.notification-list>li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
    border-bottom: none;
}

.notifications ul.notification-list>li a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
    background-color: #fafafa;
}

.notifications ul.notification-list>li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}

.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}

.noti-time {
    margin: 0;
}

.dash-widget-icon {
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: inline-flex;
    font-size: 40px;
    height: 80px;
    justify-content: center;
    text-align: center;
    width: 80px;
    background-color: var(--main-bgcolor);
}

.dash-count {
    font-size: 18px;
    margin-left: auto;
}

.dash-widget-info {
    margin-left: auto;
    text-align: right;
}

.dash-widget-info h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.dash-widget-info h6 {
    margin-bottom: 0;
}

.dash-widget-header {
    align-items: center;
    display: flex;
}

.submit-section {
    text-align: center;
    margin-top: 40px;
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}

.profile-menu .nav-tabs.nav-tabs-solid {
    background-color: transparent;
}

.main-wrapper>.header {
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(55, 73, 72, 0.02);
    left: 310px;
    position: fixed;
    right: 50px;
    top: 50px;

}

.page-wrapper {
    border-radius: 25px;
    background-color: #f5f5f5;
    min-height: calc(100vh - 40px);
}

.main-wrapper {
    background-color: #fff;
    height: auto;
    padding: 18px 20px 20px 0 !important;
}

.card {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(55, 73, 72, 0.02);
}

.header.fixed-header {
    margin: 0;
    right: 0;
    border-radius: 0;
    transition: all 0.45s ease 0s;
    top: 0;
    left: 280px;
    background-color: var(--main-bgcolor);
}

.sidebar-logo {
    display: flex;
    padding: 20px 20px 0;
    justify-content: center;
}

.sidebar-logo img {
    max-height: 46px;
    width: auto;
}

.pricing-box .pricing-selected .card {
    background-color: var(--main-bgcolor);
    border-color: var(--main-bgcolor);
    color: #fff;
}

.pricing-box .pricing-selected .pricing-header h2 {
    color: #fff;
}

.pricing-box .pricing-selected .pricing-header p {
    color: #fff;
}

.pricing-box .pricing-selected .pricing-card-price .heading2 {
    color: #fff;
}

.pricing-box .pricing-selected .pricing-card-price p {
    color: #fff;
}

.pricing-box .pricing-selected .btn {
    background-color: #fff;
    color: var(--main-bgcolor);
}

.pricing-box .pricing-selected .pricing-options li {
    color: #fff;
}

.pricing-box .pricing-header {
    margin-bottom: 2rem;
}

.pricing-box .pricing-header h2 {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
}

.pricing-box .pricing-header p {
    color: #9b9b9b;
    font-size: 0.875rem;
    line-height: 1.43;
}

.pricing-box .pricing-card-price {
    margin-bottom: 1.563rem;
}

.pricing-box .pricing-card-price .heading2 {
    margin-bottom: 5px;
}

.pricing-box .pricing-card-price p {
    color: #9b9b9b;
}

.pricing-box .pricing-card-price p+p {
    margin: 0;
}

.pricing-box .pricing-options {
    list-style: none;
    padding: 0;
    margin-bottom: 1.875rem;
}

.pricing-box .pricing-options li {
    margin-bottom: 12px;
    font-size: 0.875rem;
    line-height: 1.43;
    position: relative;
}

.add-button {
    box-shadow: 0 0 12px 0 rgba(255, 0, 128, 0.2);
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.filter-btn {
    box-shadow: 0 0 12px 0 rgba(55, 73, 72, 0.02);
    border: 0;
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
}

.filter-btn:hover,
.filter-btn:focus {
    background-color: var(--main-bgcolor);
    color: #fff;
}

.filter-card .form-group {
    margin-bottom: 1.5rem;
}

.has-error .help-block {
    color: red;
}

small.help-block {
    color: #f44336 !important;
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
    border-radius: 20px;
}

.toggle.ios .toggle-handle {
    border-radius: 20px;
}

.service-header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.service-header h2 {
    font-weight: 700;
}

.service-cate a {
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    background: #d9c505;
    border-radius: 4px;
    font-size: 13px;
    display: inline-block;
}

.service-images {
    margin-bottom: 30px;
}

.widget {
    margin-bottom: 30px;
}

.profile-header {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border: 1px solid #efefef;
    padding: 1.5rem;
}

.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}

.profile-menu .nav-tabs.nav-tabs-solid {
    background-color: transparent;
}

.profile-header img {
    height: 100px;
    width: 100px;
}

.profile-tab-cont {
    padding-top: 1.875rem;
}

.about-text {
    max-width: 500px;
}

.skill-tags span {
    background-color: #f4f4f5;
    border-radius: 4px;
    color: #66676b;
    display: inline-block;
    font-size: 15px;
    line-height: 22px;
    margin: 2px 0;
    padding: 5px 15px;
}



.form-title {
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: inherit;
    color: #333;
    white-space: normal;
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.form-title:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}

.form-title span {
    padding: 0 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 2;
    position: relative;
}

.service-amount {
    color: var(--main-bgcolor);
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-left: auto;
}

.about-author {
    min-height: 80px;
}

.about-provider-img {
    background-color: #fff;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px;
}

.provider-details {
    margin-left: 100px;
}

.ser-provider-name {
    display: inline-block;
    margin-bottom: 5px;
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}

.last-seen {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.last-seen i {
    color: #ffbc34;
    margin-right: 5px;
    font-size: 12px;
}

.last-seen i.online {
    color: #00e65b;
}

.provider-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
}

.available-widget ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.available-widget ul li {
    color: #858585;
    overflow: hidden;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.available-widget ul li span {
    float: left;
}

.available-widget ul li:first-child {
    padding-top: 0;
}

.available-widget ul li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}

.rating i {
    color: #dedfe0;
}

.rating i.filled {
    color: #fbc418;
}

.service-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}

.service-widget .rating i {
    font-size: 14px;
}

.nav-tabs.menu-tabs {
    margin-bottom: 30px;
}

.nav-tabs.menu-tabs .nav-item.active .nav-link {
    color: var(--main-bgcolor);
}

.nav-tabs.menu-tabs .nav-item {
    padding-right: 2.25rem;
}

.nav-tabs.menu-tabs .nav-link {
    padding: 0 0 1rem;
    font-weight: 700;
    position: relative;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    background: 0 0;
}

.nav-tabs.menu-tabs .nav-link::before,
.nav-tabs .nav-link::after {
    position: absolute;
    content: "";
}

.nav-tabs.menu-tabs .nav-link::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: var(--main-bgcolor);
    opacity: 0;
}

.nav-tabs.menu-tabs .nav-item.active .nav-link::after {
    opacity: 1;
}

.nav-tabs.menu-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background: 0 0;
}

.nav-tabs.menu-tabs .nav-link:focus {
    color: var(--main-bgcolor);
}

.nav-tabs.menu-tabs .nav-link .badge {
    margin-left: 0.25rem;
}

.noti-dropdown>a {
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 6px !important;
    min-width: 36px;
}

.noti-dropdown>a::before {
    position: absolute;
    z-index: -1;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    content: "";
    background-color: #e5e5e5;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s;
}

.noti-dropdown>a:hover::before {
    opacity: 1;
    height: 40px;
    width: 40px;
}

.login-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    height: 50vh;
    background-color: var(--main-bgcolor);
    border-radius: 0 0 2.5rem 2.5rem;
    z-index: 0;
}

.login-page {
    background-color: #f5f5f5;
    position: relative;
    padding: 0;
    margin: -20px -20px -20px 0;
}

.login-body {
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 15px;
    min-height: 100vh;
    display: flex;
}

.login-header {
    margin-bottom: 20px;
}

.login-header p {
    margin-bottom: 0;
}

.login-header h3 {
    font-size: 20px;
    margin-bottom: 0;
}

.login-header h3 span {
    color: var(--main-bgcolor);
}

.login-body .form-control {
    height: 50px;
}

.account-btn {
    font-size: 20px;
    font-weight: 500;
    display: block;
    width: 100%;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
}

.login-body .form-control::placeholder {
    color: #bbb;
    opacity: 1;
}

.filter-card .form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.filter-card .form-group label {
    position: absolute;
    background: #fff;
    font-size: 12px;
    left: 10px;
    top: -7px;
    padding: 0 7px;
    color: #858585;
    z-index: 1;
}

.filter-card .form-control {
    height: 48px;
}

.filter-card .btn {
    height: 48px;
}

.filter-card .select2-container .select2-selection--single {
    height: 48px;
}

.filter-card .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 46px;
}

.filter-card .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 46px;
}

#toggle_btn {
    align-items: center;
    color: #333;
    text-decoration: none;
    display: inline-flex;
    float: left;
    font-size: 26px;
    height: 60px;
    justify-content: center;
    padding: 0 20px;
}

.service-carousel .owl-nav {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0;
}

.service-carousel:hover .owl-nav {
    opacity: 1;
    visibility: visible;
}

.service-carousel .owl-carousel .owl-nav div {
    height: 48px;
    width: 48px;
    line-height: 48px;
    top: 50%;
    background: #666;
    color: #fff;
    font-size: 25px;
    position: absolute;
    border: none;
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 100%;
    margin-top: -24px;
}

.service-carousel .owl-carousel .owl-nav div:hover {
    color: #fff;
}

.service-carousel .owl-carousel .owl-nav .owl-prev {
    left: -75px;
}

.service-carousel .owl-carousel .owl-nav .owl-prev:hover {
    left: -65px;
}

.service-carousel .owl-carousel .owl-nav .owl-next {
    right: -75px;
}

.service-carousel .owl-carousel .owl-nav .owl-next:hover {
    right: -65px;
}

.service-carousel .owl-dots {
    margin-top: 50px;
}

.service-carousel .owl-dots .owl-dot {
    margin-right: 5px;
}

.service-carousel .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.service-carousel .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    line-height: 12px;
    margin: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.service-carousel .owl-dots .owl-dot.active span {
    border-color: transparent;
    width: 30px;
    height: 12px;
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}

.checktoggle:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
    background-color: #55ce63;
}

.check:checked+.checktoggle:after {
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0;
}

.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}

.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}

.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0;
}

.noti-contents {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    color: #a1a1a1;
    justify-content: center;
}

#filter_inputs {
    display: none;
}

.service-img {
    width: 60px;
}

.content-textarea {
    height: 300px;
}

.fav-icon {
    width: 32px;
}

.site-logo {
    max-height: 32px;
    width: auto;
}

.admin-noti-wrapper .noti-list {
    padding: 7px 10px;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    margin-bottom: 5px;
    position: relative;
}

.admin-noti-wrapper .noti-list .noti-avatar {
    left: auto;
    height: 40px;
    width: 40px;
    position: absolute;
}

.admin-noti-wrapper .noti-list .noti-avatar img {
    height: 40px;
    width: 40px;
}

.admin-noti-wrapper .noti-list .noti-contents {
    margin-left: 50px;
    font-size: 13px;
}

.admin-noti-wrapper .noti-list .noti-contents h3 {
    color: #333;
    font-size: 16px;
    margin-bottom: 0;
}

.chat-window {
    border: 1px solid #e0e3e4;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.875rem;
}

.chat-window .chat-cont-left {
    border-right: 1px solid #e0e3e4;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4;
}

.chat-window .chat-cont-left .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e3e4;
    color: #324148;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}

.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
    display: inline-flex;
}

.chat-window .chat-cont-left .chat-search {
    background-color: #f5f5f6;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
    align-items: center;
    bottom: 0;
    color: #666;
    display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}

.chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}

.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
    border-bottom: 0;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
    bottom: 7px;
    height: 10px;
    right: 4px;
    position: absolute;
    width: 10px;
    border: 2px solid #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body {
    display: flex;
    justify-content: space-between;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name {
    color: #333;
    text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child {
    text-align: right;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
    background-color: #f5f5f6;
}

.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body>div:last-child .last-chat-time {
    color: #8a8a8a;
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
    background-color: #f5f5f6;
}

.chat-window .chat-cont-right {
    flex: 0 0 65%;
    max-width: 65%;
}

.chat-window .chat-cont-right .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e3e4;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}

.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}

.chat-window .chat-cont-right .chat-header .media {
    align-items: center;
    display: flex;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
    height: 50px;
    width: 50px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}

.chat-window .chat-cont-right .chat-header .chat-options {
    display: flex;
}

.chat-window .chat-cont-right .chat-header .chat-options>a {
    align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}

.chat-window .chat-cont-right .chat-body {
    background-color: #f5f5f6;
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}

.chat-window .chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}

.chat-window .chat-cont-right .chat-body .media .media-body {
    margin-left: 20px;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div {
    padding: 10px 15px;
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div p {
    color: #333;
    margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box+.msg-box {
    margin-top: 5px;
}

.chat-window .chat-cont-right .chat-body .media.received {
    margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media:last-child {
    margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div {
    background-color: #fff;
}

.chat-window .chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div {
    background-color: #e3e3e3;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div p {
    color: #333;
}

.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}

.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #e0e3e4;
    padding: 10px 15px;
    position: relative;
}

.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn,
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: var(--main-bgcolor);
    border-color: var(--main-bgcolor);
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
    width: 40px;
}

.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px;
}

.msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #a0a0a0;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

.msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite 0.33333s;
}

.msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite 0.66666s;
}

.msg-typing span:nth-of-type(3) {
    animation: 1s blink infinite 0.99999s;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
    position: relative;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e3e3e3;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -6px;
    top: 8px;
    width: 0;
}

.chat-msg-info {
    align-items: center;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}

.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}

.chat-msg-info li:not(:last-child):after {
    position: absolute;
    right: 8px;
    top: 50%;
    content: "";
    height: 4px;
    width: 4px;
    background: #d2dde9;
    border-radius: 50%;
    transform: translate(50%, -50%);
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}

.chat-msg-info li a {
    color: #777;
}

.chat-msg-info li a:hover {
    color: #2c80ff;
}

.chat-seen i {
    color: #00d285;
    font-size: 16px;
}

.chat-msg-attachments {
    padding: 4px 0;
    display: flex;
    width: 100%;
    margin: 0 -1px;
}

.chat-msg-attachments>div {
    margin: 0 1px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info {
    flex-direction: row-reverse;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-attachments {
    flex-direction: row-reverse;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}

.chat-attachment img {
    max-width: 100%;
}

.chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden;
}

.chat-attachment {
    border-radius: 0.25rem;
}

.chat-attachment:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    content: "";
    opacity: 0.4;
    transition: all 0.4s;
}

.chat-attachment:hover:before {
    opacity: 0.6;
}

.chat-attach-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 7px 15px;
    font-size: 13px;
    opacity: 1;
    transition: all 0.4s;
}

.chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.4s;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
}

.chat-attach-download:hover {
    color: #495463;
    background: #fff;
}

.chat-attachment:hover .chat-attach-caption {
    opacity: 0;
}

.chat-attachment:hover .chat-attach-download {
    opacity: 1;
}

.chat-attachment-list {
    display: flex;
    margin: -5px;
}

.chat-attachment-list li {
    width: 33.33%;
    padding: 5px;
}

.chat-attachment-item {
    border: 5px solid rgba(230, 239, 251, 0.5);
    height: 100%;
    min-height: 60px;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div:hover .chat-msg-actions {
    opacity: 1;
}

.chat-msg-actions {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s;
    z-index: 2;
}

.chat-msg-actions>a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}

.chat-msg-actions>a:hover {
    color: #2c80ff;
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

.btn-file {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}

.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.chat-search .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.chat-scroll .media {
    display: flex;
}

.chat-scroll .media-body {
    flex: 1;
}

.chat-scroll .badge-pill {
    border-radius: 10rem;
    padding-right: 0.6em;
    padding-left: 0.6em;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.comp-section .close:hover {
    color: #000;
    opacity: 1;
    text-decoration: none;
}

.comp-section .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    border: none;
    background: 0 0;
}

.comp-section .alert {
    position: relative;
    padding: 0.7rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.comp-section .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}

textarea.form-control {
    height: auto;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input {
    color: #dc3545;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.custom-file-input .is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

input[type="file"] {
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

.table-responsive .badge-info {
    color: #fff;
}

.badge-warning {
    color: #212529;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c6c8ca;
}

.table> :not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: url(../img/category/up-down-image-arrow.jpg); */
    background-repeat: no-repeat;
    background-position: 84% 50%;
    background-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    /* background: url(../img/category/alert-image.png), url(../img/category/up-down-image-arrow.jpg); */
    background-repeat: no-repeat;
    background-position: 96% 50%, 98% 50%;
    background-size: 15px;
}

.badge {
    padding: 0.25em 0.4em;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table-responsive .inv-badge {
    color: #000;
}

.notification-message .media {
    display: flex;
}

.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0;
}

.product {
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 1.875rem;
}

.product-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.product-inner img {
    width: 100%;
    height: auto;
}

.product-inner .cart-btns {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart-btns .btn {
    width: 120px;
    display: block;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.cart-btns .btn+.btn {
    margin-top: 10px;
}

.product .pro-desc {
    margin-top: 10px;
}

.pro-desc .price {
    font-size: 18px;
    line-height: 20px;
    color: #333;
    font-weight: 700;
}

.product:hover .cart-btns {
    opacity: 1;
}

.pro-desc h5 {
    font-size: 1rem;
}

.pro-desc h5 a {
    color: #333;
}

.product-det {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    min-height: 40px;
}

.product-desc {
    padding: 0 0 0 70px;
}

.product-det>img {
    top: 0;
    width: 60px;
    position: absolute;
    left: 0;
}

.product-desc span,
.product-desc a {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
}

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}

.calendar-events:hover {
    border-color: #e9e9e9;
    background-color: #fff;
}

.calendar-events i {
    margin-right: 8px;
}

.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: roboto, sans-serif;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
    font-family: roboto, sans-serif;
}

.fc-day {
    background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc th.fc-widget-header {
    background: #eee;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}

.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f3f3f3;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #2962ff !important;
    color: #fff !important;
    text-shadow: none !important;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #fff;
}

.fc-event {
    border-radius: 2px;
    border: none;
    color: #fff !important;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px;
    text-align: center;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: roboto, sans-serif;
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: roboto, sans-serif;
}

.event-form .input-group .form-control {
    height: 40px;
}

.submit-section {
    text-align: center;
    margin-top: 40px;
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

.dropdown-action {
    margin-bottom: 5px;
}

.dropdown-action .dropdown-toggle:after {
    display: none;
}

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
    cursor: pointer;
}

.mail-list {
    list-style: none;
    padding: 0;
}

.mail-list>li>a {
    color: #333;
    display: block;
    padding: 10px;
}

.mail-list>li.active>a {
    color: #2962ff;
    font-weight: 700;
}

.unread .name,
.unread .subject,
.unread .mail-date {
    color: #000;
    font-weight: 600;
}

.table-inbox .starred.fa-star {
    color: #ffd200;
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0;
}

.table-inbox {
    font-size: 15px;
    margin-bottom: 0;
}

.table.table-inbox thead {
    background-color: #fff;
}

.note-editor.note-frame {
    border: 1px solid #ddd;
    box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
    background-color: #fff;
}

.note-editor.note-frame.fullscreen {
    top: 60px;
}

.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333;
}

.mail-title {
    font-weight: 700;
    text-transform: uppercase;
}

.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px;
}

.table-inbox tr {
    cursor: pointer;
}

table.table-inbox tbody tr.checked {
    background-color: #ffc;
}

.mail-label {
    font-size: 16px !important;
    margin-right: 5px;
}

.attachments {
    list-style: none;
    margin: 0;
    padding: 0;
}

.attachments li {
    border: 1px solid #eee;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 180px;
}

.attach-info {
    background-color: #f4f4f4;
    padding: 10px;
}

.attach-file {
    color: #777;
    font-size: 70px;
    padding: 10px;
    min-height: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attach-file img {
    height: auto;
    max-width: 100%;
}

.mailview-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.mailview-footer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 15px;
}

.mailview-footer .btn-white {
    margin-top: 10px;
    min-width: 102px;
}

.sender-img {
    float: left;
    margin-right: 10px;
    width: 40px;
}

.sender-name {
    display: block;
}

.receiver-name {
    color: #777;
}

.right-action {
    text-align: right;
}

.mail-view-title {
    font-weight: 500;
    font-size: 24px;
    margin: 0;
}

.mail-view-action {
    float: right;
}

.mail-sent-time {
    float: right;
}

.inbox-menu {
    display: inline-block;
    margin: 0 0 1.875rem;
    padding: 0;
    width: 100%;
}

.inbox-menu li {
    display: inline-block;
    width: 100%;
}

.inbox-menu li+li {
    margin-top: 2px;
}

.inbox-menu li a {
    color: #333;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.inbox-menu li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #878787;
}

.inbox-menu li a:hover,
.inbox-menu li.active a,
.inbox-menu li a:focus {
    background: rgba(33, 33, 33, 0.05);
}

.compose-btn {
    margin-bottom: 1.875rem;
}

.compose-btn a {
    font-weight: 600;
    padding: 8px 15px;
}

.section-header {
    margin-bottom: 1.875rem;
}

.section-header .section-title {
    color: #333;
}

.line {
    background-color: var(--main-bgcolor);
    height: 2px;
    margin: 0;
    width: 60px;
}

.comp-buttons .btn {
    margin-bottom: 5px;
}

.pagination-box .pagination {
    margin-top: 0;
}

.comp-dropdowns .btn-group {
    margin-bottom: 5px;
}

.progress-example .progress {
    margin-bottom: 1.5rem;
}

.progress-xs {
    height: 4px;
}

.progress-sm {
    height: 15px;
}

.progress.progress-sm {
    height: 6px;
}

.progress.progress-md {
    height: 8px;
}

.progress.progress-lg {
    height: 18px;
}

.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}

.row.row-sm>div {
    padding-left: 3px;
    padding-right: 3px;
}

.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex;
    background: #fff;
}

.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset;
}

.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
}

.error-box h1 {
    color: var(--main-bgcolor);
    font-size: 10em;
    margin-bottom: 0;
}

.error-box p {
    margin-bottom: 1.875rem;
}

.error-box h3 {
    font-size: 2rem;
    margin-bottom: 0;
}

.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 15px 30px;
    color: #fff;
    text-decoration: none;
}

.lock-user {
    margin-bottom: 20px;
    text-align: center;
}

.lock-user img {
    margin-bottom: 15px;
    width: 100px;
}

/* ===================dashboard=================== */

.profile-header {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border: 1px solid #efefef;
    padding: 1.5rem;
}

.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}

.profile-menu .nav-tabs.nav-tabs-solid {
    background-color: transparent;
}

.profile-header img {
    height: auto;
    max-width: 120px;
    width: 122px;
    height: 122px;
}

.profile-tab-cont {
    padding-top: 1.875rem;
}

.about-text {
    max-width: 500px;
}

.skill-tags span {
    background-color: #f4f4f5;
    border-radius: 4px;
    color: #66676b;
    display: inline-block;
    font-size: 15px;
    line-height: 22px;
    margin: 2px 0;
    padding: 5px 15px;
}

.edit-link {
    display: inline-block;
    font-size: 16px;
    margin-top: 4px;
}

.form-title {
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: inherit;
    color: #333;
    white-space: normal;
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.form-title:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}

.form-title span {
    padding: 0 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 2;
    position: relative;
}

.service-amount {
    color: var(--main-bgcolor);
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-left: auto;
}

.about-author {
    min-height: 80px;
}

.about-provider-img {
    background-color: #fff;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px;
}

.provider-details {
    margin-left: 100px;
}

.ser-provider-name {
    display: inline-block;
    margin-bottom: 5px;
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}

.last-seen {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.last-seen i {
    color: #ffbc34;
    margin-right: 5px;
    font-size: 12px;
}

.last-seen i.online {
    color: #00e65b;
}

.provider-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
}

.available-widget ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.available-widget ul li {
    color: #858585;
    overflow: hidden;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.available-widget ul li span {
    float: left;
}

.available-widget ul li:first-child {
    padding-top: 0;
}

.available-widget ul li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}

.rating i {
    color: #dedfe0;
}

.rating i.filled {
    color: #fbc418;
}

.service-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}

.service-widget .rating i {
    font-size: 14px;
}

.nav-tabs.menu-tabs {
    margin-bottom: 30px;
}

.nav-tabs.menu-tabs .nav-item.active .nav-link {
    color: var(--main-bgcolor);
}

.nav-tabs.menu-tabs .nav-item {
    padding-right: 2.25rem;
}

.nav-tabs.menu-tabs .nav-link {
    padding: 0 0 1rem;
    font-weight: 700;
    position: relative;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    background: 0 0;
}





@import url('https://fonts.googleapis.com/css?family=Roboto');


i {
    margin-right: 10px;
}

/*------------------------*/
input:focus,
button:focus,
.form-control:focus {
    outline: none;
    box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
}

/*----------step-wizard------------*/
.d-flex {
    display: flex;
}

.wizard {
    box-shadow: 0px 3px 9px #cccccc;
    padding: 20px;
    margin-top: 80px;
    background-color: #fff;
    padding: 25px;
}

.logo {
    max-height: 175px;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 50px; */
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
    background-color: #333;
}

/* .signup-step-container {
    padding: 150px 0px;
    padding-bottom: 60px;
} */




.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 30px;
    z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #052693;
    font-weight: 500;
    border: 1px solid #ddd;
}

span.round-tab i {
    color: #555555;
}

.wizard li.active span.round-tab {
    background: #052693;
    color: #fff;
    border-color: #052693;
}

.wizard li.active span.round-tab i {
    color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
    color: #052693;
}

.wizard .nav-tabs>li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #052693;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs>li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}

.wizard .nav-tabs>li a i {
    position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
}

.wizard .nav-tabs>li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 20px;
}


.wizard h3 {
    margin-top: 0;
}

.prev-step,
.next-step {
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}

.next-step {
    background-color: #052693;
}

.skip-btn {
    margin-right: 2px;
    background-color: whitesmoke;
}

.skip-btn:hover {
    color: white;
    background-color: #052693;
}

.step-head {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.term-check {
    font-size: 14px;
    font-weight: 400;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

.footer-link {
    margin-top: 30px;
}

.list-content {
    margin-bottom: 10px;
}

.list-content a {
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
    transform: rotate(180deg);
}

.list-content a i {
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}

.list-box {
    padding: 10px;
}

.signup-logo-header .logo_area {
    width: 200px;
}

.signup-logo-header .nav>li {
    padding: 0;
}

.signup-logo-header .header-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-inline li {
    display: inline-block;
}

.pull-right {
    float: right;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
    position: absolute;
    content: "";
    display: inline-block;
}

input[type="checkbox"]::before {
    height: 16px;
    width: 16px;
    border: 1px solid #999;
    left: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 2px;
}

input[type="checkbox"]::after {
    height: 5px;
    width: 9px;
    left: 4px;
    top: 4px;
}

input[type="checkbox"]:checked::after {
    content: "";
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
}

input[type="checkbox"]:checked::before {
    background-color: #18ba60;
    border-color: #18ba60;
}

.tablemain {
    overflow-x: scroll;

}


/* =====================homestyle==================================== */
/* Fixed Sidebar */
.sidenav {
    width: 50px;
    position: fixed;
    z-index: 4;
    top: 19rem;
    background: white;
    overflow-x: hidden;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 15px 15px 0px;
}

.sidenav a {
    padding: 5px 0px;
    text-decoration: none;
    font-size: 25px;
    display: block;
}

.sidenav a:hover {
    color: #064579;
}

.top-left-section {
    display: flex;
    gap: 20px;
    align-items: center;
}


/* .top-nav-section {
	background-color: var(--main-bgcolor);
	padding: 12px 40px;
	display: flex;
	justify-content: space-between;
} */


.download-app-section {
    border: 1px solid black;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    padding: 4px 3px;
}

.download-app-section:hover {
    border: 1px dotted goldenrod;
    border-radius: 8px;
    text-align: center;
    padding: 1px 2px;
}

.download-icon {
    animation: icon 1.4s linear infinite;
    position: relative;
}

@keyframes icon {
    0% {
        top: 0px;
        left: 0%;
    }

    25% {
        top: 0px;
        left: 4px;
    }

    50% {
        top: 5px;
        left: 4px;
    }

    75% {
        top: 5px;
        left: 0px;
    }

    100% {
        top: 0px;
        left: 0px;
    }
}

/* .col-4.top-left-section {
	display: flex;
	justify-content: center;
	gap: 45px;
	padding-right: 70px;
} */

.top-right-section {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    align-items: center;

}

/* Header styles */

.header {
    background-color: var(--main-bgcolor);
    color: white;
    position: sticky;
    top: -1px;
    width: 100%;
    z-index: 50;
    transition: background-color 0.4s, color 0.4s;
}

.header a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

.header a:hover {
    color: white;
}

.header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

.header li {
    position: relative;
}

.header li:hover {
    background-color: #007bff;
}

.header .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 1;
}

.header li:hover .sub-menu {
    display: block;
}

.header .sub-menu a {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
}

.header .sub-menu a:hover {
    background-color: #f0f0f0;
}

.header .search-box {
    display: flex;
    align-items: center;
}

.header .search-box input[type="text"] {
    height: 32px;
    border: 2px solid #000;
    border-radius: 16px;
    padding: 0 16px;
    transition: width 1s ease-in-out, border 0.4s ease-in-out;
}

.header .search-box button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
}

.header .user-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
}

.header .user-icon .hidden-md {
    display: none;
}

/* Responsive styles */




.header-section {
    margin: 5px 135px;
    border-radius: 10px;
    padding: 0px 50px 0px 10px;
}

p.signup-icon-button {
    background: #06406E;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    height: auto;
}

.rank-signup-buttons {
    display: flex;
    gap: 28px;
    justify-content: end;
    text-align: center;
    align-items: center;
}

input.search-border {
    border-radius: 10px 0px 0px 10px;
}

button.search-icon-button {
    border-radius: 0px 10px 10px 0px;
    width: 110px;
}

.col.search-bar-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

p.rank-icon-button {
    background: #06406E;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    height: auto;
}

span.click-renk {
    padding-left: 7px;
    color: white;
}

.click-sign {
    padding-left: 7px;
    color: white;
}

form.example {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    width: 430px;
}

.header-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    /* gap: 20px; */
}


.nav-landing {
    position: relative;
    width: 100%;
    height: 360px;
}

.nav-landing:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0000009c;
    z-index: 1;
}

.nav-contain {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}



.web-hero-section-container {
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0px;
}

h1.web-hero-hadding {
    color: white;
    text-align: center;
    font-size: 3vw;
    line-height: 56px;
    letter-spacing: 1px;

    font-weight: 700;
}

p.web-here-content {
    color: white;
    text-align: center;
    font-size: 22px;
    padding-top: 20px;
    font-weight: 400;
}

button.hero-need-btn {
    background: rgba(255, 124, 0, 1);
    border: 2px solid rgba(255, 124, 0, 1);
    border-radius: 5px;
    padding: 5px 20px;
    margin-top: 30px;
    letter-spacing: 1px;
    word-spacing: 3px;
    /* margin-bottom: 50px; */
}

span.hero-need-coubnceling {
    color: white;
    font-size: 16px;
    font-weight: 500;

}


/* College Section */
.web-for-collegecontent {
    gap: 20px;
    padding: 2px;
    justify-content: space-around;
    flex-wrap: wrap;

}

.colleges-main-section {

    justify-content: center;
}

.web-for-collegehead {
    padding-left: 11px;
}

p.web-colleges-hadding {
    font-size: 36px;
    font-weight: 600;

    line-height: 41.38px;
    color: rgba(18, 89, 148, 1);
}

span.web-colleges-recomnded {
    color: var(--secondary-bgcolor);
}

.web-for-collegecontent {
    padding: 0px 15px;
}

.web-college-1 {
    padding: 20px 0px;
    background-color: rgba(239, 248, 255, 1);
}

.web-college-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-right: 35px;
    padding-left: 17px;
}

.web-colleges-names2 {
    color: rgba(18, 89, 148, 1);
    font-size: 22px;
    font-weight: 600;

}

.web-college-3 {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding-left: 20px;
    padding-top: 0px;
}

.web-college-4 {
    display: flex;
    justify-content: space-around;

    padding-top: 20px;

}

a.course-name-website {
    color: black;
    font-size: 14px;
    font-weight: 600;
    padding-left: 8px;

}

a.course-name-web {
    color: rgba(108, 106, 106, 1);
    padding-left: 5px;
    font-size: 14px;
}

a.course-fee-website {
    color: black;
    font-size: 14px;
    font-weight: 600;
    padding-left: 8px;

}

a.course-price-website {
    color: rgba(108, 106, 106, 1);
    padding-left: 5px;
    font-size: 14px;
}

a.course-name-website:hover,
a.course-name-web:hover {
    color: rgba(18, 89, 148, 1);
    /* Change color on hover */
}


.web-college-5 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    padding-top: 20px;
    gap: 20px;
    padding-bottom: 10px;
}

.web-button1 {
    border: 1px solid whitesmoke;
    border-radius: 8px;
    text-align: center;
    background-color: var(--main-bgcolor);
    color: white;
    padding: 4px 7px;

}



.web-button3 {
    border: 1px solid;
    border-radius: 50px;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 34px;
}

.web-colleges-names1 {
    position: relative;
    top: -30px;
}

.web-college-maincontainer {
    border: 1px solid rgb(226 226 226);

    margin-top: 25px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: whitesmoke;
}


button.look-more-button:hover {
    background-color: #ffa500;

}

button.look-more-button {
    /* border: 2px solid rgba(18, 89, 148, 1); */
    border-radius: 8px;
    text-align: center;
    background-color: var(--main-bgcolor);
    color: white;
    padding: 3px 27px;
    overflow: hidden;
}

button.look-more-button>a {
    text-decoration: none;

}

.look-more-srction {
    text-align: center;
    margin-top: 20px;
    overflow-y: hidden;
    position: relative;

}



/* Explore Programs */


ul.explore-list {
    display: flex;
    list-style: none;

    gap: 22px;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

a.list-explore-iems {
    color: whitesmoke;

    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-explore-courses {
    padding: 23px 15px;
}

.list-explore-iems {
    background-color: var(--main-bgcolor);
    opacity: 1;
    color: white;
    border: 1px solid rgba(202, 202, 202, 1);
    border-radius: 12px;
}



.explore-programs-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.explore-cardss {
    background-color: rgba(18, 89, 148, 1);
    width: 370px;
    height: 184px;
    border-radius: 5px;
    margin: 20px 0px;
}

.explore-comparevs {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
}

img.explore-card-findcollege {
    border: 2px solid white;
    border-radius: 50px;
    padding: 10px;
    background-color: white;
}

.explore-card-find {
    position: relative;
    top: -36px;
    margin-left: 30px;
}

p.explore-find-college {
    color: white;
    padding-left: 15px;
    position: relative;
    top: -20px;
    font-size: 16px;
    font-weight: 600;
}

p.explore-discover {
    color: white;
    padding-left: 15px;
    position: relative;
    top: -20px;
    font-size: 14px;
    font-weight: 400;
}

button.explore-best-btn1 {
    background-color: whitesmoke;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0px 5px;
    font-size: 14px;
}

button.explore-best-btn2 {
    background-color: whitesmoke;
    border: 1px solid white;
    border-radius: 10px;

    padding: 0px 5px;
    font-size: 14px;
}

.explore-best {
    margin: 2px 15px;
}

button.explore-best-btn3 {
    background: white;
    border: 1px solid white;
    border-radius: 13px 13px 0px 0px;
}

.explore-discover-top {
    text-align: center;
    margin-top: 13px;
}

a.explore-best-name3 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(18, 89, 148, 1);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 10px;
    gap: 5px;
}

button.college-pedia-btn1 {
    background-color: rgba(18, 89, 148, 1);
    border: 1px solid white;
    padding: 0px 5px;
    font-size: 14px;
}

button.college-pedia-btn2 {
    background-color: rgba(18, 89, 148, 1);
    border: 1px solid white;
    padding: 0px 5px;
    font-size: 14px;
}

.ranking-buttons {
    margin: 10px 15px;
    position: relative;
    top: -25px;
}

.explore-card-rankbutton {
    text-align: center;
    position: relative;
    top: -28px;
}

button.college-pedia-btn5 {
    background: white;
    border: 1px solid white;
    border-radius: 13px 13px 0px 0px;
}

a.explore-best-rank5 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(18, 89, 148, 1);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 10px;
    gap: 5px;
}

.explore-comparevs2 {
    border: 2px solid;
    border-radius: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 25px 0px;
    padding: 5px 5px;
    background: black;
}

.explore-comparevs {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
    padding-left: 20px;
    position: relative;
    top: -20px;
}

.explore-comparevs1 {
    display: flex;
    /* justify-content: center; */
    gap: 10px;
}

img.college-logo {
    border: 4px solid white;
    border-radius: 3px;
}

a.explore-compare-click1 {
    font-size: 14px;
    text-decoration: none;
}

a.explore-comparev-2 {
    font-size: 14px;
    text-decoration: none;
}

a.explore-comparevs-click2 {
    color: rgba(238, 204, 97, 1);
    font-size: 12px;
    position: relative;
    top: -5px;
    text-decoration: none;
}

a :hover {
    text-decoration: none;
}

a.explore-comparev-3 {

    font-size: 12px;
    position: relative;
    top: -5px;
}

.explore-comparevs-button {
    text-align: center;
    margin-top: 11px;
}

a.explore-comparebtn-click {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(18, 89, 148, 1);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 10px;
    gap: 5px;
}

button.explore-compare-buttn {
    background: white;
    border: 1px solid white;
    border-radius: 13px 13px 0px 0px;
    position: relative;
    top: -45px;
}

/* Top Study Places */
.top-places-states {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: 0px solid rgba(0, 0, 0, 0.25); */
    margin-top: 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex-wrap: wrap;
    background: white;
}

.web-top-places {

    background-color: rgb(255, 246, 247);
}

.top-state-pik {

    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-study-place-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

}

.top-study-places {
    width: 180px;
    height: 80px;
    box-shadow: inset;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 30px 4px;
    transition: all;
    row-gap: 8px;

    transition-duration: 0.8s;
}

.hh {
    transition: all;

}

.hh:hover {
    transition-duration: 0.9s;
    transform: scale(1.12);
}

a.state-namesclk {
    color: black;
    font-size: 14px;
    font-weight: 500;
}


/* Private Govt Colleges */
.checked {
    color: #111;
}

/* .pvt-gvt-colleges {
    padding: 80px 100px;
} */
.pvi-block01 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}

a.ranking-number {
    font-size: 16px;
    color: rgba(32, 68, 150, 1);
    font-weight: 700;
}

.pvt-clg-image {
    /* border: 1px solid; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
}

.web-clg-work {

    background-color: rgb(255, 246, 247);
}

.clg-work-dis {
    margin: 20px 0px;
    border: 1px solid white;
    background-color: white;
    border-radius: 16px;
    padding: 40px 30px;
}

a.clg-pvt-names {
    color: black;
    font-size: 16px;
    font-weight: 700;
}

a.clg-skill {
    color: rgba(32, 68, 150, 1);
    font-size: 16px;
    font-weight: 700;
}

.pvi-block03 {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    /* padding-bottom: 5px; */
    padding: 0px 20px 10px 20px;
}


a.clg-pvt-broch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0px 15px;
    text-align: center;
    font-size: 16px;
    color: white;
}

button.pvt-btnclick {

    background-color: var(--main-bgcolor);
    display: flex;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    /* width: 145px; */
}

button.pvt-btnclick2 {

    background-color: var(--secondary-bgcolor);
    display: flex;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    width: 145px;
}

a.clg-pvt-comp2 {
    text-decoration: none;
}

button>a .pvt-btnclick2:hover {
    text-decoration: none;
}

a.clg-pvt-comp2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 15px;
    text-align: center;
    font-size: 16px;
    color: white;
}

.web-pvt-govt {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 1px;
    border-radius: 8px;
    width: 100%;
    height: 438px;
    position: relative;
    margin-top: 30px;
}

input {
    border-radius: 6px !important;
}

p.pvt-num420-name {
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 10px;
}

.pvi-block04 {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 15px 10px;
    position: absolute;
    bottom: 10px;
    border-top: 2px solid rgba(190, 190, 190, 1);
}

.pvt-govt-colleges {
    display: flex;
    justify-content: center;

}

p.web-pvt-college {
    border: 1px solid rgba(91, 91, 91, 1);
    background-color: whitesmoke;
    padding: 4px 7px;
    border-radius: 25px;
    font-size: 16px;
    color: rgba(91, 91, 91, 1);
    font-weight: 600;
    letter-spacing: 0.9px;
    text-align: center;
}

p.web-pvt-college :hover {
    color: #223222;

    text-decoration: none;
}

.pvt-govt-both {
    display: flex;
    justify-content: center;
    gap: 20px;

    padding: 10px 0px;
    flex-wrap: wrap;
}

.pvt-block-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.pvt-gvt-colleges {
    padding: 0px 100px;
}

.view-div-more {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 30px 0px;
}

/* Mentorship & Guidance */
.web-men-gud {
    background-image: url('../images/mentore.png');
    background-size: cover;
    width: 100%;
    opacity: 1.2;
    background-repeat: no-repeat;
    background-color: rgba(38, 152, 205, 1);

}

p.web-guidance-sec {
    color: white;
    font-size: 22px;
    font-weight: 600;
}

span.web-guidance {
    color: #333;
    border: 1px solid black;
    background-color: lightblue;
    border-radius: 0px 15px 15px 0px;
    padding: 0px 5px;
}

p.ment-paragraph {
    font-size: 16px;
    font-weight: 600;
    color: white;
    padding-top: 10px;
}

.guid-ment01 {
    display: flex;
    justify-content: space-around;
    /* gap: 80px; */
    flex-wrap: wrap;
}

.guid-ment-section {
    margin-top: 35px;
}

.guid-ment02 {
    display: flex;
    justify-content: flex-start;
    gap: 31px;
    align-items: center;
    margin-top: 25px;
    flex-wrap: wrap;
}

p.counc-expert-content {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.guid-ment001 {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: center;
}

/* Confused about the best career-fit for you */


/* .imp-alt-sec2 {
    border: 2px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
} */

.imp-alerts-1 {
    justify-content: center;
}

.imp-alt-category {
    display: flex;
    justify-content: flex-start;
    padding-left: 33px;
    flex-wrap: wrap;
    gap: 30px;

    margin-top: 20px;
    font-size: medium;
    font-weight: 600;
}

a.import-alt-buttons {
    color: black;
    text-decoration: none;
}

p.imp-alt-categories {
    border: 2px solid rgba(202, 202, 202, 1);
    display: flex;
    scale: 1;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    font-weight: 600;

}

.imp-details-main1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

a.imp-alt-details {
    color: rgba(35, 102, 147, 1);
    font-size: 18px;
    font-weight: 500;
}

.imp-details-main {
    border: 2px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 20px;

    width: fit-content;
}

.imp-details-main2 {
    border: 1.5px solid rgba(225, 225, 225, 1);
    height: 30px;
    margin: 0px 15px;
}

.imp-details-main-button {
    display: flex;
    justify-content: flex-start;

    margin-top: 20px;
}

button.imp-view-button {
    border: 1px solid black;
    background-color: rgba(18, 89, 148, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 8px;
    color: white;
    border-radius: 20px;

    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
}


.imp-alerts-second-sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    border: 2px solid white;
    padding: 10px;
    border-radius: 5px;

    background-color: white;
    margin-top: 15px;
}

p.imp-alerts-second-second-details {
    color: rgba(14, 73, 113, 1);
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.imp-alerts-second-second {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.imp-alerts-second-first {
    display: flex;

    justify-content: center;
    align-items: center;
}

.imp-alerts-second-first>video {
    width: 220px;
    height: 100px;

    justify-content: center;
    align-items: center;
}

.imp-alerts-first-sec {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

p.imp-alerts-first-hadding {
    color: white;
    font-size: 23px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

p.imp-alerts-first-hadding {
    color: white;
    font-size: 23px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

a.imp-alerts-first-button {
    font-size: 14px;
    border: 1px solid white;
    border-radius: 20px;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(35, 102, 147, 1);
}

.imp-alerts-second {
    width: 40px;

    background-color: rgba(226, 226, 226, 1);
}

.imp-alerts-ssecond-third {
    width: 150px;
    background-color: rgba(35, 102, 147, 1);
}

.imp-alerts-first {
    background-color: rgba(35, 102, 147, 1);
    padding: 12px;
    width: fit-content;
    border: 2px solid rgba(35, 102, 147, 1);
    border-radius: 15px 0px 0px 15px;
    /* display: flex; */
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.imp-alerts-2 {
    display: flex;
    padding-top: 6px;


}

section.imp-alerts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Student Testimonials */


.student-test-card {
    box-shadow: rgba(99, 99, 99, 0.2) 3px 3px 8px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: solid white;
    text-align: center;
    padding: 30px 50px 0px 44px;
    transition: all 1s;
}

p.student-test-card-para {
    color: black;

    font-size: larger;
    text-align: start;
    justify-content: flex-start;
}

.student-test-card2 {
    padding-top: 15px;
    padding: 4px;
}

img.student-test-card-iamge {
    border: 5px solid rgba(18, 89, 148, 1);
    border-radius: 50%;
}

.student-test-card3 {
    position: relative;
    top: 18px;

}

/* Our Blogs */
/* =======owl-slider================== */


/* Blog section styling */


.author-img img {
    width: 70px !important;
    height: auto;
    border-radius: 100%;
}

.font-medium {
    font-weight: 500;
}

.border-radius-new {
    border-radius: 20px;
}

img.comms {
    width: 40px !important;
    height: auto;
}

.blog-img img {
    display: block;
    max-width: 100%;
    height: auto;
}

.blog-details p {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
}

.blog-details .date {
    color: #727070;
    padding: 7px 0px;
}

.blog-details h4 {
    font-weight: 700;
    font-size: 22px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
}

.blog-btn {
    color: var(--main-bgcolor);
    padding-top: 7px;
    padding-bottom: 4px;
}

.blog-details .date i {
    color: var(--main-bgcolor);
    font-size: 13px;
}

.blog-head h1 {
    font-weight: bold;
}

.blog-dis img {
    transition: 0.9s;
}

.blog-dis .blog-img {
    overflow: hidden;
}



.blog-dis:hover .blog-details h4 {
    color: var(--main-bgcolor);

}

a:hover {
    list-style: none !important;
    text-decoration: none !important;
}


/* ============== */
/* blog-details-sec start */

.blog-details-sec {
    position: relative;
    z-index: 1;

    & .card-b {
        position: relative;

        &::before {
            position: absolute;
            content: "";
            width: 50px;
            height: 50px;
            top: 16%;
            right: -6px;
            background-color: var(--main-bgcolor);
            transform: rotate(45deg);
            z-index: 0;
        }
    }

    & .blog-img img {
        width: 100%;
        max-height: 370px;
        object-fit: cover;
        border-radius: 10px;
    }

    & .pic {
        height: 60px;
        width: 60px;

        & img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid gray;
            padding: 2px;
        }
    }
}

.blog-details-sec #search-s {
    height: 65px;
    background: transparent;
    padding: 0 88px 0 22px;
}

#search-s:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--main-bgcolor);
    outline: 0;
    box-shadow: none !important;
}

.blog-details-sec .s-btn {
    height: 65px;
    width: 70px;
    background: var(--main-bgcolor);
    border: 0;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border-radius: 0;
    z-index: 3;
}

.blog-details-sec .blog-detailsall h4 {
    font-weight: 700;
    font-size: 25px;
    padding: 7px 0px;
}

.blog-details-sec .blog-detailsall .date {
    color: #727070;
}

.blog-details-sec .blog-detailsall .date i {
    color: var(--main-bgcolor);
    font-size: 13px;
}

.blog-details-sec .cap-content {
    padding: 20px;
    border-left: 4px solid var(--main-bgcolor);
    background-color: #eeeeee;
    font-style: italic;
}

.blog-details-sec .b-list li a {
    color: #000;
}

.blog-details-sec .category-blog h4 {
    border-bottom: 1px solid var(--main-bgcolor);
    padding-bottom: 12px;
    display: inline-block;
}

.blog-details-sec .b-list li {
    padding: 8px 0px;
}

.blog-details-sec .category-blog {
    padding: 16px 0px;
}

.blog-details-sec .post-dis img {
    max-width: 70px;
    height: auto;
    object-fit: cover;
}

.blog-details-sec .post-dis {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebe7e7;
}

.blog-details-sec .post-c {
    padding-left: 15px;
}

.blog-details-sec .post-c .date {
    font-size: 12px;
}

.blog-details-sec .post-c .date i {
    color: var(--main-bgcolor);
}

.blog-details-sec .r-post h4 {
    border-bottom: 1px solid var(--main-bgcolor);
    padding-bottom: 12px;
    display: inline-block;
}

.blog-details-sec .media-b {
    display: flex;
    justify-content: space-between;
}

.blog-details-sec .media-b h6 {
    font-weight: bold;
    padding-bottom: 5px;
    font-size: 17px;
}

.blog-details-sec .share-icon i {
    padding-right: 15px;
    color: var(--main-bgcolor);
}

/* ================= */


/* Bottom Footer Section */
.btm-footer {
    background-color: rgba(4, 35, 61, 1);
    padding: 10px 180px;
    margin-top: 1px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    gap: 20px;
}

a.bottom-copy {
    border-right: 2px solid white;
    padding-right: 20px;
}




::-webkit-scrollbar {
    width: 10px;

}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 12px;
}

.testimonial {
    scrollbar-width: thin;

    scrollbar-color: transparent transparent;
}

.testimonial::-webkit-scrollbar {
    width: 0px;
    height: 0px;

}

.testimonial::-webkit-scrollbar-thumb {
    background-color: transparent;

    border: none;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lightgray;
}

/* ScrollingCircle.css */

.scrolling-circle-container {
    position: relative;
}

.scrolling-circle {
    width: 100px;
    height: 100px;
    background-color: transparent;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: fixed;
    top: 20px;
    right: 20px;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
}


/* comments styleing of blogsdesc.js */
.comment_card .card img {
    height: 65px;
    width: 65px;
    border-radius: 50%;

}

.comments-section {
    border-radius: 6px;
}

.comments-section::-webkit-scrollbar {
    width: 5px;

}

.comment_card .card .pic {
    border-radius: 50%;
    border: 1px solid darkgray;
    padding: 3px;
}

.comment_card .contents_comment {
    padding: 15px;
}

.comment_card .commentsa {
    display: ruby-text;
    font-size: 12.5px;
}

.comment_card .commentsa #icon {
    color: var(--main-bgcolor);
}

.comment_card .username {
    background-color: rgba(255, 166, 0, 0.726);
    padding: 2px 8px;
    font-size: 10px;
    color: #000000;
    border-radius: 15px;
    display: inline-block;
    font-weight: 600;
}

.comment_card {
    position: relative;
}


.comment_card .date {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 8px;
    color: darkgray;
}

/* college deiscription */
.third span:nth-child(2) {
    margin-left: 10px;

}

.bright svg {
    font-size: 25px;
}

.info strong {
    font-size: 18px;
}

.about_heading {
    background-color: #fff;
    padding: 14px;
}

.about_heading p:nth-child(1)>strong {
    font-size: 26px;
    color: var(--main-bgcolor);
    text-decoration: underline solid #000;
}

.about_heading p:nth-child(4)>strong {
    font-size: 22px;
    margin-bottom: 5px;
    margin-top: 10px;
}

.about_heading p:nth-child(8)>strong {
    font-size: 22px;
    margin-bottom: 5px;
    margin-top: 10px;
}

/* exam button ccs in allexam.js */

.exam_butn {
    color: var(--secondary-bgcolor);
    border: 2px solid var(--secondary-bgcolor);
    padding: 3px 8px;
    font-weight: 600;
}

/* .sion-blog-sec {
    & .event-item {
        & .thumb {
            position: relative;
        }
    }
}

.sion-blog-sec {
    .thumb:before {
        background-color: var(--secondary-bgcolor);
        border-radius: 6px;
        content: "";
        height: 188px;
        left: -10px;
        position: absolute;
        top: -10px;
        width: 100%;
        z-index:0;
    }
} */
.head .mba h6 {
    font-weight: 500;
}

.allcollege_sidebar {
    position: sticky;
    top: 10vh;
    left: 0;
    height: 80vh;
    overflow: auto;
    padding: 5px 0;

    & button {
        padding: 1px 8px;
        background-color: #fff;
        font-size: 12px;
        border-radius: 6px;
    }
}

.allcollege_sidebar::-webkit-scrollbar {
    width: 3px;
    background: #000;
}

.webkitsc::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.all_cards {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
}

.totalnumberofcollege {
    background-color: #efefef;
    padding: 1px 6px;
    display: inline-block;
    border-radius: 6px;
    margin-bottom: 5px;
    margin-top: 20px;

    & button {
        background-color: #fff2da;
        padding: 1px 8px;
        border-radius: 6px;

    }
}

.breadcrumb-section {
    background: url(../images/attractive-pretty-girl-white-shirt-stands-front-glass-modern-business-building_616370-3593.avif);
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    height: 250px;
    object-position: top;
    padding: 98px 0;
    position: relative;
    width: 100%;

    & .breadcrumb {
        display: flex;
        margin-top: 0;
        background-color: transparent;
        justify-content: center;
    }
}

.breadcrumb-section:before {
    background-color: #0000009c;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.ab-1 {
    color: #fff;
    font-size: 38px;
    text-align: center;
}

.breadcrumb-item {
    font-size: 20px;
    color: gray;
}

.breadcrumb-item.active {
    color: #000 !important;
}

.exam-card-section .card {
    word-wrap: break-word;
    background-clip: initial;
    background-color: #fff;
    border: 0 solid #0000;
    border-radius: .25rem;
    box-shadow: 0 2px 6px 0 #dadafda6, 0 2px 6px 0 #ceceee8a;
    display: flex;
    flex-direction: column;
    height: 325px;
    margin-bottom: 1.5rem;
    min-width: 0;
    position: relative;
    z-index: 2;
}

#sortBy {
    padding: 3px 8px;
    border-radius: 6px;
    margin-left: 8px;
}

.search-bar {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
}

.pagination {
    justify-content: center;
    display: flex;
    margin-top: 10px;
    background-color: #fff;
    padding: 10px;
    border: none;
    align-items: center;

    & .btn {
        background-color: var(--main-bgcolor);
        color: #fff;
        padding: 1px 8px;
        margin: 0 2px;

        &:hover {
            background-color: orange;
        }
    }

    & button {
        background-color: #fff;
        padding: 2px 10px;
        font-size: 15px;

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
}
/*all collegepegae collges page css  */

.filter-btn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 767px) {
    .filter-btn {
      display: block;
    }

    .allcollege_sidebar {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 80%;
      max-width: 300px;
      background-color: #fff;
      z-index: 1000;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }

    .filter-visible .allcollege_sidebar {
        transform: translateX(0);
      }
}
/* =========new-css============ */
.dr-top-btn {
    margin-bottom: 13px;
    border-bottom: 1px dashed #aaa;
    padding-bottom: 8px;
    font-size: 21px;
}

.list-for-drop {
    border-right: 1px solid gainsboro;
}

.list-for-drop svg {
    overflow: hidden;
    color: #06c3ff;
}

.list-for-drop span {
    padding-bottom: 10px;
}

.btnisdropdown {
    top: 29px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    outline: none;
}

/* =============Newhome============================ */
/* signuppage */
.PhoneInput {
    display: flex;
}

.PhoneInputCountry {
    display: flex;
}

.PhoneInput input {
    width: 90%;
}

.PhoneInputCountryIcon {
    width: 40px !important;
    display: flex;
}

.PhoneInputCountryIcon img {
    max-width: 100%;
    width: 25px;
    margin-left: 5px;
}

.PhoneInputCountryIcon svg {
    overflow: hidden;
    width: 25px;
    margin-left: 5px;
}

.PhoneInputCountrySelect {
    width: 58%;
}

/* collage-predictor start */
.college-predictor {
    background-color: #ecedef;
    width: 100%;
}

.college-predictor .college-predictor-data h1 {
    font-size: 48px;
    font-weight: 700;
    color: var(--main-bgcolor);
}


.college-predictor .college-predictor-data span {
    color: var(--secondary-bgcolor);
}

.college-predictor .college-predictor-pera ul li {
    list-style: none;
}

.college-predictor .college-predictor-pera ul {
    padding-left: 0;
}

/* collage-predictor end */
/* best career section start */
.best-career-section .best-career-heading h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--main-bgcolor);
}

.best-career-section .best-career-heading h2 span {
    color: var(--secondary-bgcolor);
}

.best-career-section .best-career-img img {
    width: 100%;
}

.best-career-section .best-career-img {
    position: relative;
}

.best-career-section .best-img3 {
    content: "";
    top: 0;
    right: 0px;
    min-width: 115px;
    height: 115px;
    position: absolute;
    /* background-image: url("images/shape1.svg"); */
    z-index: 0;
}

.best-career-section .best-img {
    position: absolute;
    top: 74px;
    left: 115px;
    min-width: 10px;
    height: 53px;
    z-index: 11;
}

.best-career-section .best-img1 {
    position: absolute;
    top: 106px;
    right: 96px;
    min-width: 100px;
    height: 100px;
    z-index: 11;
}

.best-career-section .best-career-btn a {
    background-color: var(--main-bgcolor);
    border-radius: 50px;
    padding: 5px 20px;
    letter-spacing: 1.2px;
    color: whitesmoke;
    font-size: 18px;
    font-weight: 500;
}
.best-career-section .best-career-btn a i {
    margin-right: 1px !important;
}

.best-career-section .best-career-btn a:hover {
    background-color: orange;
    transition: .3s;
    color: white;
}

/* best career section end */


.static-section .static {
    padding: 65px 30px;
    background-size: cover;
    background-image: url('../images/static.jpg');
    background-position: center center;
    height: auto;
    width: 100%;
    position: relative;
}

.static-section .static .static-head {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 60px 0;
}

.static-section .static .static-text {

    padding-left: 34px;
}

.static-section .static .static-head h1 {
    font-size: 33px;
    color: var(--main-bgcolor);
    font-weight: 700;
}

.static-section .static .static-head h1 span {
    font-size: 33px;
    color: var(--secondary-bgcolor);
    font-weight: 700;
}

.static-section .static .static-head p {
    font-weight: 300;
    line-height: 25px;
}






/* mentorship */
.admission {
    /* background-color: #ecedef; */
    height: auto;
    background-image: url(../images/mandg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    padding: 10px;

    /* box-shadow: inset 0 0 35px 5px rgb(149 144 144 / 25%), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgb(129 123 123 / 25%); */
    & .image {
        visibility: hidden;
    }
}

.admission .main-text,
.procedure {
    color: #222636;
    align-content: center;
    align-items: center;

}

.admission .main-text h1 span {
    color: var(--secondary-bgcolor);
}

.admission .main-text h1 {
    font-weight: 700;
    font-size: 48px;
    color: var(--main-bgcolor);
    padding-bottom: 15px;
}

.admission .procedure img {
    max-width: 50px;
}

.admission .procedure .steps {
    display: flex;
    align-items: center;
}

.admission .procedure p {
    font-size: 15px;
    margin: 0 0 0 5px;
}

.admission .procedure .main-step {
    display: flex;
    gap: 25px;
    margin-bottom: 15px;

}




/* footer-section start */
#footer {
    background: var(--main-bgcolor);
    /* background-image: url(../images/footerback1.png); */
    padding: 0 0 25px 0;
    color: #eee;
    background-repeat: no-repeat;
    font-size: 14px;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(0.5px);
        z-index: 1;
        background-color: #00000073;
    }
}

#footer .footer-top {
    /* background: var(--main-bgcolor); */
    padding: 60px 0 30px 0;

}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
}

#footer .footer-top .footer-info h3 {
    font-size: 26px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    color: #fff;
}

#footer .footer-top .footer-info img {
    height: 70px;
    margin-bottom: 10px;
}

#footer .footer-top .footer-info p {

    line-height: 24px;
    margin-bottom: 0;
    color: #fff;
}

#footer .footer-top .social-links a {
    display: inline-block;
    background: #222636;
    color: #eee;
    line-height: 1;
    margin-right: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#footer .footer-top .social-links a i {
    line-height: 0;
    font-size: 16px;
    text-align: center;
}

#footer .footer-top .social-links a:hover {
    background: white;
    color: red;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
    border-bottom: 2px solid white;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 20px 0px;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 5px;
    color: gray;
    font-size: 15px;
}

#footer .footer-top .footer-links ul li {
    border-bottom: 1px solid gray;
    padding: 4px 0px;
    margin: 12px 1px;
}

#footer .footer-top .footer-links ul li a:hover {
    padding-left: 2px;
    color: orange;
    transition: 0.2s;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #eee;
}


#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    padding-top: 20px;
}

#footer .copyright {
    text-align: center;
    position: relative;
    z-index: 9;
}

#footer .copyright span a {
    color: white;
}

#footer .credits {
    text-align: center;
    font-size: 13px;
    color: #ddd;
}

ul.foot-sec-bt {
    display: flex;
    list-style: none;
    justify-content: left;
    align-items: center;
    gap: 15px;
    margin-top: 18px;
    margin-bottom: 0px;
}

/* footer section end */

/* contact-us-sec-start */
.contact-us-section {

    width: 100%;
    background-color: #c7d0df;
    /* max-height: 511px;
    background-size: cover !important;
    background: url(../images/contact_bg.avif);
    background-position: center center;
    background-repeat: no-repeat; */


}

.contact-us-section .contact-img {
    height: 100%;
}

.contact-us-section .contact-img img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.contact-us-section .book-now {
    padding: 70px;
    padding-bottom: 20px;
    background-color: #ebecee;
    z-index: 1;
    position: relative;
    /* bottom: 26px; */
}

.contact-us-section .PhoneInput {
    display: flex;
    padding: 2px;
    margin-bottom: 30px;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid gray;

}

.contact-us-section .PhoneInputCountry {
    display: flex;
    background-color: transparent;
}

.contact-us-section .PhoneInputCountrySelect {
    width: 45%;
    background-color: transparent;
}

.contact-us-section .PhoneInputInput {
    background-color: transparent;
}

.contact-us-section .section_heading {
    margin-bottom: 50px;

    z-index: 1;
}


.contact-us-section .left_line {
    margin-right: 20px;
}


.contact-us-section .small_heading h6 {
    display: inline-block;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: var(--main-bgcolor);
}



.contact-us-section .right_line {
    margin-left: 20px;
}

.contact-us-section .left_line,
.right_line {
    width: 110px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 2px;
    background-color: var(--main-bgcolor);
}

.contact-us-section.left_line:before {
    left: 0;
}

.contact-us-section .left_line:before,
.right_line:after {
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    top: -3px;
    background-color: var(--main-bgcolor);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.contact-us-section .right_line:after {
    right: 0;
}

.contact-us-section .left_line:before {
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    top: -3px;
    left: 0;
    background-color: var(--main-bgcolor);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.contact-us-section .custom-field {
    padding: 10px;
    border: 1px solid #616F7D;
    margin-bottom: 30px;
}


.contact-us-section .custom-field-icon {
    color: #616F7D;
    font-size: 24px;
    border-right: 1px solid #616F7D;
    padding-right: 10px;
    line-height: 34px;
}

.contact-us-section .custom-form-control {
    background-color: transparent;
    padding: 0 0 0 10px;
    color: #616F7D;
    font-size: 18px;
    line-height: 18px;
    border: none;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.contact-us-section .book-btn {
    font-weight: 500;
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    padding: 15px 20px;
    border: 2px solid var(--main-bgcolor);
    display: inline-block;
    background-color: var(--main-bgcolor);
    position: relative;
    overflow: hidden;
    z-index: 9;
    border-radius: 6px;
}

.contact-us-section input:focus-visible {
    outline: none;
}

.contact-us-section textarea:focus-visible {
    outline: none;
}


/* end */



.sion-blog-sec {

    & .event-item {
        padding: 15px;
        height: 360px;

        & .thumb {

            position: relative;

        }
    }

    /* & .event-content{

    position: absolute;
    top: 40px;
    backdrop-filter: blur();
    background-color: #54535341;
   } */

    .thumb::before {
        position: absolute;
        height: 188px;
        width: 100%;
        background-color: #ffa500;
        top: -10px;
        left: -10px;
        content: "";
        z-index: 0;
        border-radius: 6px;
    }
}

.sion-blog-sec .thumb img {
    border-radius: 6px;
    position: relative;
    z-index: 1;
}



/* blogpage css college pedia yogi baba start css  */
.blog-c .event-item .thumb img {
    width: 100%;
    height: 220px;
    max-height: 100%;
    object-fit: cover;
}

.blog-c {
    & .s_bar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .search-bar {
        width: 100%;
        max-width: 700px;
        background: rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 3px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    }

    & .search-bar input {
        background: transparent;
        border: 0;
        flex: 1;
        padding: 10px 25px;
        font-size: 20px;
        color: var(--main-bgcolor);

    }

    &::placeholder {
        color: darkgray;
    }

    & .search-bar button {
        border: 0;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }
}

.random_blog {
    margin-top: 15px;

    & .long_border {
        border-bottom: 2px solid var(--main-bgcolor);

        & h3 {
            background-color: var(--main-bgcolor);
            padding: 5px 10px;
            color: #fff;
            border-radius: 6px 6px 0 0;

            display: inline-block;
        }
    }
}

.popular_blog {
    & .main_side_card {
        & .popular_col {
            padding: 5px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            gap: 35px;
            border-radius: 6px;
            margin-top: 10px;

            & .content {
                & .title {
                    font-size: 12.5px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                }
            }

            .pic {
                width: 100%;
                height: 100%;
                max-width: 58px;

                & img {
                    object-fit: cover;
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                }
            }

            & .published_on {
                border-top: 1px dashed gray;
                font-size: 10px;

            }


        }


    }

    & .blog_recent {
        /* box-shadow: 0px 12px 28px 0px rgba(140, 149, 159, 0.3); */
        /* box-shadow: -20px 0 10px -20px rgba(0,0,0,0.45) ; */
        box-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;
        position: relative;

        & .readbtn button {
            border-radius: 6px;
            bottom: 40px;
            right: 20px;
            padding: 3px 8px;
            color: #fff;
            background-color: var(--main-bgcolor);

            &:hover {
                background-color: orange;
                color: #fff !important;
            }
        }

        & .pic {
            position: relative;
        }

        & img {
            height: 220px;
            width: 100%;
            border-radius: 6px;
        }

        & .content_blog {
            & .heading h4 {
                font-weight: 700;
                font-size: 22px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;

                /* text-decoration: double underline #000; */
            }

            & .category p {
                background-color: var(--main-bgcolor);
                padding: 3px 8px;
                display: inline-block;
                color: #fff;
                font-size: 12px;
                border-radius: 6px;


            }

            & .description {
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                font-size: 14px;

            }

        }

        & .author_details {
            display: flex;
            justify-content: space-between;

            & img {
                width: 38px;
                border-radius: 50%;
                height: 38px;
                object-fit: cover;
            }

            & .name {
                & p {
                    font-weight: 300;
                    font-size: 11px;
                }

                & h5 {
                    font-size: 15px;
                }
            }
        }
    }

    & .long_border {
        border-bottom: 2px solid var(--main-bgcolor);

        & h3 {
            background-color: var(--main-bgcolor);
            padding: 5px 10px;
            color: #fff;
            display: inline-block;
            border-radius: 6px 6px 0 0;
        }
    }

    & .blog_one {
        position: relative;
        overflow: hidden;
        height: 200px;
        width: 100%;
        margin-top: 15px;
        z-index: 1;
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;

        &:hover .inner {
            top: 50px;

        }

        & .inner {
            position: absolute;
            top: 300px;
            text-align: center;

            transition: top 0.5s ease-out;
            padding: 10px;
            color: #fff;

        }

        & img {
            background-image: url(../images/testi_back.jpeg);
            height: 300px;
            width: 100%;
            border-radius: 6px;
            transition: all 0.5s linear;
        }

        & .category {

            & h3 {
                background-color: #003a90;
                color: #fff;
                padding: 3px 8px;
                position: absolute;
                top: 1px;
                left: 1px;
                z-index: 99;

            }
        }
    }

    .social_media {
        display: flex;
        gap: 12px;
        justify-content: start;

        & .icon {
            font-size: 22px;
        }

        & span:nth-child(1) {
            color: blue;
        }

        & span:nth-child(2) {
            color: #d62976;
        }

        & span:nth-child(3) {
            color: black;
        }

        & span:nth-child(4) {
            color: rgb(44, 5, 160);
        }

        & span:nth-child(5) {
            color: red;
        }

        & span:nth-child(6) {
            color: rgb(63, 63, 129);
        }

    }

    & .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        &>*:nth-child(n) {
            background-color: lightgray;
            color: #000;
            padding: 2px 5px;
            display: flex;
            align-items: center;

            &:hover {
                background-color: orange;
                color: #fff;
            }
        }
    }

    & .category_filter {
        &>*:nth-child(n) {
            background-color: lightgray;
            color: #000;
            padding: 2px 5px;
            display: flex;
            align-items: center;

            &:hover {
                color: #fff;
                background-color: orange;
            }
        }
    }
}

.event-content {

    & .author_details {

        & img {
            width: 38px;
            border-radius: 50%;
            height: 38px;
            object-fit: cover;
        }

        & .name {
            & p {
                font-weight: 300;
                font-size: 11px;
            }

            & h5 {
                font-size: 15px;
                max-width: 150px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
        }
    }
}



.category_of_blog {
    position: absolute;
    background-color: var(--main-bgcolor);
    top: 15px;
    border-radius: 0 6px 0 0;
    color: #fff;
    /* left: 225px; */
    right: 23px;
    z-index: 2;
    padding: 1px 5px;
}

.event-data {
    & .thumb {
        & img {
            height: 200px;
            width: 100%;

        }
    }
}

.blog_one:hover::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 300px;
    background-color: #0000009e;
    border-radius: 6px;
}

.blog-c .event-content {
    padding: 30px;
    background: #f0f7ff;
    position: relative;
    transition: all 0.5s ease 0s;

    & .readbtn button {
        position: absolute;
        bottom: 40px;
        border-radius: 6px;
        right: 20px;
        padding: 3px 8px;
        color: #fff;
        background-color: var(--main-bgcolor);

        &:hover {
            background-color: orange;
            color: #fff !important;
        }
        & a:hover {
            color: #fff !important;
        }

    }
}


.blog-c .event-content .date {
    position: absolute;
    background-color: #06c3ff;
    width: 87px;
    height: 67px;
    border-radius: 8px;
    left: 30px;
    top: -37px;
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: 'Jost', sans-serif;

    & .time {
        font-weight: bold;
    }
}

.Collapsible__contentInner li {
    font-size: 12px;
}

.blog-c .event-content .date strong {
    font-size: 24px;
    display: block;
    margin-top: 10px;
}

.blog-c .event-content h3 {
    font-size: 24px;
    margin-bottom: 10px;

}

.blog-c .event-content h3 a {
    color: #232323;
    font-size: 21px;
    font-weight: 700;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.blog-c .time strong {
    color: var(--main-bgcolor);
}

.blog-c .event-content .time i {
    margin-left: 5px;
    margin-right: 5px;
}

.blog-c .time i {
    color: var(--main-bgcolor);
}

.blog-c .event-content p {
    color: #636161;
    padding: 6px 0px;
    font-size: 15px;
}

.blog-c .time {
    font-size: 13px;
}

.blog-c .event-content h3 a:hover {
    text-decoration: none;
}

.blog-c .event-item {
    overflow: hidden;
    transition: 0.3s;
}

.blog-c .event-item:hover img {
    transition: 0.9s;
    transform: scale(1.2);
}

.blog-c .event-content {
    transition: 0.5s;
}

.blog-c .event-item:hover .event-content {
    background-color: var(--main-bgcolor);
    color: #fff;
}

.blog-c .event-item:hover .event-content h3 a {
    color: #fff;
}

.blog-c .event-item:hover .event-content p {
    color: #fff;
}

/* blog page css end ogi baba  */

/* notification page css  */

.notification-count {
    position: absolute;
    top: 5px;
    background-color: red;
    color: var(--main-bgcolor);
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 50%;
}

.notification-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 450px;
    background-color: #fff;
    z-index: 9999;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;

    /* transition: all 2s; */
}

.notification-content {
    height: 100%;
    overflow-y: auto;
}

.notification-panel .notification_heading {
    position: sticky;
    font-size: 18px;
    color: var(--main-bgcolor);
    font-weight: 600;
    text-align: center;
    padding: 5px 2px;
    cursor: pointer;
}

.notification_heading:hover {
    color: black;
}

.exam_notification .divheading {
    font-size: 17px;
    list-style: inside;
    padding: 5px 4px;
    font-weight: 600;
}

.exam_notification .application_list_exam {
    padding: 10px 2px;
    max-height: 200px;
    overflow-y: auto;

    border-left: 1px dotted black;
    margin: 10px 0px;

}

.exam_notification .upcoming_exam_date {
    padding: 10px 4px;
    max-height: 200px;
    overflow-y: auto;
    border-left: 1px dotted black;
    margin: 10px 0px;

}

.exam_notification li {
    text-transform: lowercase;
    display: flex;
    margin: 4px 0px;
    flex-wrap: nowrap;
    line-height: 1.3;
}

.exam_notification img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid var(--main-bgcolor);
    padding: 2px;
    object-fit: contain;
    margin: 1px 3px;
}

.exam_notification .exam_date {
    font-weight: 500;
    /* text-align: justify; */
    font-size: 13px;
    white-space: nowrap;
}

/* .application_li:nth-child(2){
    width: 100px !important;
} */


.popup_notification .Toastify__toast-container--bottom-right {
    margin-bottom: 12%;
    padding-bottom: 2%;
}

/* NewFooter.css */

.bottom-toast-container {
    bottom: 20px;
    top: auto !important;
    transition: top 0.5s ease;
}

/* End Notification CSS */

/* =============================collegeDashboard============================== */
/* yogi baba css  */

.containe {
    display: flex;

}

.headings {
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 31px;
    padding: 10px 13px;
}

.containe main {
    min-width: calc(100% - 300px);
    /* padding: 23px; */
    /* margin-top: 35px; */
    width: 100%;
    transition: margin-left 0.6s;

}

.r-rating ul {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Newtopbar_dashbord {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
}

.Newtopbar_dashbord .navbar {
    display: flex;
    justify-content: end;
    width: 100%;
    background-color: var(--main-bgcolor);
}

.containe .sidebarbar {
    background: #fff;
    height: 100vh;
    padding: 0 10px;
    transition: all 0.5s;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

.containe .top_section {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0;
}

.containe .logo {
    font-size: 30px;
}

.containe .bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.containe .link {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 14px;
    gap: 43px;
    transition: all 0.5s;
    font-size: 18px;
    border-radius: 6px;
}

.containe .link:hover {
    background: #bababa;
    color: #fff;
    transition: all 0.5s;
}

.containe .link.active {
    background: var(--main-bgcolor);
    color: whitesmoke;
}



.containe .icon,
.containe.link_text {
    font-size: 24px;
}

.Newtopbar .dropdown-toggle::after {
    display: none;
}

.containe .top {
    position: relative;
    background-color: #ebefef;
}





/* college profile abhishek css */
.college_profile .card .college_pic {
    position: relative;
    /* max-height: 50px; */


}

.college_profile {
    padding: 0 21px;
}

.arrow-icon-dash {
    align-items: center;
    display: flex;
}

.college_profile .card .college_pic img {
    width: 100%;
    height: 300px;
    object-fit: cover;


}

.college_profile .card .college_pic .college_log {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    top: 78%;
    left: 3%;
}

.college_profile .card .college_pic .college_log img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* position: absolute; */
    padding: 4px;
    border: 3px solid #475f7b;


}

.college_profile .card .card-body .col_name h5 {
    margin-top: 10px;
    font-size: 19px;
    font-weight: 600;


}

.college_profile .card .card-body .esta {
    color: #000;
    font-size: 14px;

    font-weight: 300;
}

.college_profile .card .col_name {
    display: flex;
    align-items: center;
}

.college_profile #icon {
    background-color: var(--main-bgcolor);

    padding: 8px;

    font-size: 12px;
    margin-left: 4px;
    color: #fff;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.college_profile .card .social {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    font-size: 20px;
}

.college_profile .card .social .insta {
    color: #d62976;
}

.college_profile .card .social .face {
    color: #316FF6;
}

.college_profile .card .social .linked {
    color: #0077b5;
}

.college_profile .card .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.college_profile .card .second .add {
    text-transform: capitalize;
}

/* .college_profile .card .second{
    width: 280px;
  } */
.college_profile .card .second .add h1 {
    font-weight: 700;
    font-size: 18px;
}

.college_profile .card .second .add h2 {
    font-weight: 600;
    font-size: 15px;
    display: contents;
}

.college_profile .card .second .add p {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
}

.college_profile .view .card .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.college_profile .view .card {
    padding: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

.college_profile .view .card #icon {
    color: #fff;
    background-color: var(--main-bgcolor);
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    display: flex;
    font-size: 50px;
    clip-path: none;
}

.college_profile .view .card #data h1 {
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: right;
}

.college_profile .view .card #data p {
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
}

.college_profile .view .card #total {
    color: blue;
}

.college_profile .view .card #on {
    color: darkgoldenrod;
}

.college_profile .view .card #accept {
    color: green;
}

.college_profile .view .card #reject {
    color: red;
}

.college_profile .overview {
    padding: 15px;
}

.college_profile .overview h1 {
    font-size: 27px;
    font-weight: 600;
    color: gray;
    text-transform: capitalize;
    margin-bottom: 5px;
    text-decoration: underline double var(--main-bgcolor);

}

.college_profile .overview p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.college_profile .overview .btnn {
    background-color: var(--main-bgcolor);
    color: #fff;
    font-size: 13px;
    border-radius: 35px;
    float: right;
    margin-top: 10px;

}

/* table.................... */
.college_profile .table .m-head {
    color: #4f7ec2;
    background-color: #f6f7fc;
    padding: 10px;
    border-radius: 5px;


}

.college_profile .table .m-head th {
    border-right: 2px solid black;
    text-transform: capitalize;

}

.college_profile .table .m-data th,
.college_profile .table .m-data td {
    border-right: 2px solid black;
    text-transform: capitalize;
}





/* user profile css  */
/* .profile_of_student{
     margin-left: auto;
     margin-right: auto;
  } */
.profile_of_student .student_image img {
    width: 260px !important;
    height: 275px !important;
    border-radius: 5px;
    border: 3px solid var(--main-bgcolor);
    padding: 5px;
    object-fit: cover;

}

.profile_of_student .student_image {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

}

.profile_of_student .location {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.profile_of_student .clip_stu {
    font-size: 14px;
    font-weight: 700;
    background-color: #e7e5e58a;
    padding: 10px 30px 10px 30px;
    display: inline-block;
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%); */
    /* clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); */
    clip-path: polygon(100% 0, 93% 47%, 100% 100%, 0% 100%, 7% 47%, 0% 0%);
    margin-bottom: 10px;


}

.profile_of_student h1 span {
    font-weight: 600;
}

.profile_of_student .location .icon {
    background-color: var(--main-bgcolor);
    border-radius: 50%;
    padding: 6px;
    color: aliceblue;
}

.profile_of_student .location .ltext {
    color: green;
    font-weight: 600;
    /* margin-left: 5px ; */
}

.profile_of_student .card {
    background-color: #fff;
    box-shadow: 0px 0px 30px #dad8d8ab;
    padding: 15px;
    border-radius: 5px;
    /* display: inline-block; */
    position: relative;

}

.profile_of_student .course_catogory img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 3px solid var(--main-bgcolor);
    padding: 5px;
    object-fit: cover;

}

.profile_of_student .second {
    font-size: 16px;
    font-weight: 600;
    /* box-shadow: inset 0px 0px 30px #f0eeeed6; */
    background-color: #fff;
    /* box-shadow: rgb(0 0 0 / 11%) 3px 0px 0px 1px; */
    box-shadow: 0 25px 50px -12px rgb(238 238 238 / 25%);
    border-radius: 5px;
    padding: 10px 6px 10px 20px;


}

.profile_of_student .name {
    display: flex;
    justify-content: center;
}

.profile_of_student .course_catogory {
    display: flex;
    justify-content: center;
}

.profile_of_student .course {
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    box-shadow: 0.6em 0.6em 1.2em #d2dce9, -0.5em -0.5em 1em #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

/* .profile_of_student .card{
    height: 240px;
  } */
.profile_of_student .hit {
    height: auto;
}



.profile_of_student .down-cards .card #icon {
    color: #fff;
    background-color: var(--main-bgcolor);
    padding: 5px;
    align-items: center;
    display: flex;
    font-size: 50px;
}

.profile_of_student .down-cards .card .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.profile_of_student .down-cards .card .body #data {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}

.profile_of_student .social {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
}

.profile_of_student .links .social .insta {
    color: #d62976;
}

.profile_of_student .links .social .face {
    color: #316FF6;
}

.profile_of_student .links .social .linked {
    color: #0077b5 !important;
}

/* navbar dropdown */
.second_dropdown .dropdown-menu {
    position: absolute;
    left: -110px;
    right: 0 !important;
    
}
.second_dropdown{
    margin-top: 1px;
    & div a span svg{
        font-size:18.5px;
    }
}
/* edit buttonn */
.edit_form button {
    position: relative;
    margin-top: 10px;
    background-color: darkgreen;
    color: #fff !important;

}

.button_of_close {
    color: #fff;
    background-color: var(--main-bgcolor) !important;
}

.button_of_submit {
    color: #fff;
    background-color: darkgreen !important;
}

.view_button .btnn {
    color: #fff !important;
    background-color: var(--main-bgcolor);

}

.view_button {
    margin-top: 10px;
}

.social_links h1 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

/* testimonial css */


.tes .tes-card {
    border-radius: 6px;
    height: 294px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
    padding: 10px;

}

.tes .tes-head {
    font-size: 24px;
    font-weight: 600;
    padding: 5px;
    color: white;
    background-color: #475f7b;
}

.tes .tes-card .tes-description {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.tes .tes-add {
    color: white;
    font-size: 15px;
    font-weight: 500;
    background-color: var(--main-bgcolor);
    border-radius: 6px;
    padding: 5px 10px;
}

.tes .tes-buttons .edit {
    color: #fff;
    background-color: darkgreen;
    border-radius: 5px;
    margin-right: 10px;
    box-shadow: none !important;
}

.tes .tes-buttons .delete {
    color: #fff;
    background-color: #cd201f;
    border-radius: 5px;
    box-shadow: none !important;
}

.close_director {
    background-color: black;
    color: white;
}

.close_director:hover {
    background-color: gray;
}

.save_director {
    background-color: blue;

}


.social_links #youtube {
    color: #cd201f;
}

.social_links #instagram {
    color: #d62976;
}

.social_links #whatsapp {
    color: #25d366;
}

.social_links #facebook {
    color: #316ff6;
}

.social_links #linkedin {
    color: #0a66c2;
}

.social_links #twitter {
    color: #1da1f2;
}

.social_links #telegram {
    color: #0088cc;
}

.social_links #website {
    color: blue;
}

.college_profile .social_links {
    display: flex;
    font-size: 23px;
    gap: 7px;
}





/*   counsellor dashboard director css */
.director .director_card .profile_img {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.director .director_card .profile_img img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: fill;
    padding: 5px;
    border: 2px solid #475f7b;
}

.director .director_card .p_details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.director .director_card #contact {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin-top: 5px;
}

.director .director_card .contact {
    font-size: 25px;
    color: yellowgreen;
}

.director .director_card #email {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.director .director_card .email {
    font-size: 25px;
    color: blue;
}

.director .director_card #website {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.director .director_card .website {
    font-size: 25px;
    color: yellow;
}

.director .director_card .card-body h1 {
    text-transform: capitalize;
    /* width: -webkit-fill-available;
    max-width: 112px; */
}

.director .director_card .card-body p {
    font-size: 14px;
}

.director .director_card #name {
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    color: #475f7b;
    border-bottom: 3px solid #475f7b;
}

.director .director_card #contact h1 {
    font-size: 15px;
    font-weight: 600;
}

.director .director_card #email h1 {
    font-size: 15px;
    font-weight: 600;
}

.director .director_card #website h1 {
    font-size: 15px;
    font-weight: 600;
}

.director .director_card .card {
    position: relative;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;


    padding: 20px 0;
}

.director .director_card .inner_card {
    box-shadow: inset 0 0 0 2px #475f7b;
    min-height: 350px;
    padding: 15px;
}

.director .director_card .social_links {
    margin-top: 15px;
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    gap: 10px;
}

.director .director_card #youtube {
    color: #cd201f;
}

.director .director_card #instagram {
    color: #d62976;
}

.director .director_card #whatsapp {
    color: #25d366;
}

.director .director_card #facebook {
    color: #316ff6;
}

.director .director_card #linkedin {
    color: #0a66c2;
}

.director .director_card #twitter {
    color: #1da1f2;
}

.director .director_card #telegram {
    color: #0088cc;
}

.director .director_card #website {
    color: blue;
}

.director .director_card .buttons {
    position: absolute;
    right: 38px;
    top: -10px;
    margin: 15px 0;
}

.director .director_card .buttons .edit {
    color: #fff;
    background-color: #0482c2;
    border-radius: 5px;
    margin-right: 10px;
    box-shadow: none !important;
    padding: 3px;
}

.profile_of_student .edit {
    color: #fff;
    background-color: #0482c2;
    border-radius: 5px;
    margin-right: 10px;
    box-shadow: none !important;
    padding: 3px;
}

.director .director_card .buttons .delete {
    color: #fff;
    background-color: #cd201f;
    border-radius: 5px;
    box-shadow: none !important;
    padding: 3px;
}

/* .director .director_card {
    padding: 0 38px;
  } */
.director .director_card .inner_email {
    display: flex;
    align-items: center;
}



.close_director {
    background-color: #0a66c2 !important;
    color: #fff;
}

.save_director {
    background-color: darkgreen !important;
    color: #fff;
}

.college_type .form-control {
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add box shadow */
    border-radius: 0.25rem;
    /* Optional: Add border radius for rounded corners */
}

.company_profile_buttons .edit {
    background-color: #0088cc;
}

.company_profile_buttons .delete {
    background-color: #cd201f;
    margin-left: 5px;
}

.middle .middle_para {
    margin-left: 5px;
    background-color: var(--main-bgcolor);
    padding: 2px 5px;
    color: #fff;
    border-radius: 15px;
    font-size: 12px;
}

.first h2 {
    font-weight: 600;
}

/* toggler */
.Newtopbar_dashbord .bars {
    transition: all 0.5s;
    font-size: 26px;
    color: #fff;
}

.top_section .logo {

    margin: auto;
    display: flex;
    flex-direction: column;
    min-width: 64px;
    max-width: 140px;
    padding: 1px 5px 1px 0;
}


.link_text {
    font-size: 15px;
}

.containe main {
    background-color: #ebefefc4;
    padding-bottom: 20px;
}

.usericon svg {
    color: #fff !important;



}


.collapsible a {
    padding-left: 15px;
}

.containe .sidebarbar::-webkit-scrollbar {
    width: 3px;
}

.Newtopbar_dashbord a svg {
    color: #000;
}

/* director cards */
.director_card .left_side {
    border-right: 2px solid var(--main-bgcolor);
    padding: 3px;

}

.director_card .left_side h3 {
    font-weight: 600;
    text-transform: capitalize;
}

.director_card .emaill {
    margin-bottom: 5px;
    align-items: baseline;

    & h3 {
        font-weight: 600;
    }

    & p {
        font-size: 14px;
        margin-left: 15px;
    }

}

.director_card .phone {
    & h3 {
        font-weight: 600;
    }

    & p {
        font-size: 14px;
        margin-left: 15px;
    }

}

.director_card .left_side img {
    width: 120px;
    height: 120px;
    border: none;
}

.director_card .left_side .pic {
    display: flex;
    justify-content: center;
}

.director_card .right_side {
    border-left: 2px solid var(--main-bgcolor);
    padding: 10px;
}

/* scrollbar */

.thin_scroll::-webkit-scrollbar {
    height: 6px;
    border-radius: none;
}

.width_define {
    white-space: break-spaces;
    overflow-wrap: anywhere;
    font-weight: 400;
}

.upcoming img {
    width: 60px;
    height: 60px;
}

.upcoming_btn {
    background-color: var(--main-bgcolor);
    color: #ffffff;
    border-radius: 20px;


}

.leads_btn {
    width: 77px;
}

.common_dash .top h3 {
    font-weight: 600;
    font-size: 25px;
    display: flex;
}

.common_dash .anim {
    animation: myAnim 3s ease-in 1s 2 normal both;

}

@keyframes myAnim {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }

    10% {
        transform: rotate(8deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-10deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-8deg);
    }

    90% {
        transform: rotate(8deg);
    }
}

.common_dash .bread {
    display: flex;
    justify-content: end;
}

.action_btn {
    width: 77px;
    margin-top: 3px;
}

.breadcrumb {
    display: flex;
    /* justify-content: end !important; */
    margin-top: 0;

}

.upcoming_exams h3 {
    font-weight: 500;
    font-size: 25px;
    text-decoration: underline #000;
}


.owl-carousel.owl-loaded {
    position: relative;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: -4%;
    top: 100px;
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: -4%;
    top: 100px;
}

.Newtopbar .comments .pic {

    display: flex;
    justify-content: space-around;

    align-items: center;

    & p {

        white-space: break-spaces;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
}

.Newtopbar .comments .pic img {
    height: 25px;
    width: 25px;
    margin-right: 10px;

}

.first_dropdown .usericon {
    font-size: 24px;
}

.first_dropdown .dropdown-menu {
    width: 300px;
    height: 250px;
    overflow-y: scroll;
    position: absolute;
    left: -278px;
    right: 0 !important;
    /* display: none; */
}

/* .first_dropdown:hover .dropdown-menu {
    display: block;
  } */

.first_dropdown .btn {
    background-color: var(--main-bgcolor);
    color: #fff;
    border-radius: 20px;
    padding: 3px 8px;
    font-size: 12px;
    position: absolute;
    bottom: auto;
    right: 5px;
}

.elig-table {
    position: relative;
    
}
@media (max-width:767px) {
    .elig-table {
        position: relative;
        display: block;
    }
    
}
.elig-btn {
    position: absolute;
    right: 20px;
    top: 108px;
}

.common_dash button {
    border-radius: 5px;

}

.modal-dialog {
    max-width: 1000px;
}

.dashboard_footer {
    background-color: var(--main-bgcolor);
    padding: 20px;
    color: #fff;
}

.btn-sm {
    border-radius: 6px;
}

.innerbar {
    display: none !important;
}




.hback {
    background-color: var(--main-bgcolor);
    color: #fff;
    font-weight: 600;
    font-size: 23px;
    padding: 10px;
    border-radius: 6px;
}

.borderbt {
    padding: 15px 0;
    border-bottom: 1px solid gray;

}

.nav-pills .nav-link.active {
    background-color: var(--main-bgcolor);
}

.nav_of_stu a.navkinks {
    font-size: 15px;
}

.nav_of_stu {
    background-color: var(--main-bgcolor)38;
    border-radius: 6px;

}

.nav_of_stu .tab-content {
    padding-top: 0;
    margin-top: 0 !important;
}

.stu_gender {
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.student_clr {
    color: #0943a1;
}

.director .organization_type {
    font-size: 14px;
    font-weight: 700;
    background-color: #9bd6e18a;
    padding: 10px 30px 10px 30px;
    display: inline-block;
    clip-path: polygon(100% 0, 93% 47%, 100% 100%, 0% 100%, 7% 47%, 0% 0%);
    margin-bottom: 10px;
    margin-top: 25px;

}

.btn-primary:hover {
    background-color: var(--main-bgcolor);
    border-color: var(--main-bgcolor);
}

.btn-primary:focus {
    box-shadow: none;
}

/* homw page testimonial page......................................... */
/* .testimonial_sec{
    padding: 30px 0;
    background-image: url(../../assets/images/testi_back.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  } */
.testimonial_sec h4 {
    font-weight: bold;
}

.testi_card {
    background-color: #f8f9fa;
    /* background: transparent; */

    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 6px;


    & .line-clamp-4 {
        text-align: start;
    }

    & .tetsi-pic {
        padding: 5px;
        border: 2px solid #ffa500;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        position: relative;

        & img {
            width: 100px;
            height: auto;
            object-fit: cover;
        }
    }

    & .description svg {
        font-size: 22px;
        color: var(--main-bgcolor);

        &:nth-child(3) {
            float: right;
        }

    }

    & .line-clamp-4 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

.owl-theme .owl-dots .owl-dot span {
    background-color: var(--main-bgcolor) !important;
    border: 2px solid #0088cc;
    height: 14px;
    width: 14px;
    padding: 2px;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: lightblue !important;
    border: 2px solid #0088cc;
    height: 14px;
    width: 14px;
    padding: 2px;
}

.icon_bg_rotate {
    position: absolute;
    padding: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease-in-out;
    animation-name: rotateme;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &::before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        border-radius: 50%;
        top: 8px;
        right: 8px;
        background: #ffa500;
        transition: all 0.6s ease-in-out;
    }
}

@keyframes rotateme {

    0% {

        transform: rotate3d(0, 0, 1, 0deg);
    }

    25% {

        transform: rotate3d(0, 0, 1, 90deg);
    }

    50% {

        transform: rotate3d(0, 0, 1, 180deg);
    }

    75% {

        transform: rotate3d(0, 0, 1, 270deg);
    }

    100% {

        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.card_college .card-body {
    background-color: #fff;
    border-radius: 8px;
}


.event-content img {
    max-width: 30px;
}

.card_college .card-body .head {
    justify-content: space-between;
}

.card_college:before {
    background-color: #0000007d;
    content: "";
    height: 160px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    border-radius: 6px 6px 0 0;
}

.card_college {
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    height: 370px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 6px;
}

.card_college .card-body {
    background-color: #fff;
    border-radius: 8px;
}

.card_college .card-body .mba1 h6 {
    color: #e7b14e;
    display: flex;
    gap: 4px;
}

.card_college .card-body .main {
    align-items: center;
    position: absolute;
    top: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.logocard {
    padding-bottom: 0;
}

.card_college .card-body .adress,
.card_college .card-body .main .card-pera h4 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;

}

.card-pera h4 {
    color: #000;
    font-weight: 600;
    height: 41px;
    margin-top: 5px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    &:hover {
        color: #007bff;
    }
}

.logocard img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.card_college .mba-img {
    height: 160px;
    object-fit: cover;
    width: 100%;
    border-radius: 6px 6px 0 0;
}

.card_college .card-body .link a {
    color: #000;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 90%;
    align-items: center;
    padding: 2px 3px !important;
    border: 1px solid lightgray;
}

.small-c {
    align-items: center;
    background-color: #fff2da;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2px 7px;
    position: absolute;
    border-radius: 6px;
    top: 30px;
    right: 10px;
}

.blog-sec .card {
    background-color: #eee;

}

.blog-sec .topdivbar {
    display: flex;
    justify-content: space-between;
}

.blog-sec .card .profile .pic img {
    border: 2px solid var(--main-bgcolor);
    border-radius: 50%;
    height: 50px;
    object-fit: contain;
    padding: 1px;
    width: 50px;
}

/* .blog-sec .card .detailes {
    padding-left: 21px;
} */

.blog-sec .card p {
    font-size: 14px;
    margin-bottom: 0;
}

.blog-sec .topdivbar {
    display: flex;
    justify-content: space-between;
}

.headingblog {
    font-size: 22px;
    font-weight: 600;
    padding: 12px 1px;
}

.blog-sec .btn {
    background-color: var(--main-bgcolor);
    color: #fff;
    margin: 5px;
    padding: 4px;
}

.college_cards .card .top-bar #one {
    color: #3eae8d;
    margin-right: 15px;
}

.college_cards .card .bright {
    border-right: 2px solid #999;
    padding-right: 10px;
}

.college_cards .card .top-bar #two {
    color: #ffa100;
    margin-right: 15px;
}

.college_cards .card .bright {
    border-right: 2px solid #999;
    padding-right: 10px;
}

.college_cards .card .third {
    padding-bottom: 10px;
}

.college_cards .card .info {
    border-top: 2px solid #999;
    display: -webkit-box;
    overflow: hidden;
    padding-top: 10px;
}

.titlecollege,
.titlename {
    align-items: center;
    justify-content: center;
}

.titlename {
    display: flex;
    font-size: 28px;
    font-weight: 600;
    gap: 10px;
    margin-bottom: 8px;
}

.titlecollege img {
    margin-right: 10px;
    max-width: 90px;
}

.titledesc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: start;
}

.titledesc span {
    margin-right: 5px;
}

.img-section .div-img {
    position: relative;
}

.img-section .div-img:before {
    /* background-color: rgba(0, 0, 0, .503); */
    background: rgb(63, 94, 251);
    background: linear-gradient(0deg, #052963db 10%, rgba(0, 0, 0, 0.7373074229691876) 81%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

/* .img-section .div-img:after{
    background-color: rgba(0, 0, 0, .503);
    content: "";
    height: 40%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
} */

.img-section .div-img img {
    max-height: 400px;
    object-fit: fill;
    opacity: 1;
    width: 100%;
}

.social_d {
    margin-bottom: -50px;
}

.titlecollege {
    color: #fff;
    display: grid;
    left: 50%;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
}

.react-tabs__tab {
    border: 1px solid #0000;
    border-bottom: none;
    bottom: -1px;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    padding: 6px 12px;
    position: relative;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab-list {
    background-color: var(--main-bgcolor);
    border-radius: 5px;
    color: #fff;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}

.react-tabs__tab {
    border-right: 3px solid #f5f5f5;
    display: flex !important;
    padding: 6px 12px !important;
    white-space: nowrap !important;
}

.react-tabs__tab-list::-webkit-scrollbar {
    height: 5px;
}

.predict_your_college {
    background-image: url(../images/predictor.jpg);
    height: 600px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    position: relative;

    .modal-header {
        background-color: var(--main-bgcolor);
        color: #fff;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            left: 10%;
            top: 50%;
            width: 300px;
            height: 3px;
            background-color: #fff;
        }
    }

    & .pre_btn {
        & button {
            position: absolute;
            top: 450px;
            left: 57px;
            background-color: var(--secondary-bgcolor);
            color: #fff;
            border: none;
            font-size: 22px;
            font-weight: 700;
            border-radius: 6px;
            padding: 3px 10px;

        }

    }

    & .close span {
        color: #fff;
        padding: 5px;
        font-size: 30px;

    }

    & .sub_btn {
        background-color: #052693 !important;
        color: #fff;
        padding: 2px 10px;
        border-radius: 6px;

    }

    .predictor-div {
        position: absolute;
        right: 25px;
        top: 300px;
        background-color: #fff;
        width: 80%;
        border-radius: 6px;

        & .inner {
            display: inline-block;
        }
    }


}

.colleges-main-section {
    & .owl-carousel .owl-nav button.owl-prev {
        left: -4%;
        background-color: var(--main-bgcolor);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .owl-carousel .owl-nav button.owl-next {
        right: -4%;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-bgcolor);
    }

    & .long_border {
        border-bottom: 2px solid var(--main-bgcolor);
        margin-bottom: 10px;

        & h3 {
            background-color: var(--main-bgcolor);
            padding: 5px 10px;
            color: #fff;
            border-radius: 6px 6px 0 0;
            display: inline-block;
        }
    }

    & .head .mba {
        & h6 {
            background-color: #052693;
            color: #fff;
            padding: 3px 7px;
            border-radius: 5px;
        }
    }

    & .main_side_card {
        & .popular_col {
            padding: 5px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            gap: 35px;
            border-radius: 6px;
            margin-top: 10px;

            & .rating {
                border-top: 1px dashed lightgray;
                display: flex;
                color: var(--secondary-bgcolor);
                justify-content: start;

                & svg {
                    font-size: 10px;
                }
            }

            & .content {
                & .title {
                    font-size: 12.5px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                }
            }

            .pic {
                border-radius: 50%;
                width: 100%;
                max-width: 60px;
                height: 70px;

                & img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;

                }
            }

            & .published_on {
                border-top: 1px dashed gray;
                font-size: 10px;

            }

            & .application_date {
                border-top: 1px dashed gray;
                font-size: 10px;
                display: block;

            }
        }

        & .social_media {
            margin-top: 10px;
            display: flex;
            gap: 12px;
            justify-content: start;

            & .icon {
                font-size: 22px;
            }

            & span:nth-child(1) {
                color: green;
            }

            & span:nth-child(2) {
                color: blue;
            }

            & span:nth-child(3) {
                color: #d62976;
            }

            & span:nth-child(4) {
                color: #000;
            }

            & span:nth-child(5) {
                color: #2c05a0;
            }

            & span:nth-child(6) {
                color: red;
            }

            & span:nth-child(7) {
                color: #3f3f81;
            }
        }
    }
}

.main__card {
    padding: 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-top: 10px;

}

.h_of_card {
    height: auto;
    max-height: 400px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.h_of_card::-webkit-scrollbar {
    width: 5px;
}

body {
    background-color: #eef1f1;
}

.pagination button {
    color: blue;
}

.common_dash_form {
    background-color: #cfdef680;
    border-radius: 6px;

    & input,
    select {
        box-shadow: 0px 9px 45px #a2b5d130;
    }


}

.image_of_blog {
    & img {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
}

.notification_heading {
    background-color: var(--main-bgcolor);
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 6px;
}
/* 
.table th,
.table td {
    vertical-align: middle !important;
} */

.profile_of_student h5 {
    font-weight: 600;
}

.edit_awards {
    & img {
        max-width: 100px;
    }
}

.direction_of_btn {
    display: block;
}

@media (max-width:767px) {
    .innerbar {
        display: block !important;
    }

    .top_section .logo {
        display: none !important;
    }

    .owl-carousel .owl-nav button.owl-next {
        position: absolute;
        right: -1%;
        top: 100px;
    }

    .tes {


        & p {
            font-size: 9px;
        }

        & span {
            font-size: 8px;
        }

    }

    .titlecollege {
        & .single_college_bread {
            width: 80%;
        }

        & .college_main_details {
            width: 80%;
        }
    }

    .director {
        & .director_card {
            & .right_side {
                padding-left: 35px;
            }

            & h3 {
                font-size: 12px;
                font-weight: 600;
            }
        }
    }


    .college_profile {
        & h3 {
            font-size: 12px;
            font-weight: 600;
        }
    }
}

/* Landing Page */
.landing_section {
    & .landing_pic {
        width: 100%;
        height: 450px;
        position: relative;

        & img {
            width: 100% !important;
            height: 100%;
        }
    }

    /* & .owl-stage{
        width: 100% !important;
    }
    & .owl-item{
        width: 100% !important;
    } */
    & .landing_content {
        position: absolute;
        top: 33%;
        left: 8%;
        background-color: #ffffff41;
        backdrop-filter: blur(3px);
        /* display: inline-block; */
        border-radius: 6px;
        width: 48%;
        padding: 20px;

        & h3,
        h1,
        h2 {
            font-size: 38px;
            color: #fff;
            font-weight: 600;

        }

        & p {
            font-size: 23px;
            color: #fff;
            font-weight: 400;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        & .landing_btn {
            width: fit-content;
            margin-top: 10px;

            & .btn {
                background-color: var(--main-bgcolor);
                padding: 6px 12px;
                color: #fff;
                display: flex;
                align-items: center;


                &:hover {
                    background-color: orange;
                }
            }
        }
    }

    .owl-carousel .owl-nav button {
        background-color: #fff;
        padding: 10px;
    }

    .owl-carousel .owl-nav button.owl-prev {
        position: absolute;
        left: 93.5%;
        top: 161px;
        background-color: var(--main-bgcolor);
        height: 50px;
        width: 50px;
        color: #fff;
        border-radius: 50%;
        opacity: 0.9;
    }

    .owl-carousel .owl-nav button.owl-next {
        position: absolute;
        left: 93.5%;
        top: 100px;
        background-color: var(--main-bgcolor);
        height: 50px;
        width: 50px;
        color: #fff;
        border-radius: 50%;
        opacity: 0.9;
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #052963 !important;
        border: 2px solid #0088cc;
        height: 14px;
        width: 14px;
        padding: 2px;
    }

    .owl-theme .owl-dots .owl-dot {
        display: inline-block;
        zoom: 1;
    }

}

/* single college */
.img-section {
    position: relative;

    & .titlename {
        display: flex;
        align-items: flex-start;

        & img {
            height: 200px;
            object-fit: contain;
            background-color: #fff2da;
            height: auto;
            padding: 5px;
            border-radius: 6px;

        }
    }
}

.single_college_bread {

    & .breadcrumb {
        background-color: transparent;

        & a {
            color: #fff;
            font-size: 10px;
            text-transform: capitalize;
        }

        & .breadcrumb-item.active {
            color: lightgray !important;
            font-size: 10px;
            text-transform: capitalize;
        }

        .breadcrumb-item {
            display: flex;
            align-items: center;

        }

    }


}

.college_main_details {
    display: flex;
    gap: 15px;

    & p {
        font-size: 12px;
    }

    .logo_pic {
        background-color: #fff2da;
        padding: 5px;
        border-radius: 6px;
        height: max-content;
        width: 70px;
        display: flex;
        justify-content: center;

        & img {
            height: 70px;
            width: 70px;
            object-fit: contain;
            margin-right: 0;
        }
    }

    .college_name {
        & .other_details {
            display: flex;
            align-items: center;
            gap: 10px;


            &>*:nth-child(n) {
                &::after {
                    content: "|";
                    margin-left: 2px;
                }

            }
        }

        & .c_name {
            & .name {
                font-size: 25px;
                font-weight: 500;
                text-align: start;
            }
        }
    }
}

.react-tabs__tab--selected {
    /* background-color: var(--main-bgcolor); */
    font-size: 14px;
    font-weight: 600;
    color: orange !important;
    position: relative;
    border-radius: 0 !important;

    &::before {
        position: absolute;
        content: "";
        bottom: 4px;
        left: 0;
        background-color: orange;
        width: 100%;
        height: 2px;
        z-index: 9;



    }
}

.react-tabs__tab {
    background-color: #fff;
    color: var(--main-bgcolor);
    border: none !important;
    border-radius: 0 !important;
}

.react-tabs__tab-list {
    border-radius: 0;
    border: none;
    background-color: #fff;
    padding: 5px;
    position: relative;
    margin-top: 12.5px !important;

    &::-webkit-scrollbar {
        height: 0;
    }

    /* & li:nth-child(1){
        background: rgb(5,41,99);
        background: linear-gradient(90deg, #05296375 0%, rgba(255,255,255,1) 44%, rgba(255,255,255,1) 100%);
    }
    & li:nth-last-child(){
        
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 44%, #05296375 100%);
    } */
}

.tabs_prev_btn {
    position: absolute;
    left: -9px;
    top: 19.5px;
    color: #fff;
    background-color: orange;
    padding: 5px;
    border-radius: 50%;

    & svg {
        font-size: 10px;
    }

    &:hover {
        background-color: var(--main-bgcolor);
    }

    &:focus {
        box-shadow: none;
    }
}

.tabs_next_btn {
    position: absolute;
    right: -9px;
    top: 19.5px;
    color: #fff;
    background-color: orange;
    padding: 5px;
    border-radius: 50%;

    & svg {
        font-size: 10px;
    }

    &:hover {
        background-color: var(--main-bgcolor);
    }

    &:focus {
        box-shadow: none;
    }
}

.btnbroucher {
    & .btn {
        background-color: var(--main-bgcolor);
        width: 100%;
        color: #fff;
        padding: 5px;
        display: flex;
        justify-content: space-around;
        margin-top: 12.5px;
        border-radius: 6px;

        &:hover {
            background-color: orange;
        }
    }
}

.btnapply {
    & .btn {
        background-color: orange;
        width: 100%;
        color: #fff;
        padding: 5px;
        margin-top: 10px;
        border-radius: 6px;
        display: flex;
        gap: 15px;

        &:hover {
            background-color: var(--main-bgcolor);
        }
    }
}

.single_col_pform {
    & .first-div {
        background-color: #fff !important;
    }

    & .sub_btn {
        background-color: var(--main-bgcolor);
        color: #fff;
        border-radius: 6px;

        &:hover {
            background-color: orange;
        }
    }
}

.single_college_section {
    & .small_banner {
        margin-top: 10px;
        background-color: #fff;
        border-radius: 6px;
        padding: 6px;

        & img {
            border-radius: 6px;
            width: 100%;
            height: 130px;

        }
    }

    & .gallery {
        background-color: #fff;
        padding: 8px;
        margin-top: 10px;
        border-radius: 6px;

        & .picc {
            & img {
                padding-right: 5px;
                height: 120px;
                width: 100%;
                border-radius: 6px;
            }
        }
    }


    & .long_border {
        border-bottom: 2px solid var(--main-bgcolor);
        ;

        & h3 {
            background-color: var(--main-bgcolor);
            padding: 5px 10px;
            color: #fff;
            display: inline-block;
            border-radius: 6px 6px 0 0;
        }
    }
}

.single_page_heading {
    font-weight: 700;
    font-size: 18px;
    color: orange;
    margin-left: 3px;
    margin-bottom: 10px;
    text-decoration: double underline var(--main-bgcolor);
}

.spec_padding {
    padding: 0 !important;

    & div {
        & .table-data {
            & .container {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

}

.react-tabs__tab-panel--selected {
    & div .tab_contents div {
        background-color: #fff;
        padding: 14px;
        border-radius: 6px;
    }
}

.main_h_fluid_content {
    height: 240px;
}

/* .single_college_section{
    & .collapsible{
        & .collapsible-body{
            & p{
                & p{
                    & span{
                        padding: 0px 8px;
                        & .strong{
                            padding: 0px 8px;
                        }
                    }
                }
            }
        }
    }
} */
.tab_contents {
    & div {
        & ul {
            list-style: disc;
        }

        & h1 span span {
            font-family: "Oswald", sans-serif !important;
        }

        & h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        & p span span {
            font-family: "Oswald", sans-serif !important;

        }
    }
}

tr {
    /* & td:nth-child(1) {
        width: 300px;
    } */

    td ul {
        list-style: none !important;
    }
}

.collapsible {
    box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08);
    padding-left: 0;

    & .collapsible-body {
        & p {
            & p {
                & span {
                    font-weight: 300;
                }
            }
        }
    }
}

.btn_of_view {
    font-weight: 600;
    color: orange;
    width: 100%;
    position: relative;
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    padding: 10px 0 8px 0;
    z-index: 9;

    &::before {
        content: "";
        position: absolute;
        bottom: 33px;
        left: 0;
        width: 100%;
        height: 53px;
        background: linear-gradient(0deg, rgb(5 41 99 / 16%) 4%, rgba(255, 255, 255, 1) 90%);
    }

}

.single_college_section {
    & .course_fees {
        & .pic_of {
            background-color: #fff2da;
            border-radius: 6px;
            padding: 10px 50px;
        }

        & .date {
            background-color: #fff;
            box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
            border-radius: 6px;
            border: 2px solid var(--main-bgcolor);
            font-size: 12px;

            & p {
                font-weight: 500;

            }
        }

        & .eligib {
            background-color: #fff;
            box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
            border-radius: 6px;

            & h3 {
                font-weight: 600;
            }
        }
    }

    & .owl-carousel .owl-nav button.owl-next {
        right: -4%;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-bgcolor);
    }

    & .owl-carousel .owl-nav button.owl-prev {
        right: -4%;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-bgcolor);
    }

    & .exam_name {
        font-weight: 500;
        font-size: 14px;

        &:hover {
            color: #007bff;
        }
    }

    & .main_side_card {
        & .popular_col {
            padding: 5px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            gap: 35px;
            border-radius: 6px;
            margin-top: 10px;
        }
    }

    & .content {
        & .title {
            font-size: 12.5px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;

        }

        & .description {
            font-size: 9px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }
    }

    & .pic {
        border-radius: 50%;
        width: 100%;
        max-width: 75px;
        height: 70px;


        & img {
            object-fit: cover;
            height: 70px;
            width: 100%;


        }
    }

    & .rating {
        border-top: 1px dashed lightgray;
        display: flex;
        color: var(--secondary-bgcolor);
        justify-content: start;
    }

    & .published_on {
        border-top: 1px dashed gray;
        font-size: 10px;
    }

    & .collapsible div:nth-child(1) {
        text-decoration: underline;

    }

    & .collapsible div:nth-child(2) {
        display: flex;
        gap: 5px;

        &::before {
            content: "Ans.";
            left: 0;
        }
    }
}

.for_sticky {
    top: 100px;
    left: 0;
    position: sticky;
    position: -webkit-sticky;
}

@media (max-width:767px) {
    .main_h_fluid_content {
        height: 350px;
    }

    #college-content-section {
        & .social_college {
            margin-top: 6px;
        }
    }

    .college_main_details {


        .logo_pic {
            display: none;

        }
    }

}

.all_exams_page {
    & .title {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    & .long_border {
        border-bottom: 2px solid var(--main-bgcolor);
        margin-bottom: 10px;

        & h3 {
            background-color: var(--main-bgcolor);
            padding: 5px 10px;
            color: #fff;
            border-radius: 6px 6px 0 0;
            display: inline-block;
        }
    }

    & .long_border_red {
        border-bottom: 2px solid red;
        margin-bottom: 10px;
        /* display: flex;
        justify-content: end; */


        & h3 {
            background-color: red;
            padding: 5px 10px;
            color: #fff;
            border-radius: 6px 6px 0 0;
            display: inline-block;
            width: 100%;
            text-align: center;

        }
    }

    & .main__card {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        & .card_exams {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
            height: auto;
            padding: 10px;

            & .mode {

                background-color: #fff2da;
                padding: 2px 7px;

                & p {
                    font-size: 12px;
                    font-weight: 600;

                }

            }
        }

        & .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .pic {
                width: 100%;
                height: 100%;
                max-width: 55px;

                & img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }

        & .middle {
            box-shadow: 0px 0px 4px #ccc;
            padding: 7px 10px;
            border-radius: 6px;
            margin-top: 13px;

            & .desc {
                display: block;

                & span:nth-child(1) {
                    font-size: 15px;
                    font-weight: 600;


                }

                & p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                    padding: 5px;
                }

                & span h3 strong {
                    font-size: 14px;
                }
            }

            & .cate {
                & span:nth-child(1) {
                    font-size: 15px;
                }

                & span:nth-child(2) {
                    font-size: 12px;
                    color: #fff;
                    background-color: var(--main-bgcolor);
                    padding: 3px 7px;
                    border-radius: 5px;
                }
            }

            & h3 {
                color: #2c59a1;
                text-decoration: underline;
                font-weight: 600;
                font-size: 15px;


            }

            & h4 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & span:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #2c59a1;
                    text-decoration: underline;
                }

                & span:nth-child(2) {
                    font-size: 17px;
                    font-weight: 600;
                    color: var(--main-bgcolor);


                }

                & span:nth-child(3) {
                    font-size: 14px;
                    font-weight: 400;
                    border: 2px solid gray;
                    padding: 2px;
                    border-radius: 6px;

                }

            }

            & p {
     
                border: 1px dashed #cbc8c8;
                /* text-decoration: underline; */
                padding: 2px;
                border-radius: 6px;

                & span:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;

                }

                & span:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        & .bottom {
            & .view_button {
                background-color: var(--main-bgcolor);
                color: #fff;
                padding: 2px 8px;
                margin-top: 20px;
            }
        }
    }

    & .owl-carousel .owl-nav button.owl-prev {
        left: -4%;
        background-color: var(--main-bgcolor);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .owl-carousel .owl-nav button.owl-next {
        right: -4%;
        background-color: var(--main-bgcolor);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & select {
        box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    }

    & select:focus-visible {
        border: none;
        outline: none;

    }

    & .appli_dropd {
        & label {
            display: flex;
            align-items: center;
            gap: 6px;

            & svg {
                color: #2c59a1;
            }
        }
    }

    & .h_of_card_alerts {
        height: auto;
        width: 100%;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        /* box-shadow: inset 0 0 35px 5px rgba(0,0,0,0.25), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgba(0,0,0,0.25); */
        /* box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35); */
        box-shadow: 0px 80px 50px -30px rgba(0, 0, 0, 0.15);
        padding: 0 10px;

        /* &::before{
            position: absolute;
            content: "";
            top: 0;
            left: 0px;
            width: 100%;
            height: 10px;
            z-index: 9;
            background-color: #ededed;
            padding: 0;
         } */
        & .alert_card {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 0;
            box-shadow: 0px 80px 50px -30px rgba(0, 0, 0, 0.15);

            & .logo {
                width: 100%;
                height: 100%;
                max-width: 55px;

                & img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            & .content {
                & h3 {
                    font-size: 14px;
                    font-weight: 600;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;

                    &:hover {
                        color: orange;
                    }
                }

                & p {
                    font-size: 12px;
                    font-weight: 400;
                    border-top: 1px dashed gray;

                    &span:nth-child(1) {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    & .search-bar {
        width: 100%;
        max-width: 700px;
        background: rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 3px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    }

    & .search-bar input {
        background: transparent;
        border: 0;
        flex: 1 1;
        padding: 10px 25px;
        font-size: 20px;
        color: var(--main-bgcolor);
    }


}


/* css chatbot css start  */
/* MainLayout.css */
/* MainLayout.css */

.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.5 ease-in-out;
}

.chat-icon:hover {
    background-color: #f0f0f0;
}

.chat-icon.open {
    transform: scale(1.1);
}

.close-icon {
    cursor: pointer;
}

/* ChatBot.css */

.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    margin-top: 10px;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(100%);
}

.chatbot-container.open {
    opacity: 1;
    transform: translateY(0);
}

.close-icon {
    cursor: pointer;
}

.react-chatbot-kit-chat-message-container {
    height: 400px !important;
    width: 312px;
    padding: 10px;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
}

.react-chatbot-kit-chat-input-container {
    width: 312px !important;
}

.react-chatbot-kit-chat-inner-container {
    width: 312px;
}

.chatbot_main_heading {
    background-color: var(--main-bgcolor);
    padding: 12px 5px;
    color: #fff;
    text-align: center;
}

.chatbot_ans_btn {
    color: #fff;
    background-color: orange;
}

.react-chatbot-kit-chat-bot-avatar-container {

    font-weight: 600;
    background-color: darkcyan !important;

    & p {
        color: #fff;
    }
}

.chatbot_cross_btn {
    position: relative;
    color: white;
}

.typing_style {
    position: absolute;
    bottom: 75px;
    right: 12px;
    text-shadow: #000 1px;
    color: orange;
}


/* share social media accounts button and sharre blog  */
.share-buttons-container {
    position: relative;
    display: inline-block;
}

.share-buttons-list {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 35px;
    /* Adjust as necessary */
    left: 0;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
}

.fa-share-from-square.icon {
    cursor: pointer;
    font-size: 24px;
}

.share-buttons-list>* {
    cursor: pointer;
}

/* Tabs Section */
.warpper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
}

.herosection {
    background-color: rgb(255, 246, 247);
}

.panels {
    /* background: white; */
    width: 100%;
    max-width: 1195px;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 15px;

    & .pic {
        & img {
            height: 65px;
            width: 65px;
            object-fit: contain;
        }
    }

    & .second {
        & .rating {

            & ul li svg {
                height: 12px;
                width: 12px;
            }
        }

        & .mode {
            background-color: var(--main-bgcolor);
            padding: 2px;
            font-size: 14px;
            color: #fff;
            border-radius: 5px;
            margin-bottom: 8px;

        }
    }
}

.glider {
    position: absolute;
    display: flex;
    height: 44px;
    width: 150px;
    background-color: #cdddf6;
    color: #fff;
    z-index: 1 !important;
    border-radius: 6px;
    transition: 0.25s ease-out;
}

.tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    padding: 8px 9px 0 9px;
    border-radius: 8px;

    * {
        z-index: 2;
    }
}

input[id="one"] {
    &:checked {
        &~.tabs {
            .glider {
                transform: translateX(0);
            }
        }
    }
}

input[id="two"] {
    &:checked {
        &~.tabs {
            .glider {
                transform: translateX(100%);
            }
        }
    }
}


#three {
    &:checked {
        &~.tabs {
            .glider {
                transform: translateX(200%);
            }
        }
    }
}

#four {
    &:checked {
        &~.tabs {
            .glider {
                transform: translateX(300%);
            }
        }
    }
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;

}

.panel {
    display: none;
    animation: fadein .8s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.panel-title {
    font-size: 1.5em;
    font-weight: bold
}

.radio {
    display: none;
}

.panel {
    /* background-color: #f5f5f5; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

/* Style the grid and columns */

#one:checked~.panels #one-panel,
#two:checked~.panels #two-panel,
#three:checked~.panels #three-panel,
#four:checked~.panels #four-panel {
    display: block
}

/* #one:checked~.tabs #one-tab,
#two:checked~.tabs #two-tab,
#three:checked~.tabs #three-tab,
#four:checked~.tabs #four-tab {
    background-color: var(--main-bgcolor);

} */

/* Inner Tabs    College top tabs section  */

.grids {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
}

.cols {
    text-align: center;
    background-color: #fff;
    /* border-radius: 12px; */
    gap: 4px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    transition: transform 0.7s ease-in-out, box-shadow 1s ease-in-out;
}

.cols:hover .web-tabs-links a {
    color: #006bb1;
}

.web-tabs-images img {
    max-width: 100%;
    height: auto;
}


.web-tabs-images img:hover {
    transition: transform 0.8s ease;

}


.web-tabs-para {
    /* padding-top: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-tabs-links {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    margin: 0px;
    padding: 0px;
    justify-content: center;
    align-items: center;
}


.tabs-links-click {
    color: #000;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.5px;
}

/* .web-tab-content {
	padding: 14px 4px;
	gap: 2px;

} */



/* css single page college single tabs button prev and nex */
/* .tab-container {
  display: flex;
  align-items: center;
  position: relative;
}

.tab-container .btn {
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
}

.tabs_prev_btn, .tabs_next_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.tabs_prev_btn {
  left: 0;
}

.tabs_next_btn {
  right: 0;
}

.tab-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
} */
.content_of_card {
    & .counslrname {
        & h3 {
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }
    }

    & .area_of_operation {
        & h3 {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            &>*:nth-child(n) {
                display: flex;
                align-items: center;
                padding: 2px 5px 3px 5px;
                background-color: var(--main-bgcolor);
                border-radius: 5px;
                color: #fff;
                font-size: 12px;

            }
        }
    }

    & .address {
        margin-top: 5px;

        & h3 {
            font-weight: 400;
            font-size: 16px;
        }
    }
}

::selection {
    background-color: #000;
    color: #fff;
}

.breadcrumb-item {
    font-size: 20px;
    color: white;

    & a:hover {
        color: orange !important;
    }
}

.breadcrumb-item.active {
    color: #e2dfdf !important;

    & a:hover {
        color: orange !important;
    }
}

.pedia_logo {
    padding: 4px 0;

    & a img {
        max-width: 135px;

    }
}

.main__navbar {
    & a:hover {
        color: orange;
    }

    & .nav-item {
        position: relative;



        &:hover::after {
            position: absolute;
            content: "";
            background-color: #fff;
            top: 26px;
            left: 8px;
            width: 30px;
            height: 30px;
            transform: rotate(45deg);
            z-index: 99;
            border-radius: 5px;

        }
    }

    .dropdown {
        position: relative;

        &:hover .dropdown-menu {
            display: block;
            left: auto;
            /* Ensure the menu aligns correctly */
        }
    }

    & .nav-link {
        padding: 0;
    }

    & .dropdown-menu {
        position: absolute;
        left: 50%;
        z-index: 1100;
        top: 35px !important;
        transform: translateX(-53%) !important;
        min-width: 58rem;
        height: 360px;
        padding: 15px;
        overflow: hidden;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
        display: none;

    }

    & .category {
        & h3 {
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 15px;
        }

        & .h_of_megamenu {
            overflow-y: scroll;
            height: 300px;
            padding: 20px 0;

            & ul {
                & li {
                    display: flex;
                    gap: 10px;
                    font-size: 17px;
                    font-weight: 400;
                    margin-top: 6px;
                    align-items: center;
                    cursor: pointer;
                    transition: all 4ms ease-in-out;
                    transition-duration: 3ms;                    
                    &:hover{
                        color: orange;
                        padding-left: 1.5px;
                    }

                    & svg {

                        color: orange;
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: #b7922354;
            }

            &::-webkit-scrollbar-thumb {
                background-color: orange;
            }
        }

        & .cards {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: start;
            padding: 5px;
            border-radius: 6px;
            /* box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05); */
            box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px 1px rgba(9, 30, 66, 0.13);

            & a {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: start;
            }

            & .pic {
                width: 100%;
                height: 50px;
                max-width: 55px;

                & img {
                    height: 100%;
                    width: 100%;
                    border-radius: 6px;
                }
            }

            & .date {
                font-size: 12px;
                font-weight: 400;
            }

            & .title {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
        }
    }

    & .top_blog {
        margin-top: 10px;


        & .desc {
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
        }

        & .title {
            font-size: 18px;
            margin: 10px 0;
        }

        & .pic img {

            width: 100%;
            height: 150px;
        }

        & .date {
            float: right;
            font-weight: 400;
            padding-right: 10px;
            font-size: 15px;
        }
    }

    & .add_banner {
        & .pic {
            margin-top: 15px;

            & img {
                width: 100%;
                height: 150px;
                border-radius: 6px;
            }
        }
    }
}

.h_of_megamenu {
    overflow-y: scroll;
    height: 300px;
    padding: 20px 0;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #b7922354;
    }

    &::-webkit-scrollbar-thumb {
        background-color: orange;
    }
}

.colleges-main-section {
    & .area_of_operation {
        height: 43px;

        & .title {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            &>*:nth-child(n) {
                display: flex;
                align-items: center;
                padding: 2px 5px 3px 5px;
                background-color: var(--main-bgcolor);
                border-radius: 5px;
                color: #fff;
                font-size: 12px;

            }
        }
    }

    & .adress {
        font-size: 14px;
        font-weight: 400;
    }
}

.footer-top {
    & .separation {
        position: relative;
        z-index: 5;
    }

    & .footer-bot-list {
        border-bottom: 0px !important;

        & a svg {
            background-color: #fff;
            padding: 3px;
            border-radius: 50%;
            height: 22px;
            width: 22px;
        }
    }

}

/* home page responsive code */
@media (max-width:992px) {
    .landing_section {

        .owl-carousel .owl-nav button.owl-prev {
            top: 93px;
            left: 82%;

        }

        & .landing_pic {
            width: 100%;
            height: 280px;
            position: relative;
        }

        .owl-carousel .owl-nav button.owl-next {
            top: 30px;
        }

        & .landing_content {
            top: 13%;
            left: 5%;
            padding: 10px;
            width: 50%;

            & h3,
            h2,
            h1 {
                font-size: 16px;
                margin-bottom: 8px;

            }

            & p {
                font-size: 12px;

            }

            & .landing_btn {
                & .btn {
                    padding: 2px 8px;
                    font-size: 12px;
                }
            }
        }



    }

    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel .owl-nav button.owl-prev {
        display: none;
    }

    .predict_your_college {

        height: 312px;

        & .pre_btn {
            & button {

                top: 210px;
                left: 57px;
                font-size: 12px;
                border-radius: 5px;

            }
        }

        .predictor-div {

            right: 20px;
            top: 30px;
            width: 78%;
            height: 240px;

        }

    }

    .admission {
        display: none;
    }

    /* .warpper{
        display: none;
    } */
    .glider {
        display: none;
    }

    .panel {
        padding: 0;

        & .second {
            & .mode {

                font-size: 9px;

            }

        }
    }

    .tabs-links-click {
        font-size: 8px;
    }

    .card_college {
        height: 400px;
    }

    .tabs p {
        font-size: 14px;
    }

    .content_of_card {
        & .address {
            & h3 {
                font-weight: 400;
                font-size: 14px;
                margin-top: 15px;
            }
        }
    }
}

@media (max-width:767px) {
    .landing_section {

        .owl-carousel .owl-nav button.owl-prev {
            top: 93px;
            left: 82%;

        }

        & .landing_pic {
            width: 100%;
            height: 200px;
            position: relative;
        }

        .owl-carousel .owl-nav button.owl-next {
            top: 30px;
        }

        & .landing_content {
            top: 13%;
            left: 5%;
            padding: 10px;
            width: 65%;

            & h3,
            h1,
            h2 {
                font-size: 12px;
                margin-bottom: 8px;

            }

            & p {
                font-size: 8px;

            }

            & .landing_btn {
                & .btn {
                    padding: 2px 8px;
                    font-size: 12px;
                }
            }
        }



    }

    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel .owl-nav button.owl-prev {
        display: none;
    }

    .predict_your_college {
        height: 200px;

        & .pre_btn {
            & button {

                top: 150px;
                left: 57px;
                font-size: 12px;
                border-radius: 5px;

            }
        }

        .predictor-div {

            right: 20px;
            top: -20px;
            width: 90%;
            height: 240px;

        }

    }

    .admission {
        display: none;
    }

    /* .warpper{
        display: none;
    } */
    .glider {
        display: none;
    }

    .panel {
        padding: 0;

        & .second {
            & .mode {

                font-size: 9px;

            }

        }
    }

    .tabs-links-click {
        font-size: 8px;
    }

    .card_college {
        height: 400px;
    }

    .tabs p {
        font-size: 14px;
    }

    .content_of_card {
        & .address {
            & h3 {
                font-weight: 400;
                font-size: 14px;
                margin-top: 15px;
            }
        }
    }

    .college_main_details {
        display: block;

        & p {
            font-size: 9px;
        }

        & .college_name {
            & .c_name {
                & .name {
                    font-size: 19px;
                    font-weight: 500;
                    text-align: start;
                }
            }
        }
    }

    .tabs_next_btn,
    .tabs_prev_btn {
        display: none;
    }

    .college_ratings {
        margin-top: 100px;
    }
}
.book-more-button:hover{
    background-color: orange;
}


/* tabs css  */

/* .tab-container {
    display: flex;
    align-items: center;
    position: relative;
}

.tab-container .p-tabview-nav {
    overflow-x: auto;
    flex: 1;
    white-space: nowrap;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}

.tab-container .p-tabview-nav::-webkit-scrollbar {
    display: none; 
}

.tab-container .p-tabview-nav .p-tabview-nav-link {
    position: relative;
    padding-bottom: 10px;
}

.tab-container .p-tabview-nav .p-tabview-nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff; 
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.tab-container .p-tabview-nav .p-tabview-nav-link.p-highlight::after {
    transform: scaleX(1);
}

.tabs_prev_btn, .tabs_next_btn {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.tabs_prev_btn {
    margin-right: 10px;
}

.tabs_next_btn {
    margin-left: 10px;
}

.tableheadingcolor {
    color: #007bff; 
} */

/* Additional styles if needed */
