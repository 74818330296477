@media only screen and (min-width: 992px) {
    .header.fixed-header #toggle_btn {
        align-items: center;
        color: #fff;
        display: inline-flex;
        float: left;
        font-size: 30px;
        height: 60px;
        margin-left: 15px;
        justify-content: center;
        padding: 0 20px;
    }

    .mini-sidebar .header-left .logo img {
        height: auto;
        max-height: 24px;
        width: auto;
    }

    .mini-sidebar .header .header-left .logo {
        display: none;
    }

    .mini-sidebar .header-left .logo.logo-small {
        display: block;
    }

    .mini-sidebar .header .header-left {
        padding: 0 5px;
        width: 90px;
    }

    .mini-sidebar .sidebar {
        width: 90px;
    }

    .mini-sidebar.expand-menu .sidebar {
        width: 280px;
    }

    .mini-sidebar .sidebar-menu {
        padding: 15px 19px;
    }

    .mini-sidebar .menu-title {
        visibility: hidden;
        white-space: nowrap;
    }

    .mini-sidebar.expand-menu .menu-title {
        visibility: visible;
    }

    .mini-sidebar .menu-title a {
        visibility: hidden;
    }

    .mini-sidebar.expand-menu .menu-title a {
        visibility: visible;
    }

    .modal-open.mini-sidebar .sidebar {
        z-index: 1051;
    }

    .mini-sidebar .sidebar .sidebar-menu ul>li>a span {
        display: none;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }

    .mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
        display: inline;
        opacity: 1;
    }

    .mini-sidebar .page-wrapper {
        margin-left: 90px;
    }

    .mini-sidebar.expand-menu .page-wrapper {
        margin-left: 280px;
    }

    .mini-sidebar .main-wrapper>.header {
        left: 120px;
    }

    .mini-sidebar .header.fixed-header {
        left: 90px;
    }

    .mini-sidebar .sidebar-menu>ul>li>a {
        border-radius: 10px;
        padding: 10px 16px;
    }

    .mini-sidebar.expand-menu .main-wrapper>.header {
        left: 310px;
    }

    .mini-sidebar.expand-menu .main-wrapper>.header.fixed-header {
        left: 280px;
    }
}

@media only screen and (max-width: 1400px) {

    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
        max-width: 180px;
    }
}

@media only screen and (max-width: 1199px) {

    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
        max-width: 150px;
    }

    .chat-window .chat-cont-left {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .chat-window .chat-cont-right {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

@media only screen and (max-width: 991.98px) {
    .header .header-left {
        position: absolute;
        width: 100%;
        display: block;
    }

    .mobile_btn {
        color: #333;
        cursor: pointer;
        display: flex;
        font-size: 26px;
        height: 60px;
        left: 0;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10;
        justify-content: center;
        align-items: center;
    }

    #toggle_btn {
        display: none;
    }

    .header .header-left .logo {
        display: none;
    }

    .header-left .logo.logo-small {
        display: block;
    }

    .header .header-left .logo img {
        max-height: 36px;
        height: 36px;
    }

    .main-wrapper>.header {
        left: 50px;
    }

    .main-wrapper>.header.fixed-header {
        left: 0;
    }

    .main-wrapper {
        padding: 20px;
    }

    .sidebar {
        margin-left: -225px;
        width: 225px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        z-index: 1041;
    }

    .page-wrapper {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .nav-tabs.menu-tabs .nav-item {
        padding-right: 1.25rem;
    }

    .chat-window .chat-scroll {
        max-height: calc(100vh - 255px);
    }

    .chat-window .chat-cont-left,
    .chat-window .chat-cont-right {
        flex: 0 0 100%;
        max-width: 100%;
        transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
        width: 100%;
    }

    .chat-window .chat-cont-left {
        border-right: 0;
    }

    .chat-window .chat-cont-right {
        position: absolute;
        right: calc(-100% + -1.875rem);
        top: 0;
    }

    .chat-window .chat-cont-right .chat-header {
        justify-content: start;
    }

    .chat-window .chat-cont-right .chat-header .back-user-list {
        display: block;
    }

    .chat-window .chat-cont-right .chat-header .chat-options {
        margin-left: auto;
    }

    .chat-window.chat-slide .chat-cont-left {
        left: calc(-100% + -1.875rem);
    }

    .chat-window.chat-slide .chat-cont-right {
        right: 0;
    }
}

@media only screen and (max-width: 767.98px) {
    body {
        font-size: 0.9375rem;
    }

  

    h2,
    .h2 {
        font-size: 1.75rem;
    }

    h3,
    .h3 {
        font-size: 1.5rem;
    }

    h4,
    .h4 {
        font-size: 1.125rem;
    }

    h5,
    .h5 {
        font-size: 1rem;
    }

    h6,
    .h6 {
        font-size: 0.875rem;
    }

    .header .has-arrow .dropdown-toggle:after {
        display: none;
    }

    .user-menu.nav>li>a>span:not(.user-img) {
        display: none;
    }

    .navbar-nav .open .dropdown-menu {
        float: left;
        position: absolute;
    }

    .navbar-nav.user-menu .open .dropdown-menu {
        left: auto;
        right: 0;
    }

    .header .header-left {
        padding: 0 15px;
    }

    .header .header-left .logo {
        display: none;
    }

    .header-left .logo.logo-small {
        display: inline-block;
    }

    .login-wrapper .loginbox {
        max-width: 450px;
        min-height: unset;
    }

    .error-box h1 {
        font-size: 6em;
    }

    .error-box .btn {
        font-size: 15px;
        min-width: 150px;
        padding: 8px 20px;
    }

    .dash-count {
        font-size: 16px;
    }

    .profile-btn {
        flex: 0 0 100%;
        margin-top: 20px;
    }

    .nav-tabs.menu-tabs {
        margin-top: -15px;
    }

    .nav-tabs.menu-tabs .nav-item {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 575.98px) {
    .main-wrapper>.header {
        right: 35px;
        top: 35px;
        left: 35px;
    }

    .card {
        margin-bottom: 0.9375rem;
    }

    .page-wrapper>.content {
        padding: 0.9375rem 0.9375rem 0;
    }

    .card-body {
        padding: 1.25rem;
    }

    .card-header {
        padding: 0.75rem 1.25rem;
    }

    .card-footer {
        padding: 0.75rem 1.25rem;
    }

    .page-header {
        margin-bottom: 0.9375rem;
    }

    .loginbox {
        padding: 1.25rem;
    }

    .profile-image {
        flex: 0 0 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .profile-user-info {
        text-align: center;
    }

    .profile-btn {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
    }

    .avatar-xxl .border {
        border-width: 4px !important;
    }

    .avatar-xxl .rounded {
        border-radius: 12px !important;
    }

    .avatar-xxl .avatar-title {
        font-size: 42px;
    }

    .avatar-xxl.avatar-away:before,
    .avatar-xxl.avatar-offline:before,
    .avatar-xxl.avatar-online:before {
        border-width: 4px;
    }
}

@media only screen and (max-width: 479px) {

    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
        max-width: 160px;
    }
}

@media (max-width:767px) {
    .faqquestion {
        white-space: break-spaces;
    }
}

@media only screen and (max-width:990px) {
    .modal-container {
        left: 0;
    }
}

@media screen and (min-width: 768px) {
    .scroll-container {
        max-width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .scroll-container {
        max-width: 300%;
    }
}

@media (max-width:767px) {
    .after-before::before {
        display: none;
    }

    .after-before::after {
        display: none;
    }

    .blog-sec .card h2 {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {

    .ab-1 {
        font-size: small;
    }

    .btnbroucher {
        font-size: smaller;
    }

    .btnapply {
        font-size: smaller;
    }

    .titlename {
        gap: 0px;
        font-size: 12px;
    }

    .titledesc {
        font-size: 10px;
        margin-left: 0px;
    }

    .titledesc p {
        font-size: 10px;
    }

    .titlecollege img {
        max-width: 50px;
        margin-right: 2px;
    }

    .titlecollege {
        top: 48%;
    }

    .img-section {
        padding-bottom: 18px;
    }
}



@media (max-width: 767px) {
    .sign-content h3 {
        font-size: 40px;
    }

    .wizard .nav-tabs>li a i {
        display: none;
    }

    .signup-logo-header .navbar-toggle {
        margin: 0;
        margin-top: 8px;
    }

    .signup-logo-header .logo_area {
        margin-top: 0;
    }

    .signup-logo-header .header-flex {
        display: block;
    }
}

@media (max-width: 768px) {
    .header ul {
        flex-direction: column;
        align-items: flex-start;
    }

    .header .hidden-md {
        display: block;
    }

    .header .sub-menu {
        position: static;
        display: block;
        background-color: transparent;
        box-shadow: none;
    }

    .header .sub-menu a {
        color: #333;
        padding: 4px 0;
    }
}

@media only screen and (max-width:992px) {
    .nav-landing {
        height: 340px;
    }

    .nav-landing:before {
        height: 340px;
        background-color: #0000009c;
        z-index: 1;
    }

    .nav-contain {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translate(0%, -50%);
    }
}

@media only screen and (max-width:440px) {
    .nav-landing {
        height: 250px;
    }

    .nav-landing:before {
        height: 250px;
        background-color: #0000009c;
        z-index: 1;
    }

    .nav-contain {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translate(0%, -50%);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    body {
        overflow-x: hidden;
    }

    div.ruby-menu-demo-description {
        position: relative;
        width: 760px;
        height: 160px;
        margin: 0 auto;
        padding: 0;
    }

    div.ruby-menu-demo-description img {
        max-width: 200px;
        float: left;
        margin: 50px 20px 0 0;
    }

    div.ruby-menu-demo-bg-light,
    div.ruby-menu-demo-bg-dark {
        width: 760px;
        font-size: 14px;
    }

    div.ruby-menu-demo-cs-1 {
        width: 27%;
    }

    div.ruby-menu-demo-cs-2 {
        width: 73%;
    }

    td.ruby-menu-demo-theme-td-1 {
        max-width: 27px;
        padding: 7px;
    }

    td.ruby-menu-demo-theme-td-2 {
        min-width: 100px;
        padding: 7px;
    }

    .ruby-menu-demo-circle {
        width: 22px;
        height: 22px;
        border: 3px solid #222;
    }

    img.iphone7 {
        max-width: 320px;
        float: right;
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    div.ruby-menu-demo-header {
        width: 100%;
        height: auto;
    }

    div.ruby-menu-demo-description {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 220px;
        padding: 10px;
        text-align: center;
    }

    div.ruby-menu-demo-description img {
        float: none;
        margin: 90px 0 0;
    }

    div.ruby-menu-demo-download {
        position: relative;
        width: auto;
        top: 25px;
        right: auto;
        left: auto;
    }

    div.ruby-menu-demo-download>span {
        font-size: 20px;
        margin: 25px auto;
    }

    div.ruby-menu-demo-download>span>span {
        font-size: 26px;
    }

    button.ruby-btn {
        padding: 14px 25px;
        font-size: 16px;
        text-transform: uppercase;
    }

    div.ruby-menu-demo-bg-light.ruby-menu-demo-switches {
        display: none;
    }

    div.ruby-menu-demo-bg-light,
    div.ruby-menu-demo-bg-dark {
        width: 100%;
        font-size: 16px;
        padding: 25px;
        margin-bottom: -100px;
    }

    div.ruby-menu-demo-cv-1 {
        width: 100%;
    }

    div.ruby-menu-demo-cv-2 {
        width: 100%;
    }

    div.ruby-menu-demo-cv-3 {
        width: 100%;
    }

    div.ruby-menu-demo-cv-4 {
        width: 100%;
    }

    div.ruby-menu-demo-cr-1 {
        width: 100%;
    }

    div.ruby-menu-demo-cr-2 {
        width: 100%;
        margin-top: 40px;
    }

    div.ruby-menu-demo-cf-1,
    div.ruby-menu-demo-cf-2,
    div.ruby-menu-demo-cf-3,
    div.ruby-menu-demo-cf-4 {
        float: left;
        width: 50%;
        text-align: center;
    }

    div.ruby-menu-demo-f-desc {
        margin-bottom: 30px;
    }

    img.iphone7 {
        max-width: 300px;
        float: none;
        margin-right: 0 auto;
    }
}

@media (min-width: 768px) {

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after {
        background-color: #222;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover {
        color: #333;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>Link:after,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>Link:after {
        border-color: transparent #fff transparent transparent;
    }
}

@media (max-width: 767px) {
    ul.ruby-menu>li>a {
        background: #efefef;
    }

    ul.ruby-menu>li>a:hover,
    ul.ruby-menu>li:hover>a,
    ul.ruby-menu>li.ruby-active-menu-item>a:hover {
        background: #ccc;
        color: #111;
    }

    ul.ruby-menu>li.ruby-active-menu-item>a {
        background: #ccc;
    }

    ul.ruby-menu>li>ul>li>a,
    ul.ruby-menu>li>ul>li>ul>li>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        background: #d1d1d1;
    }

    ul.ruby-menu>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>a:hover,
    ul.ruby-menu>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>a:hover,
    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #d9d9d9;
        color: #111;
    }

    ul.ruby-menu>li>ul>li>ul>li>a {
        background: #e5e5e5;
    }

    ul.ruby-menu>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>a:hover {
        background: #e1e1e1;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
        background: #efefef;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #fff;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a {
        background: #bcbcbc;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>a {
        background: #d9d9d9;
    }

    /* ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a {
      background: #bcbcbc;
    } */

    /* ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a {
      background: #d9d9d9;
    } */

    span.ruby-dropdown-toggle:after {
        color: #fff;
    }

    ul.ruby-menu>li>a:before,
    ul.ruby-menu>li>ul>li>a:before,
    ul.ruby-menu>li>ul>li>ul>li>a:before,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        background: #333;
    }

    ul.ruby-menu>li>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        background: #555;
    }

    ul.ruby-menu>li>ul>li>ul>li>a:before {
        background: #777;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before {
        background: #999;
    }

    .c-hamburger span {
        background: #222;
    }

    .c-hamburger span::before,
    .c-hamburger span::after {
        background-color: #222;
    }

    .c-hamburger--htx {
        background-color: #efefef;
        color: #222;
    }

    .c-hamburger--htx.is-active {
        background-color: #ddd;
    }

    ul.ruby-menu>li.ruby-menu-mega>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        border-top: none;
    }

    ul.ruby-menu>li>ul,
    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul {
        border-top: none;
    }

    ul.ruby-menu.ruby-menu-dividers>li,
    ul.ruby-menu.ruby-menu-dividers>li>a,
    ul.ruby-menu.ruby-menu-dividers>li.ruby-menu-social>a,
    ul.ruby-menu.ruby-menu-dividers>li>a:hover,
    ul.ruby-menu.ruby-menu-dividers>li:hover>a {
        border: none;
    }
}

@media (min-width: 767px) {
    ul.ruby-menu>li>a:after {
        transition: all 0.1s ease;
    }

    ul.ruby-menu>li>ul>li>a:after,
    ul.ruby-menu>li>ul>li>ul>li>a:after,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:after {
        transition: all 0.1s ease;
    }

    ul.ruby-menu>li>ul,
    ul.ruby-menu>li>div {
        transform: translateY(25px);
        transition: all 0.1s ease;
    }

    ul.ruby-menu>li:hover>ul,
    ul.ruby-menu>li:hover>div {
        transform: translateY(0);
    }

    ul.ruby-menu.ruby-vertical>li>ul,
    ul.ruby-menu.ruby-vertical>li>div {
        transform: translateX(25px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu.ruby-vertical>li:hover>ul,
    ul.ruby-menu.ruby-vertical>li:hover>div {
        transform: translateX(0);
    }

    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>ul {
        transform: translateX(25px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu>li>ul>li:hover>ul,
    ul.ruby-menu>li>ul>li>ul>li:hover>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>ul {
        transform: translateX(0);
    }

    ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,
    ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left>ul {
        transform: translateX(-25px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu>li>ul>li.ruby-open-to-left:hover>ul,
    ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left:hover>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left:hover>ul {
        transform: translateX(0);
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        transform: translateY(-50px);
        transition: all 0.5s ease;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        transform: translateY(0);
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        transform: translateX(50px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div,
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        transform: translateX(0);
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        transform: translateY(-50px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        transform: translateY(0);
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop>div>ul>li>div {
        transform: translateX(50px);
        transition: all 0.3s ease;
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        transform: translateX(0);
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after {
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
}

@media (min-width: 768px) {
    div.ruby-wrapper.ruby-menu-transparent {
        background: none;
    }

    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-active-menu-item>a {
        background: none;
    }

    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social>a {
        border: none;
    }

    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li>a {
        border: none;
    }

    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li:hover>a,
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li>a:hover,
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li:hover>a,
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu.ruby-menu-dividers>li>a:hover {
        background: none;
        color: #e2e2e2;
    }

    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social.ruby-menu-search>a:hover,
    div.ruby-wrapper.ruby-menu-transparent>ul.ruby-menu>li.ruby-menu-social.ruby-menu-search:hover>a {
        background: none;
    }

    div.ruby-wrapper.ruby-menu-full-width {
        width: 100%;
    }

    div.ruby-wrapper.ruby-menu-full-width>ul.ruby-menu {
        width: 980px;
        margin: 0 auto;
    }

    ul.ruby-menu>li>ul,
    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul {
        visibility: hidden;
        opacity: 0;
    }

    ul.ruby-menu>li:hover>ul,
    ul.ruby-menu>li>ul>li:hover>ul,
    ul.ruby-menu>li>ul>li>ul>li:hover>ul {
        visibility: visible;
        opacity: 1;
    }

    ul.ruby-menu>li.ruby-menu-mega>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        visibility: hidden;
        opacity: 0;
    }

    ul.ruby-menu>li.ruby-menu-mega:hover>div,
    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div,
    ul.ruby-menu>li.ruby-menu-mega-shop:hover>div {
        visibility: visible;
        opacity: 1;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        visibility: hidden;
        opacity: 0;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog:hover>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>div {
        visibility: visible;
        opacity: 1;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>div {
        visibility: visible;
        opacity: 1;
        z-index: 101;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        visibility: hidden;
        opacity: 0;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop:hover>div>ul>li.ruby-active-menu-item>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>div {
        visibility: visible;
        opacity: 1;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after {
        height: 1px;
        position: absolute;
        content: "";
        background-color: #19ad86;
        width: 0;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        top: 10px;
        left: 5%;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:after {
        bottom: 10px;
        right: 5%;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover,
    .snip1217 .current a {
        color: #333;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover:after,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a:after,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a:after {
        width: 90%;
    }

    ul.ruby-menu>li>a:after {

        text-decoration: none;
        position: absolute;
        /* content: "\f138"; */
        margin-left: 5px;
    }

    ul.ruby-menu>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    ul.ruby-menu>li>ul>li>a:after,
    ul.ruby-menu>li>ul>li>ul>li>a:after,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:after {

        text-decoration: none;
        font-size: 16px;
        padding: 0;
        content: "\f105";
        position: absolute;
        right: 15px;
    }

    ul.ruby-menu>li>ul>li:hover>a:after,
    ul.ruby-menu>li>ul>li>ul>li:hover>a:after,
    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    ul.ruby-menu>li>ul>li.ruby-open-to-left>a:after,
    ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>a:after,
    ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left>a:after {
        content: "\f104";
        right: auto;
        left: 10px;
    }

    ul.ruby-menu>li>ul>li.ruby-open-to-left:hover>a:after,
    ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left:hover>a:after,
    ul.ruby-menu>li>ul>li>ul>li>ul>li.ruby-open-to-left:hover>a:after {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a:after,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>a:after {
        content: "";
        position: absolute;
        right: 0;
        margin-top: 13px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 8px 6px 0;
        border-color: transparent #fff transparent transparent;
    }
}

/* responsive */

@media (max-width: 767px) {
    div.ruby-wrapper .hidden-xs {
        visibility: hidden;
        display: none;
    }

    div.ruby-wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        background: #111;
    }

    .ruby-wrapper .visible-xs {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }

    div.ruby-wrapper>ul {
        position: absolute;
        top: 50px;
        width: 100%;
    }

    ul.ruby-menu>li,
    ul.ruby-menu>li.ruby-menu-right {
        float: none;
    }

    ul.ruby-menu>li>a {
        padding: 0 65px;
        background: #19ad86;
    }

    ul.ruby-menu>li>a:only-child {
        padding: 0 65px;
    }

    ul.ruby-menu>li>a:hover,
    ul.ruby-menu>li:hover>a,
    ul.ruby-menu>li.ruby-active-menu-item>a:hover {
        background: #127e61;
    }

    ul.ruby-menu>li.ruby-active-menu-item>a {
        background: #159572;
    }

    ul.ruby-menu>li.ruby-menu-social>a>span {
        display: block;
    }

    ul.ruby-menu>li>ul,
    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul {
        position: relative;
        width: 100%;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-top: none;
    }

    ul.ruby-menu>li,
    ul.ruby-menu>li>ul>li,
    ul.ruby-menu>li>ul>li>ul>li,
    ul.ruby-menu>li>ul>li>ul>li>ul>li {
        position: relative;
    }

    ul.ruby-menu>li>ul>li>a,
    ul.ruby-menu>li>ul>li>ul>li>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
        width: 100%;
        line-height: 50px;
        padding: 0 80px;
        background: #d1d1d1;
    }

    ul.ruby-menu>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>a:hover,
    ul.ruby-menu>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>a:hover,
    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #d9d9d9;
        color: #111;
    }

    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul>li>ul {
        left: auto;
        top: auto;
        z-index: auto;
    }

    ul.ruby-menu>li>ul>li.ruby-open-to-left>ul,
    ul.ruby-menu>li>ul>li>ul>li.ruby-open-to-left>ul {
        left: auto;
        right: auto;
    }

    ul.ruby-menu>li>ul>li>ul>li>a {
        background: #e5e5e5;
        padding: 0 95px;
    }

    ul.ruby-menu>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>a:hover {
        background: #e1e1e1;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li>a {
        background: #efefef;
        padding: 0 110px;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li:hover>a,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:hover {
        background: #fff;
    }

    ul.ruby-menu>li.ruby-menu-social>a {
        border: none;
    }

    ul.ruby-menu>li.ruby-menu-mega,
    ul.ruby-menu>li.ruby-menu-mega-blog,
    ul.ruby-menu>li.ruby-menu-mega-shop {
        position: relative;
    }

    ul.ruby-menu>li.ruby-menu-mega>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        position: relative;
        top: auto;
        left: auto;
        border-top: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav {
        width: 100%;
        height: auto;
        left: auto;
        top: auto;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li {
        position: relative;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a {
        line-height: 50px;
        padding: 0 75px;
        background: #d1d1d1;
        color: #222;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li.ruby-active-menu-item>a {
        background: #bcbcbc;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        position: relative;
        width: 100%;
        min-height: auto;
        left: auto;
        top: auto;
        z-index: auto;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li:hover>a {
        background: #d9d9d9;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        position: relative;
        text-align: left;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul {
        position: relative;
        border: none;
        font-size: 14px;
        margin-bottom: -1px;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li {
        position: relative;
        display: block;
        margin-top: -1px;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        padding: 0 80px;
        line-height: 50px;
        max-height: 50px;
        text-transform: none;
        font-weight: 400;
        background: #d1d1d1;
        color: #222;
        border: none;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a {
        background: #bcbcbc;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li:hover>a,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:hover,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li.ruby-active-menu-item>a {
        background: #d9d9d9;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        display: block;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div {
        position: relative;
        top: 0;
        z-index: 9999;
        margin: 0;
    }

    ul.ruby-menu>li.ruby-menu-social img {
        display: inline-table;
        position: absolute;
        top: 15px;
    }

    ul.ruby-menu>li.ruby-menu-social>a {
        width: 100%;
    }

    ul.ruby-menu>li.ruby-menu-social .fa {
        position: absolute;
        top: 17px;
    }

    ul.ruby-menu>li.ruby-menu-social span {
        line-height: 50px;
        padding: 0 25px;
    }

    span.ruby-dropdown-toggle {
        position: absolute;
        width: 50px;
        height: 50px;
        max-width: 50px;
        max-height: 50px;
        left: 0;
        top: 0;
        padding: 10px 17px;
        z-index: 1000000;
        cursor: pointer;
    }

    span.ruby-dropdown-toggle:after {

        text-decoration: none;
        content: "\f107";
        color: #fff;
        font-size: 25px;
        right: 0;
    }

    span.ruby-dropdown-toggle.ruby-dropdown-toggle-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    ul.ruby-menu>li>a:before,
    ul.ruby-menu>li>ul>li>a:before,
    ul.ruby-menu>li>ul>li>ul>li>a:before,
    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        content: "";
        width: 50px;
        height: 50px;
        background: #333;
        position: absolute;
        left: 0;
        top: 0;
    }

    ul.ruby-menu>li>ul>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>a:before,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a:before {
        background: #555;
    }

    ul.ruby-menu>li>ul>li>ul>li>a:before {
        background: #777;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul>li>a:before {
        background: #999;
    }

    ul.ruby-menu>li>ul>li>ul>li>ul,
    ul.ruby-menu>li>ul>li>ul,
    ul.ruby-menu>li>ul,
    ul.ruby-menu,
    ul.ruby-menu>li.ruby-menu-mega>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div,
    ul.ruby-menu>li.ruby-menu-mega-blog>div,
    ul.ruby-menu>li.ruby-menu-mega-shop>div {
        max-height: 0;
        transform: scaleY(0);
        overflow: hidden;
    }

    ul.ruby-menu>li>ul.ruby-mobile-sublevel-show,
    ul.ruby-menu>li>ul>li>ul.ruby-mobile-sublevel-show,
    ul.ruby-menu>li>ul>li>ul>li>ul.ruby-mobile-sublevel-show,
    ul.ruby-menu.ruby-mobile-sublevel-show,
    ul.ruby-menu>li.ruby-menu-mega>div.ruby-mobile-sublevel-show,
    ul.ruby-menu>li.ruby-menu-mega-blog>div.ruby-mobile-sublevel-show,
    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div.ruby-mobile-sublevel-show,
    ul.ruby-menu>li.ruby-menu-mega-shop>div.ruby-mobile-sublevel-show,
    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>div.ruby-mobile-sublevel-show {
        max-height: 20000px;
        transform: scaleY(1);
    }

    div.ruby-grid>div.ruby-row div.ruby-row [class^="ruby-col"] {
        margin-top: 0;
    }

    div.ruby-grid>div.ruby-row div.ruby-row:first-of-type [class^="ruby-col"] {
        margin-top: 0;
    }

    div.ruby-grid.ruby-grid-lined>div.ruby-row:after {
        height: 0;
    }

    div.ruby-grid>div.ruby-row>[class^="ruby-col"] {
        padding: 0;
    }

    div.ruby-grid>div.ruby-row>[class^="ruby-col"] [class^="ruby-col"] {
        padding-left: 0;
    }

    div.ruby-grid>div.ruby-row>[class^="ruby-col"] [class^="ruby-col"]:first-of-type {
        padding-left: 0;
    }

    div.ruby-grid>div.ruby-row div.ruby-col-1,
    div.ruby-grid>div.ruby-row div.ruby-col-2,
    div.ruby-grid>div.ruby-row div.ruby-col-3,
    div.ruby-grid>div.ruby-row div.ruby-col-4,
    div.ruby-grid>div.ruby-row div.ruby-col-5,
    div.ruby-grid>div.ruby-row div.ruby-col-6,
    div.ruby-grid>div.ruby-row div.ruby-col-7,
    div.ruby-grid>div.ruby-row div.ruby-col-8,
    div.ruby-grid>div.ruby-row div.ruby-col-9,
    div.ruby-grid>div.ruby-row div.ruby-col-10,
    div.ruby-grid>div.ruby-row div.ruby-col-11,
    div.ruby-grid>div.ruby-row div.ruby-col-12 {
        width: 100%;
        padding: 20px 30px;
    }

    div.ruby-grid.ruby-grid-lined>div.ruby-row>div[class^="ruby-col"]:after {
        width: 0;
    }

    div.ruby-grid img {
        position: relative;
        max-width: 100%;
        height: auto;
        display: block;
    }

    div.ruby-grid>div.ruby-row::after {
        content: "";
        display: table;
        clear: both;
    }

    .c-hamburger {
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        font-size: 0;
        text-indent: -9999px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: none;
        border: none;
        cursor: pointer;
        -webkit-transition: background 0.3s;
        transition: background 0.3s;
    }

    .c-hamburger:focus {
        outline: none;
    }

    .c-hamburger span {
        display: block;
        position: absolute;
        top: 24px;
        left: 8px;
        right: 8px;
        height: 3px;
        background: #fff;
    }

    .c-hamburger span::before,
    .c-hamburger span::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff;
        content: "";
    }

    .c-hamburger span::before {
        top: -10px;
    }

    .c-hamburger span::after {
        bottom: -10px;
    }

    .c-hamburger--htx {
        background-color: #19ad86;
    }

    .c-hamburger--htx span {
        -webkit-transition: background 0 0.3s;
        transition: background 0 0.3s;
    }

    .c-hamburger--htx span::before,
    .c-hamburger--htx span::after {
        -webkit-transition-duration: 0.3s, 0.3s;
        transition-duration: 0.3s, 0.3s;
        -webkit-transition-delay: 0.3s, 0;
        transition-delay: 0.3s, 0;
    }

    .c-hamburger--htx span::before {
        -webkit-transition-property: top, -webkit-transform;
        transition-property: top, transform;
    }

    .c-hamburger--htx span::after {
        -webkit-transition-property: bottom, -webkit-transform;
        transition-property: bottom, transform;
    }

    .c-hamburger--htx.is-active {
        background-color: #333;
    }

    .c-hamburger--htx.is-active span {
        background: none;
    }

    .c-hamburger--htx.is-active span::before {
        top: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .c-hamburger--htx.is-active span::after {
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .c-hamburger--htx.is-active span::before,
    .c-hamburger--htx.is-active span::after {
        -webkit-transition-delay: 0s, 0.3s;
        transition-delay: 0s, 0.3s;
    }

    ul.ruby-menu.ruby-menu-dividers>li,
    ul.ruby-menu.ruby-menu-dividers>li>a,
    ul.ruby-menu.ruby-menu-dividers>li.ruby-menu-social>a,
    ul.ruby-menu.ruby-menu-dividers>li>a:hover,
    ul.ruby-menu.ruby-menu-dividers>li:hover>a {
        border: none;
    }


    ul.ruby-menu>li.ruby-menu-social.ruby-menu-search>a {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .ruby-wrapper .hidden-md {
        visibility: hidden;
        display: none;
    }



    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav {
        width: 170px;
    }

    ul.ruby-menu>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        width: 590px;
        left: 170px;
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega>div,
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div,
    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-shop>div {
        width: 560px;
    }

    ul.ruby-menu.ruby-vertical>li.ruby-menu-mega-blog>div>ul.ruby-menu-mega-blog-nav>li>div {
        width: 360px;
    }

    span.ruby-c-date {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    div.ruby-grid>div.ruby-row div.ruby-col-3-md {
        width: 25%;
    }

    div.ruby-grid>div.ruby-row div.ruby-col-4-md {
        width: 33.33%;
    }

    div.ruby-grid>div.ruby-row div.ruby-col-6-md {
        width: 50%;
    }

    div.ruby-grid>div.ruby-row div.ruby-col-12-md {
        width: 100%;
    }

    div.ruby-grid.ruby-grid-lined>div.ruby-row:after,
    div.ruby-grid.ruby-grid-lined>div.ruby-row>div[class^="ruby-col"]:after {
        background: none;
    }

    ul.ruby-menu>li.ruby-menu-mega-shop>div>ul>li>a {
        padding: 20px 5px;
        font-size: 12px;
        font-stretch: condensed;
        color: #666;
    }
}

@media (max-width: 767px) {
    .best-career-section .best-img {
        display: none;
    }

    .college-predictor .college-predictor-data h1 {
        font-size: 24px;
        font-weight: 700;
        margin: 12px 0px;
    }

    .best-career-section .best-career-heading h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
        color: #052963;
    }

    .best-career-section .best-img1 {
        display: none;
    }

    .best-career-section .best-career-btn a {

        padding: 8px 20px;
        letter-spacing: 1.2px;
        color: whitesmoke;
        font-size: 16px;
        font-weight: 500;
    }
}

@media (max-width:992px) {

    .static-section .static::before {
        position: absolute;
        content: "";
        background-color: rgba(31, 44, 117, .7);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    .static-section .static-text {
        position: relative;
    }

    .static-section .static .static-head {
        color: white;
    }
}

@media (max-width:767px) {

    .static-section .static .static-head h1 {
        font-size: 29px;
        color: #000;
        font-weight: 700;
    }

    .static-section .static .static-head h1 span {
        font-size: 29px;
        color: #e48aae;
        font-weight: 700;
    }
}

@media (max-width:767px) {

    .admission .procedure img {
        max-width: 40px;

    }

    .main-step {

        gap: 4px;
    }

    .admission .procedure p {
        font-size: 10px;
        margin: 0;
    }

    .admission .main-text h1 {
        font-weight: 700;
        font-size: 29px;
        padding-bottom: 15px;
    }
}

@media (max-width:767px) {
    .contact-us-section .small_heading h6 {
        font-size: 14px;
    }

}

@media (max-width: 767px) {

    .contact-us-section .contact-img img {
        display: none;

    }

    .contact-us-section .left_line,
    .right_line {
        width: 54px;
    }
}

@media (max-width: 992px) {

    .contact-us-section .contact-img img {
        display: none;
    }
}

/* Add media query for small screens */
@media (max-width:992px) {
    .containe {
        margin-left: 0;
        padding: 8px;
    }
}

@media (max-width: 768px) {
    .containe .sidebarbar {
        position: fixed;
        z-index: 1000;
        height: 100%;
        top: 0;
        left: 0;
        overflow-x: hidden;
        transition: width 0.3s;
    }

    .containe .open {
        width: 100%;
    }

    .containe {
        margin-left: 46px;
        padding: 8px;
    }

    .college_profile .card-body {
        display: block !important;
    }

    .college_profile .card .second {
        width: 100% !important;
        margin-top: 10px;
    }

    .college_profile .card .college_pic .college_log {
        top: 76% !important;
    }

    .college_profile .card .college_pic .college_log img {
        height: 70px !important;
        width: 70px !important;
    }

    .college_profile .card .college_pic img {

        height: 185px !important;

    }

    .containe main {

        padding: 0px;
        margin-top: 5px;
        transition: width 0.3s;
        width: 100%;
        margin-left: 35px;
    }
}

@media (max-width:767px) {
    .profile_of_student .hit {
        height: auto;
    }

    .profile_of_student .second {
        margin-top: 10px;
    }

    .profile_of_student .card {
        margin-top: 10px;
    }

}

@media (max-width:992px) {
    .profile_of_student .hit {
        height: auto;
    }

    .profile_of_student .second {
        margin-top: 10px;
    }

    .profile_of_student .card {
        margin-top: 10px;
    }

}

@media (max-width:767px) {
    .tes .tes-card {
        border-radius: 0;
        height: auto;

    }
}

@media (max-width:992px) {
    .tes {
        margin-left: -64px;
    }
}

@media (max-width: 992px) {
    .director .director_card #name {
        font-size: 17px;
    }

    .director .director_card {
        padding: 0;
    }

    .director .director_card .edit,
    .director .director_card .delete {
        font-size: 12px;
    }

    .director .director_card .buttons {
        right: 13px;
    }

    .director .director_card .edit {
        margin-right: 4px !important;
    }

    .director .director_card .inner_card {
        padding: 10px;
    }

    .director .director_card #email {
        display: block;
    }

    .director .director_card .card-body {
        padding: 0;
    }

    .director .director_card #contact h1 {

        font-size: 12px;
    }

    .director .director_card #email h1 {

        font-size: 12px;
    }

}

@media (max-width: 767px) {
    .director .director_card #name {
        font-size: 17px;
    }

    .director .director_card {
        padding: 0;
    }

    .director .director_card .edit,
    .director .director_card .delete {
        font-size: 12px;
    }

    .director .director_card .buttons {
        right: 13px;
    }

    .director .director_card .edit {
        margin-right: 4px !important;
    }

    .director .director_card .inner_card {
        padding: 10px;
    }

    .director .director_card #email {
        display: block;
    }

    .director .director_card .card-body {
        padding: 0;
    }

    .director .director_card #contact h1 {

        font-size: 12px;
    }

    .director .director_card #email h1 {

        font-size: 12px;
    }

}

@media (max-width:767px) {
    .usericon svg {

        margin-right: -10px;
    }

    .first_dropdown .usericon svg {
        font-size: 23px;
    }

    .second_dropdown .usericon svg {
        font-size: 18px;
    }
}

@media (max-width:767px) {
    .outerbar {
        display: none;
    }

    .innerbar {
        display: block;
    }

    .top_section .logo {
        display: none;
    }

    .first_dropdown .dropdown-menu {
        width: 250px;
        left: -196px;
    }

    .leading-5 {
        font-size: 15px;
    }

    .common_dash {
        padding-left: 23px !important;
    }

    .common_dash_test {
        padding-left: 75px !important;
    }

    .first h2 {
        font-size: 14px;
    }

    .college_profile .social_links {
        font-size: 18px;
    }

    .common_wrap {
        white-space: nowrap;
    }

    .s_column {
        padding-left: 0;
    }

    .elig-btn {
        top: 84px;
    }

    .email_break {
        overflow-wrap: break-word;
    }
}

@media (max-width:767px) {
    .profile_of_student {
        & .name h3 {
            font-size: 14px;

        }

        & .hback {
            font-size: 15px;
        }
    }

    .nav-pills .nav-link {
        font-size: 16px;
    }

    .width_define {
        overflow-wrap: normal;
    }
    .popular_blog {
        & .main_side_card {
            & .popular_col {
                .pic {
                    width: 50%;
                }
            }
        }
    }
    .blog-details-sec {
        & .card-b {
            &::before{
                display: none;
            }
        }
    }
}
