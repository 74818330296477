@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  display: none !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1184px;
  }
}
@media (min-width: 1350px) {
  .container {
    max-width: 1300px;
  }
}

.check_box_category {
  height: auto;
  overflow: auto;
  max-height: 200px; 
}

#progressbar {
  height: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  background: #052963;
  border-radius: 30px;
  margin: -20px 0 0 -100px;
  padding: 2px;
}

#loading {
  transition: all 500ms ease;
  height: 22px;
  width: calc(100% - 10px);
  border-radius: 10px;
  background: linear-gradient(to right, white, skyblue, whitesmoke);
  position: absolute;
  padding: 2px;
  display: inline-block;
  animation: loadd 5s ease infinite;
}

#load {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-top: 30px;
  letter-spacing: 1px;
}

@keyframes loadd {
  0% {
    width: 2%;
  }

  100% {
    width: 95%;
  }
}

.lodindingcontner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f7f4f4;
  min-height: 100vh;
  padding: 30px;
}

.loadingcards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.loadingcard {
  margin: 10px;
  width: 300px;
  background: rgb(224, 213, 213);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.loadingcard .image {
  img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.loadingcard .content {
  padding: 20px 28px;
}

.loadingcard.is-loading .image,
.loadingcard.is-loading h2,
.loadingcard.is-loading p {
  background: rgb(223, 220, 220);
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.loadingcard.is-loading .image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.loadingcard.is-loading h2 {
  height: 30px;
}

.loadingcard.is-loading p {
  height: 40px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .loadingcard {
    width: 100%;
    /* Make cards full-width on smaller screens */
  }
}


/* .navbar{
    height: 50px;
    background-color:rgb(35, 35, 35);
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-around;
} */
.list {
  display: flex;
  align-items: center;
  list-style: none;

}

.listitem {
  justify-content: space-between;
  cursor: pointer;
  gap: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.logo {
  font-size: 20;
}

.avtar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  background-color: darkgrey;
  justify-content: center;
}

.logintitle {
  position: absolute;
  top: 250px;
  color: blueviolet;
}

.wrapper {
  width: 50%;
  height: 55%;
  box-shadow: 1px 1px 2px 2px, 5px 4px 3px 4px whitesmoke;
  display: flex;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}

.left,
.right {
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;

}

.right input {
  width: 200px;
  padding: 10px 15px;
}

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or {

  justify-content: center;
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 2px 2px 2px 2px;
  background-color: white;

}

.or:hover {

  background-color: blue;

}

.line {
  width: 1px;
  height: fit-content;
}

.form {
  flex-direction: column;
  display: flex;
  row-gap: 10px;
}

.google {
  width: 150px;
  padding: 12px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: red;
}

.facebook {
  width: 150px;
  padding: 12px 20px;
  border-radius: 10px;
  background-color: blue;

}

.sgininbtn {
  right: 0%;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  color: white;
  background-color: blueviolet;
}

.sgininbtn:hover {
  background-color: blue;
}

.tooltip-inner {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}


.feedback-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.feedback-popup .popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.feedback-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.feedback-popup .emoji-rating {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.feedback-popup .emoji-container {
  position: relative;
  cursor: pointer;
}

.feedback-popup .emoji {
  font-size: 2rem;
}

.feedback-popup .emoji:hover {
  transform: scale(1.1); 
  transition: transform 0.2s;
}

.feedback-popup .tick {
  position: absolute;
  bottom: -22px;
  right: 0px;
  color: green;
  font-size: 1.5rem;
}

.feedback-popup textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}

.feedback-popup button[type="submit"] {
  padding: 10px 20px;
  background-color: #052963;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


/* this user feedback form css   */
.feedback-form {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 600px;
  margin: auto;
}

.feedback-form .feedback-header,
.feedback-footer {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

}

.feedback-form .feedback-header h2 {
  margin: 0;
  color: #052963;
  display: flex;
  justify-content: center;
}
.feedback-form .feedback-header button {
  margin: 0;
  color: orangered;
  justify-content: end;
  font-size: 24px;
  font-weight: 600;

}

.feedback-form .feedback-footer p {
  margin: 0;
  padding-top: 5%;
  color: #555;
  text-justify: distribute;
  font-style: italic;
  text-align: end;
}

.star-rating {
  display: flex;
  justify-content: start;
  gap: 5px;
  cursor: pointer;
}

.star {
  font-size: 24px;
  color: #ccc;
  transition: color 0.2s;
}

.star.selected {
  color: gold;
}

.star:hover {
  color: gold;
}

.star:hover ~ .star {
  color: #ccc;
}

.feedback-form .btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.feedback-form .btn {
  color: var(--white);
  background-color: #052963;
}

.feedback-form .btn:hover {
  background-color: orange;
}

.feedback-form .form-group {
  margin-bottom: 15px;
  background:whitesmoke;
}

.feedback-form .form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.feedback-form .form-control:hover {
  background: whitesmoke;
}
.feedback-form .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* new loading book css  */
:root {
  --color-primary_loading: #052963ad;
  --white: #ffffff;
}

.loading_page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f0f0;
}

.loading_page .container {
  position: absolute;
  width: 185px;
  text-align: center;
}

@keyframes pageTurn {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.loading_page .book-section {
  border: 2.8px solid var(--color-primary_loading);
  border-radius: 0.25rem;
  padding: 2rem;
  perspective: 37.5rem;
  position: relative;
  width: 80px;
  height: 100px;
  display: flex;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.loading_page .book-section .page {
  position: absolute;
  width: 75px;
  height: 95px;
  left: 0.1rem;
  top: 0.02rem;
  border: 2.8px solid var(--color-primary_loading);
  background-color: var(--white);
  background-image: repeating-linear-gradient(var(--color-primary_loading) 0 0.125rem,
      hsla(223, 10%, 10%, 0) 0.125rem 0.5rem);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5rem 4.125rem, 100% 100%;
  transform-origin: 100% 50%;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
}

.loading_page .book-section .backPage {
  left: 50%;
  border-left-width: 1px;
}

.loading_page .book-section .pageFlip:nth-of-type(2) {
  position: absolute;
  z-index: 3;
  animation: pageTurn 1s cubic-bezier(0, 0.39, 1, 0.68) 0s infinite;
}

.loading_page .book-section .pageFlip:nth-of-type(3) {
  position: absolute;
  z-index: 2;
  animation: pageTurn 1.5s cubic-bezier(0, 0.39, 1, 0.68) 2s infinite;
}

.loading_page .book-section .pageFlip:nth-of-type(4) {
  position: absolute;
  z-index: 1;
  animation: pageTurn 1.8s cubic-bezier(0, 0.39, 1, 0.68) 1s infinite;
}

.progress-container {
  margin-top: 20px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 20px;
  background: var(--color-primary_loading);
  transition: width 0.5s;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  line-height: 20px;
  font-size: 14px;
  color: white;
}