.pdf .category .cat {
    font-size: 20px;
    font-weight: 500;
}

.pdf .category .add {
    font-size: 17px;
    font-weight: 500;

}

.pdf .category {
    display: inline-block;


}

.pdf img {
    max-width: 180px;
}

.pdf .container {
    border-bottom: 8px solid rgb(209, 205, 205);

    margin-bottom: 15px;


}

.pdf .head h1 {
    font-size: 33px
}

.contentone h2 {
    color: #052963;
    margin: 10px 0px;
    font-weight: 600;
    font-size: 26px;
    padding-left: 4px;
    border-left: 3px solid #052963;
}

.contentone .q {
    font-size: 16px;
}

.contentone p {

    font-family: "Oswald", sans-serif !important;

}

.list_st {



    & li {
        margin-left: 17px;
        list-style: disc;
    }
}

/* table */
.table .m-head {
    color: #4f7ec2;
    background-color: #f6f7fc;
    padding: 10px;
    border-radius: 5px;
}

.table tbody img {
    height: 50px;
    width: 50px;
}

.table .ffees {
    color: green;
    font-weight: 500;
}

.table .yfees {
    color: blue;
    font-weight: 500;

}

.banner .adver {
    display: flex;
    justify-content: space-around;
    position: relative;
    -webkit-print-color-adjust: exact;
}

.banner .adver::before {
    content: "";
    position: absolute;
    border-right: 2px dashed #052963;
    top: 0%;
    left: 0%;
    z-index: 1;

}

.banner .adver img {
    width: 100%;
    height: 210px;
    object-fit: contain;
}

.footer {
    background-color: #052963;
    -webkit-print-color-adjust: exact;
    color: #fff;
    padding: 20px;
}

.footer img {
    max-width: 120px;
}

.footer .sbet {
    border-right: 5px dashed gray;
    border-left: 5px dashed gray;
    -webkit-print-color-adjust: exact;

}

.footer span {
    font-size: 17px;
    font-weight: 700;
    margin-right: 5px;
}

.footer .icon .instagram {
    color: #cd486b;
}

.footer .icon .envelope {
    color: teal;
}

.footer .icon .facebookk {
    color: #316FF6;
}

.footer .disc p {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: small;
}

.footer .disc h4 {
    font-size: smaller;
}

@media print {
    .disc {
        width: 50%;
    }

    .sbet {
        width: 30%;

    }

    .icon {
        width: 20%;
    }

    .pic {
        width: 30%;
    }

    .head {
        width: 70%;
    }
}

@media (max-width: 767px) {

    .pdf img {
        max-width: 110px;
        margin: auto;
    }

    .pdf .head h1 {
        font-size: 19px
    }

    .pdf .category .cat {
        font-size: 20px;
        font-weight: 500;
    }

    .banner .adver img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.contentone {
    background-color: #fff;
    padding: 6px;
    border-radius: 6px;

    & .overview {
        font-size: 25px;
        margin-bottom: 10px;
        text-decoration: underline;
    }
}

.social_d {

    margin-bottom: -50px;

    /* padding: 50px 0; */
    & .social_college {
        display: flex;
        gap: 7px;
        & span svg {
            font-size: 18px;
            background-color: #fff2da;
            border-radius: 6px;
            padding: 1.5px;
        }

        &  a:nth-child(1) {
            color: green;
        }

        &  a:nth-child(2) {
            color: #d62976;
        }

        & a:nth-child(3) {
            color: blue;
        }

        & a:nth-child(4) {
            color: blue;
        }

        & a:nth-child(5) {
            color: #000;
        }

        & a:nth-child(6) {
            color: rgb(243, 51, 51);
        }

        & a:nth-child(7) {
            color: rgb(93, 29, 245);
        }

    }

    & .personal_d_college {
        display: flex;
        gap: 7px;

        & span {
            font-size: 12px;

            &:nth-child(1) {
                font-weight: 500;
            }

            &:nth-child(2) {
                font-weight: 300;
            }
        }
    }

}

.font_f {
    & h1 {
        padding-left: 5px;
    }
}

.conte {
    background-color: #fff2da;
    padding: 2px 5px;
    border-radius: 6px;
    color: #000;

}

div::after {
    content: "";
    display: none;
}
.direction_of_btn{
    display: flex;
    justify-content: center;
    align-items: center;
}