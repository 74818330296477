/* yogi baba css  */

.containe {
  display: flex;

}



.headings {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 31px;
  padding: 10px 13px;
}

.containe main {
  min-width: calc(100% - 300px);
  /* padding: 23px; */
  /* margin-top: 35px; */
  width: 100%;
  transition: margin-left 0.6s;

}

.r-rating ul {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Newtopbar_dashbord {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
}

.Newtopbar_dashbord .navbar {
  display: flex;
  justify-content: end;
  width: 100%;
  background-color: #052963;
}

.containe .sidebarbar {
  background: #fff;
  height: 100vh;
  padding: 0 10px;
  transition: all 0.5s;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.containe .top_section {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
}

.containe .logo {
  font-size: 30px;
}

.containe .bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.containe .link {
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 14px;
  gap: 43px;
  transition: all 0.5s;
  font-size: 18px;
  border-radius: 6px;
}

.containe .link:hover {
  background: #bababa;
  color: #fff;
  transition: all 0.5s;
}

.containe .link.active {
  background: #052963;
  color: whitesmoke;
}



.containe .icon,
.containe.link_text {
  font-size: 24px;
}

.Newtopbar .dropdown-toggle::after {
  display: none;
}

.containe .top {
  position: relative;
  background-color: #ebefef;
  margin-top: 15px;
}





/* college profile abhishek css */
.college_profile .card .college_pic {
  position: relative;
  /* max-height: 50px; */


}

.college_profile {
  padding: 0 21px;
}

.arrow-icon-dash {
  align-items: center;
  display: flex;
}

.college_profile .card .college_pic img {
  width: 100%;
  height: 300px;
  object-fit: fill;


}

.college_profile .card .college_pic .college_log {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  top: 78%;
  left: 3%;
}

.college_profile .card .college_pic .college_log img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* position: absolute; */
  padding: 4px;
  border: 3px solid #475f7b;


}

.college_profile .card .card-body .col_name h5 {
  margin-top: 10px;
  font-size: 19px;
  font-weight: 600;


}

.college_profile .card .card-body .esta {
  color: #000;
  font-size: 14px;

  font-weight: 300;
}

.college_profile .card .col_name {
  display: flex;
  align-items: center;
}

.college_profile #icon {
  background-color: #052963;

  padding: 8px;

  font-size: 12px;
  margin-left: 4px;
  color: #fff;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.college_profile .card .social {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  font-size: 20px;
}

.college_profile .card .social .insta {
  color: #d62976;
}

.college_profile .card .social .face {
  color: #316FF6;
}

.college_profile .card .social .linked {
  color: #0077b5;
}

.college_profile .card .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.college_profile .card .second .add {
  text-transform: capitalize;
}

/* .college_profile .card .second{
  width: 280px;
} */
.college_profile .card .second .add h1 {
  font-weight: 700;
  font-size: 18px;
}

.college_profile .card .second .add h2 {
  font-weight: 600;
  font-size: 15px;
  display: contents;
}

.college_profile .card .second .add p {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.college_profile .view .card .body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.college_profile .view .card {
  padding: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

.college_profile .view .card #icon {
  color: #fff;
  background-color: #052963e6;
  padding: 5px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  font-size: 50px;
  clip-path: none;
}

.college_profile .view .card #data h1 {
  font-size: 25px;
  font-weight: 600;
  display: flex;
  justify-content: right;
}

.college_profile .view .card #data p {
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
}

.college_profile .view .card #total {
  color: blue;
}

.college_profile .view .card #on {
  color: darkgoldenrod;
}

.college_profile .view .card #accept {
  color: green;
}

.college_profile .view .card #reject {
  color: red;
}

.college_profile .overview {
  padding: 15px;
}

.college_profile .overview h1 {
  font-size: 27px;
  font-weight: 600;
  color: gray;
  text-transform: capitalize;
  margin-bottom: 5px;
  text-decoration: underline double #052963;

}

.college_profile .overview p {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.college_profile .overview .btnn {
  background-color: #052963;
  color: #fff;
  font-size: 13px;
  border-radius: 35px;
  float: right;
  margin-top: 10px;

}

/* table.................... */
.college_profile .table .m-head {
  color: #4f7ec2;
  background-color: #f6f7fc;
  padding: 10px;
  border-radius: 5px;


}

.college_profile .table .m-head th {
  border-right: 2px solid black;
  text-transform: capitalize;

}

.college_profile .table .m-data th,
.college_profile .table .m-data td {
  border-right: 2px solid black;
  text-transform: capitalize;
}





/* user profile css  */
/* .profile_of_student{
   margin-left: auto;
   margin-right: auto;
} */
.profile_of_student .student_image img {
  width: 260px !important;
  height: 275px !important;
  border-radius: 5px;
  border: 3px solid #052963;
  padding: 5px;
  object-fit: cover;

}

.profile_of_student .student_image {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

}

.profile_of_student .location {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.profile_of_student .clip_stu {
  font-size: 14px;
  font-weight: 700;
  background-color: #e7e5e58a;
  padding: 10px 30px 10px 30px;
  display: inline-block;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%); */
  /* clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); */
  clip-path: polygon(100% 0, 93% 47%, 100% 100%, 0% 100%, 7% 47%, 0% 0%);
  margin-bottom: 10px;


}

.profile_of_student h1 span {
  font-weight: 600;
}

.profile_of_student .location .icon {
  background-color: #052963;
  border-radius: 50%;
  padding: 6px;
  color: aliceblue;
}

.profile_of_student .location .ltext {
  color: green;
  font-weight: 600;
  /* margin-left: 5px ; */
}

.profile_of_student .card {
  background-color: #fff;
  box-shadow: 0px 0px 30px #dad8d8ab;
  padding: 15px;
  border-radius: 5px;
  /* display: inline-block; */
  position: relative;

}

.profile_of_student .course_catogory img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 3px solid #052963;
  padding: 5px;
  object-fit: cover;

}

.profile_of_student .second {
  font-size: 16px;
  font-weight: 600;
  /* box-shadow: inset 0px 0px 30px #f0eeeed6; */
  background-color: #fff;
  /* box-shadow: rgb(0 0 0 / 11%) 3px 0px 0px 1px; */
  box-shadow: 0 25px 50px -12px rgb(238 238 238 / 25%);
  border-radius: 5px;
  padding: 10px 6px 10px 20px;


}

.profile_of_student .name {
  display: flex;
  justify-content: center;
}

.profile_of_student .course_catogory {
  display: flex;
  justify-content: center;
}

.profile_of_student .course {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0.6em 0.6em 1.2em #d2dce9, -0.5em -0.5em 1em #ffffff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

/* .profile_of_student .card{
  height: 240px;
} */
.profile_of_student .hit {
  height: auto;
}



.profile_of_student .down-cards .card #icon {
  color: #fff;
  background-color: #052963;
  padding: 5px;
  align-items: center;
  display: flex;
  font-size: 50px;
}

.profile_of_student .down-cards .card .body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profile_of_student .down-cards .card .body #data {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
}

.profile_of_student .social {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 25px;
}

.profile_of_student .links .social .insta {
  color: #d62976;
}

.profile_of_student .links .social .face {
  color: #316FF6;
}

.profile_of_student .links .social .linked {
  color: #0077b5 !important;
}

/* navbar dropdown */
.second_dropdown .dropdown-menu {
  position: absolute;
  left: -110px;
  right: 0 !important;
}

/* edit buttonn */
.edit_form button {
  position: relative;
  margin-top: 10px;
  background-color: darkgreen;
  color: #fff !important;

}

.button_of_close {
  color: #fff;
  background-color: #052963 !important;
}

.button_of_submit {
  color: #fff;
  background-color: darkgreen !important;

}

.view_button .btnn {
  color: #fff !important;
  background-color: #052963;

}

.view_button {
  margin-top: 10px;
}

.social_links h1 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

/* testimonial css */


.tes .tes-card {
  border-radius: 6px;
  height: 294px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
  padding: 10px;

}

.tes .tes-head {
  font-size: 24px;
  font-weight: 600;
  padding: 5px;
  color: white;
  background-color: #475f7b;
}

.tes .tes-card .tes-description {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tes .tes-add {
  color: white;
  font-size: 15px;
  font-weight: 500;
  background-color: #052963;
  border-radius: 6px;
  padding: 5px 10px;
}

.tes .tes-buttons .edit {
  color: #fff;
  background-color: darkgreen;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: none !important;
}

.tes .tes-buttons .delete {
  color: #fff;
  background-color: #cd201f;
  border-radius: 5px;
  box-shadow: none !important;
}

.close_director {
  background-color: black;
  color: white;
}

.close_director:hover {
  background-color: gray;
}

.save_director {
  background-color: blue;

}


.social_links #youtube {
  color: #cd201f;
}

.social_links #instagram {
  color: #d62976;
}

.social_links #whatsapp {
  color: #25d366;
}

.social_links #facebook {
  color: #316ff6;
}

.social_links #linkedin {
  color: #0a66c2;
}

.social_links #twitter {
  color: #1da1f2;
}

.social_links #telegram {
  color: #0088cc;
}

.social_links #website {
  color: blue;
}

.college_profile .social_links {
  display: flex;
  font-size: 23px;
  gap: 7px;
}





/*   counsellor dashboard director css */
.director .director_card .profile_img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.director .director_card .profile_img img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: fill;
  padding: 5px;
  border: 2px solid #475f7b;
}

.director .director_card .p_details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.director .director_card #contact {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  margin-top: 5px;
}

.director .director_card .contact {
  font-size: 25px;
  color: yellowgreen;
}

.director .director_card #email {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.director .director_card .email {
  font-size: 25px;
  color: blue;
}

.director .director_card #website {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.director .director_card .website {
  font-size: 25px;
  color: yellow;
}

.director .director_card .card-body h1 {
  text-transform: capitalize;
  /* width: -webkit-fill-available;
  max-width: 112px; */
}

.director .director_card .card-body p {
  font-size: 14px;
}

.director .director_card #name {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  color: #475f7b;
  border-bottom: 3px solid #475f7b;
}

.director .director_card #contact h1 {
  font-size: 15px;
  font-weight: 600;
}

.director .director_card #email h1 {
  font-size: 15px;
  font-weight: 600;
}

.director .director_card #website h1 {
  font-size: 15px;
  font-weight: 600;
}

.director .director_card .card {
  position: relative;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
 height: 300px;

  padding: 20px 0;
}
.img-preview{
  width: 50px; 
  height: 50px;
  margin-top: 6px;
}
.director .director_card .inner_card {
  box-shadow: inset 0 0 0 2px #475f7b;
  min-height: 350px;
  padding: 15px;
}

.director .director_card .social_links {
  margin-top: 15px;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  gap: 10px;
}

.director .director_card #youtube {
  color: #cd201f;
}

.director .director_card #instagram {
  color: #d62976;
}

.director .director_card #whatsapp {
  color: #25d366;
}

.director .director_card #facebook {
  color: #316ff6;
}

.director .director_card #linkedin {
  color: #0a66c2;
}

.director .director_card #twitter {
  color: #1da1f2;
}

.director .director_card #telegram {
  color: #0088cc;
}

.director .director_card #website {
  color: blue;
}

.director .director_card .buttons {
  position: absolute;
  right: 38px;
  top: -10px;
  margin: 15px 0;
}

.director .director_card .buttons .edit {
  color: #fff;
  background-color: green;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: none !important;
}

.director .director_card .buttons .delete {
  color: #fff;
  background-color: #cd201f;
  border-radius: 5px;
  box-shadow: none !important;
}

/* .director .director_card {
  padding: 0 38px;
} */
.director .director_card .inner_email {
  display: flex;
  align-items: center;
}



.close_director {
  background-color: #0a66c2 !important;
  color: #fff;
}

.save_director {
  background-color: darkgreen !important;
  color: #fff;
}

.college_type .form-control {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add box shadow */
  border-radius: 0.25rem;
  /* Optional: Add border radius for rounded corners */
}

.company_profile_buttons .edit {
  background-color: #0088cc;
}

.company_profile_buttons .delete {
  background-color: #cd201f;
  margin-left: 5px;
}

.middle .middle_para {
  margin-left: 5px;
  background-color: #052963;
  padding: 2px 5px;
  color: #fff;
  border-radius: 15px;
  font-size: 12px;
}

.first h2 {
  font-weight: 600;
}

/* toggler */
.Newtopbar_dashbord .bars {
  transition: all 0.5s;
  font-size: 26px;
  color: #fff;
}

.top_section .logo {

  margin: auto;
  display: flex;
  flex-direction: column;
  min-width: 64px;
  max-width: 140px;
  padding: 1px 5px 1px 0;
}

.link_text {
  font-size: 15px;
}

.containe main {
  background-color: #ebefefc4;
  padding-bottom: 20px;
}

.usericon svg {
  color: #fff !important;



}


.collapsible a {
  padding-left: 15px;
}

.containe .sidebarbar::-webkit-scrollbar {
  width: 3px;
}

.Newtopbar_dashbord a svg {
  color: #000;
}

/* director cards */
.director_card .left_side {
  border-right: 2px solid #052963;
  padding: 3px;

}

.director_card .left_side h3 {
  font-weight: 600;
  text-transform: capitalize;
}

.director_card .emaill {
  margin-bottom: 5px;
  align-items: baseline;

  & h3 {
    font-weight: 600;
  }

  & p {
    font-size: 14px;
    margin-left: 15px;
  }

}

.director_card .phone {
  & h3 {
    font-weight: 600;
  }

  & p {
    font-size: 14px;
    margin-left: 15px;
  }

}

.director_card .left_side img {
  width: 120px;
  height: 120px;
  border: none;
}

.director_card .left_side .pic {
  display: flex;
  justify-content: center;
}

.director_card .right_side {
  border-left: 2px solid #052963;
  padding: 10px;
}

/* scrollbar */

.thin_scroll::-webkit-scrollbar {
  height: 6px;
  border-radius: none;
}

.width_define {
  white-space: break-spaces;
  overflow-wrap: anywhere;
}

.upcoming img {
  width: 60px;
  height: 60px;
}

.upcoming_btn {
  background-color: #052963;
  color: #ffffff;
  border-radius: 20px;


}

.leads_btn {
  width: 77px;
}

.common_dash .top h3 {
  font-weight: 600;
  font-size: 25px;
  display: flex;
}

.common_dash .anim {
  animation: myAnim 3s ease-in 1s 2 normal both;

}

@keyframes myAnim {

  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}

.common_dash .bread {
  display: flex;
  justify-content: end;
}

.action_btn {
  width: 77px;
  margin-top: 3px;
}


.common_dash .breadcrumb {
  display: flex;
  /* justify-content: end !important; */
  margin-top: 0;
  background-color: #093c69;
  padding: 6px 20px;
  border-radius: 6px 6px 6px 6px;
  position: relative;

  &::before{
    background-color: #093c69;
    height: 25px;
    width: 25px;
    top: 8.5px;
    border-radius: 50%;
    left: -14px;
    position: absolute;
    content: "";
    z-index: 0;
  }
  &::after{
    background-color: #ecf0f0;
    height: 25px;
    width: 25px;
    top: 8.5px;
    border-radius: 50%;
    right: -14px;
    position: absolute;
    content: "";
    z-index: 0;
  }

}

.upcoming_exams h3 {
  font-weight: 500;
  font-size: 25px;
  text-decoration: underline #000;
}


.owl-carousel.owl-loaded {
  position: relative;
}

.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  left: -4%;
  top: 100px;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  right: -4%;
  top: 100px;
}

.Newtopbar .comments .pic {

  display: flex;
  justify-content: space-around;

  align-items: center;

  & p {

    white-space: break-spaces;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.Newtopbar .comments .pic img {
  height: 25px;
  width: 25px;
  margin-right: 10px;

}

.first_dropdown .usericon {
  font-size: 24px;
}

.first_dropdown .dropdown-menu {
  width: 275px;
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  left: -245px;
  right: 0 !important;
  /* display: none; */
}

/* .first_dropdown:hover .dropdown-menu {
  display: block;
} */

.first_dropdown .btn {
  background-color: #052963;
  color: #fff;
  border-radius: 20px;
  padding: 3px 8px;
  font-size: 12px;
  position: absolute;
  bottom: auto;
  right: 5px;
}

.elig-table {
  position: relative;
}

/* .elig-btn {
  position: absolute;
  right: 20px;
  top: 135px;
} */

.common_dash button {
  border-radius: 5px;

}

.modal-dialog {
  max-width: 600px;
}

.dashboard_footer {
  background-color: #052963;
  padding: 20px;
  color: #fff;
}

.btn-sm {
  border-radius: 6px;
}

.innerbar {
  display: none ;
}




.hback {
  background-color: #052963;
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  padding: 10px;
  border-radius: 6px;
}

.borderbt {
  padding: 15px 0;
  border-bottom: 1px solid gray;

}

.nav-pills .nav-link.active {
  background-color: #052963;
}

.nav_of_stu a.navkinks {
  font-size: 15px;
}

.nav_of_stu {
  background-color: #05296338;
  border-radius: 6px;

}

.nav_of_stu .tab-content {
  padding-top: 0;
  margin-top: 0 !important;
}

.stu_gender {
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.student_clr {
  color: #0943a1;
}

.director .organization_type {
  font-size: 14px;
  font-weight: 700;
  background-color: #9bd6e18a;
  padding: 10px 30px 10px 30px;
  display: inline-block;
  clip-path: polygon(100% 0, 93% 47%, 100% 100%, 0% 100%, 7% 47%, 0% 0%);
  margin-bottom: 10px;
  margin-top: 25px;

}

.btn-primary:hover {
  background-color: #052963;
  border-color: #052963;
}

.btn-primary:focus {
  box-shadow: none;
}

/* homw page testimonial page......................................... */
/* .testimonial_sec{
  padding: 30px 0;
  background-image: url(../../assets/images/testi_back.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
} */
.testimonial_sec h4{
  font-weight: bold;
}
.testi_card {
  background-color: #f8f9fa;
  /* background: transparent; */

  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 6px;


  & .line-clamp-4 {
    text-align: start;
  }

  & .tetsi-pic {
    padding: 5px;
    border: 2px solid #ffa500;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: relative;

    & img {
      width: 100px;
      height: auto;
      object-fit: cover;
    }
  }

  & .description svg {
    font-size: 22px;
    color: #052963;

    &:nth-child(3) {
      float: right;
    }

  }

  & .line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

.owl-theme .owl-dots .owl-dot span {
  background-color: #052963 !important;
  border: 2px solid #0088cc;
  height: 14px;
  width: 14px;
  padding: 2px;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: lightblue !important;
  border: 2px solid #0088cc;
  height: 14px;
  width: 14px;
  padding: 2px;
}

.icon_bg_rotate {
  position: absolute;
  padding: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease-in-out;
  animation-name: rotateme;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    top: 8px;
    right: 8px;
    background: #ffa500;
    transition: all 0.6s ease-in-out;
  }
}

@keyframes rotateme {

  0% {

    transform: rotate3d(0, 0, 1, 0deg);
  }

  25% {

    transform: rotate3d(0, 0, 1, 90deg);
  }

  50% {

    transform: rotate3d(0, 0, 1, 180deg);
  }

  75% {

    transform: rotate3d(0, 0, 1, 270deg);
  }

  100% {

    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.card_college .card-body {
  background-color: #fff;
  border-radius: 8px;
}


.event-content img{
  max-width: 30px;
}
@media (max-width:767px) {
  .breadcrumb{
    margin-top: 10px !important;
    & .breadcrumb-item{
      font-size: 12.5px;
    }
  }
  .director .director_card .card{
    height: auto;

  }
  .director .left_side,.director .right_side{
    border: none;
  }
  .common_dash .breadcrumb{
    &::after {
      background-color: #ecf0f0;
      height: 20px;
      width: 20px;
      top: 5.5px;
      border-radius: 50%;
      right: -12px;
      position: absolute;
      content: "";
      z-index: 0;
  }
    &::before {
      background-color: #093c69;
      height: 20px;
      width: 20px;
      top: 5.5px;
      border-radius: 50%;
      left: -12px;
      position: absolute;
      content: "";
      z-index: 0;
  }
  }
}