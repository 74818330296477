@media only screen and (max-width: 1440px) {
	.top-right-section {
		padding-left: 0rem;
	}

	.col-4.top-left-section {
		gap: 22px;
	}

	ul.navbar-navs {
		padding-left: 0px;
		margin: 10px 227px;
	}

	.navbar-list-content {
		margin: 0px 405px;
	}
}

@media only screen and (max-width: 1275px) {
	form.example {
		width: 320px;
	}

	.navbar-list-content {
		margin: 0px 330px;
	}

	.imp-alerts-1 {
		padding-left: 0px;
	}
}

@media only screen and (max-width: 1140px) {
	.header-section {
		padding-bottom: 20px;
	}

	.navbar-list-content {
		margin: 0px 280px;
	}

	.web-confused001 {
		flex-wrap: wrap;
	}

	section.imp-alerts {
		flex-wrap: nowrap;
		gap: 50px;
	}

	.imp-details-main {
		width: 412px;
	}

	.imp-alerts-ssecond-third {
		width: 75px;

	}
}

@media only screen and (max-width: 1024px) {
	.top-right-section {
		justify-content: center;
	}

	.col-4.top-left-section {
		padding-right: 0px;
	}

	.top-nav-section {
		padding: 10px 50px;
	}

	.header-section {
		display: flex;
		justify-content: center;
		gap: 20px;
	}

	ul.navbar-navs {
		padding-left: 0px;
		margin: 10px 227px;
	}

	.navbar-list-content {
		margin: 0px 210px;
	}

	.web-hero-section-container {
		padding: 77px 0px;
	}

	p.web-colleges-hadding {
		text-align: center;
	}

	.colleges-main-section {
		/* padding: 65px 50px; */
	}

	.web-explore-pro {
		padding: 30px 0px;
	}

	.student-test-card {
		margin-top: 50px;
	}

	.blog-bottom-sec {

		justify-content: center;

	}


}


@media only screen and (max-width:950px) {
	.top-right-section {
		padding-left: 0rem;
		
	}

	.top-nav-section {
		display: block;
	}

	.top-left-section {
		justify-content: center;
	}

	.header-section {
		gap: 15px;
	}

	/* .top-right-section {
        padding-left: 0px;
        justify-content: center;
        margin-top: 20px;
        gap: 40px;
        padding: 0px 202px;
    } */
}

@media only screen and (max-width:885px) {
	form.example {
		padding-top: 0px;
	}

	img.carrer-right {
		width: 320px;
	}

	section.imp-alerts {
		gap: 50px;
		justify-content: center;
	}

	.imp-alt-sec2 {
		display: flex;
		flex-direction: column;
		width: auto;
		justify-content: start;
	}
}


@media only screen and (max-width:768px) {

	.static-section .static .static-head {

		display: flex;
		justify-content: end;
		align-items: center;
		padding: 10px 0;
	}
	p.web-pvt-college {
		padding: 3px;
		font-size: 14px;
	}
	p.web-colleges-hadding {
		text-align: start;
		font-size: 24px !important;
	}
	.top-right-section {
		padding-left: 0rem;
		justify-content: center;
	}

	.explore-cardss {
		width: 280px;
	}

	.top-nav-section {
		display: block;
	}

	.nav-landing {
		position: relative;
		width: 100%;
		height: 400px;
	}

	.top-left-section {
		justify-content: center;
	}

	.top-right-section {
		padding-left: 0px;
		justify-content: center;

		gap: 40px;
	}

	form.example {
		padding-top: 0px;
	}

	.header-section {
		display: block;
		text-align: center;
		padding: 0px;
	}

	.search-bar-section {
		display: flex;
		justify-content: center;
	}

	.rank-signup-buttons {
		display: flex;
		gap: 28px;
		justify-content: center;
		text-align: center;
		align-items: center;
		padding: 15px 0px;
	}

	.header-section {
		margin: 10px 16px;
	}

	ul.navbar-navs {
		padding-left: 0px;
		margin: 10px 100px;
	}

	.navbar-list-content {
		margin: 20px 0px 0px 0px;
		padding-left: 10px;
		text-align: center;
	}

	h1.web-hero-hadding {
		line-height: 38px;
		letter-spacing: 1px;
	}

	p.web-here-content {
		font-size: 16px;
		padding-top: 5px;
	}

	.web-hero-section-container {
		padding: 60px 0px;
	}

	.imp-alt-categories {
		display: flex;
		scale: 1;
		flex-wrap: nowrap;
		border-radius: 12px;
		justify-content: center;
		align-items: center;
		padding: 2px 4px;
		font-weight: 400;
		margin-top: 2px;
	}

	.imp-details-main {
		border: 2px solid rgba(0, 0, 0, 0.25);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-radius: 5px;
		padding: 5px 8px;
		margin-top: 10px;
		margin-left: 4px;
		width: fit-content;
	}


	.panels {
		width: 70%;
	}

	.tabs {
		width: 400px;
		justify-content: center;
	}

	.tab {
		padding: 5px 12px;

	}

	.colleges-main-section {
		/* padding: 50px 0px; */
	}

	.warpper {
		padding: 20px 0px;
	}

	ul.explore-list {
		gap: 10px;
	}

	.top-study-places {
		margin: 20px 0px;
	}

	.web-top-places {
		padding: 20px 11px;
	}

	.pvt-govt-both {
		gap: 25px;
	}

	.pvt-gvt-colleges {
		padding: 0px 0px;
	}

	.web-clg-work {
		padding: 0px 0px;
	}

	.zoom-councelling-photo {
		width: 320px;
	}

	.web-men-gud {
		padding: 30px;
	}

	.guid-ment02 {
		gap: 30px;
		flex-wrap: nowrap;
		margin-top: 20px;
	}

	.web-confused001 {
		padding: 10px 20px;
	}

	.footer-section05 {
		margin-top: 30px;
	}

	.footer-section08 {
		margin-top: 30px;
	}

	.btm-footer {
		padding: 0px;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	a.bottom-copy {
		border-right: 0px solid white;
		padding-right: 0px;
	}

	.web-for-test {
		padding: 20px 0px;

	}

	.imp-alerts-second {
		width: 0px;
	}

	.imp-alerts-ssecond-third {
		width: 0px;
	}

	.imp-alerts-first {
		border-radius: 15px 15px 15px 15px;
	}

	.web-for-collegehead {
		padding-left: 0px;
	}

	.imp-alt-category {
		display: flex;
		justify-content: center;
		padding-left: 0px;
		flex-wrap: nowrap;
		gap: 15px;
	}



	.imp-details-main {
		margin-left: 0px;
	}

	section.our-blog-section {
		padding: 20px 0px;
		background-color: rgb(255, 246, 247);
		margin-top: 20px;
	}

	.student-test-bottom {
		padding: 20px 0px;
	}

	.imp-alerts-ssecond-third {
		width: 0px;
	}

	.imp-alerts-second-sec {
		width: 290px;
	}

	a.imp-alt-details {
		font-size: 12px;
	}
}

@media only screen and (max-width:550px) {
	.top-nav-section {
		padding: 10px 0px;
		justify-content: center !important;
	}

	footer.top-footer {
		padding: 25px 0px;
	}

	p.clg-wk-para {
		text-align: justify;
	}

	.web-clg-work {

		padding: 10px 10px;
	}

	.imp-alerts-2 {

		justify-content: start;

	}
}

@media only screen and (max-width:445px) {
	.top-left-section {
		font-size: 12px;
	}

	.nav-landing {
		position: relative;
		width: 100%;
		height: 400px;
	}

	.top-right-section {
		gap: 7px;
		font-size: 10px;
		justify-content: start;
	}

	.download-app-section {
		padding: 2px 2px;

	}

	.rank-signup-buttons {
		gap: 10px;
	}

	form.example button {
		padding: 5px;
	}

	.blog-bottom-sec {

		justify-content: center;

	}

	form.example input[type=text] {
		padding: 5px;
		font-size: 16px;
	}

	h1.web-hero-hadding {
		line-height: 23px;
		letter-spacing: 1px;
		font-size: 16px;
	}

	p.web-here-content {
		font-size: 16px;
		padding-top: 0px;
		font-size: 15px;
	}

	.web-hero-section-container {
		padding: 25px 0px;
	}

	.tabs {
		width: 310px;
	}

	.colleges-main-section {
		/* padding: 35px 0px; */
	}

	.top-study-places {
		margin: 10px 0px;
	}

	.imp-details-main {
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: 5px 12px;
		margin-top: 15px;
		margin-left: 0px;
		width: auto;
	}

	section.imp-alerts {

		gap: 0px;
		justify-content: start;

	}

	.imp-alt-category {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 5px;
	}

	.imp-alerts-2 {
		display: flex;
		padding-bottom: 20px;
		justify-content: start;
		width: auto;
	}

	section.our-blog-section {
		padding: 20px 0px;
		background-color: rgb(255, 246, 247);
		margin-top: 20px;
	}
}

@media only screen and (max-width:378px) {
	.top-left-section {
		font-size: 10px;
	}

	.top-right-section {
		gap: 7px;
		font-size: 12px;
		justify-content: center;
	}

	.download-app-section {
		padding: 2px 2px;

	}

	.imp-alerts-2 {
		display: flex;
		padding-bottom: 12px;
		justify-content: start;
		width: auto;
	}

	form.example {
		width: 255px;
	}

	p.rank-icon-button {
		font-size: 13px;
	}

	p.signup-icon-button {
		font-size: 13px;
	}
}

@media only screen and (max-width:321px) {
	.top-left-section {
		font-size: 10px;
	}

	.top-right-section {
		gap: 3px;
		font-size: 10px;
	}

	.download-app-section {
		padding: 6px 5px;

	}

	p.web-here-content {
		padding-top: 0px;
		font-size: 12px;
	}

	h1.web-hero-hadding {
		line-height: 19px;
		letter-spacing: 1px;
		font-size: 14px;
	}

	span.hero-need-coubnceling {
		font-size: 12px;
		font-weight: 700;
	}

	button.hero-need-btn {
		padding: 2px 11px;
		margin-top: 15px;
		letter-spacing: 1px;
		word-spacing: 2px;
		margin-bottom: 0px;
	}

	.web-for-collegehead {
		padding-left: 0px;
	}

	p.web-colleges-hadding {
		text-align: center;
		font-size: 16px;
	}

	.colleges-main-section {
		padding: 25px 0px;
	}

	.web-college-maincontainer {
		margin-top: 15px;
	}

	p.web-all-collegesnames {
		font-size: 15px;
	}

	.web-pvt-govt {
		width: 320px;
	}

	img.pvt-colege-images {
		width: 320px;
	}
}